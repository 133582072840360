import { LuminousGallery } from 'luminous-lightbox'
import { dragElement } from "./commons"
// import { constructInternalUrl } from './server'

export default article=> {
    article.querySelectorAll("figure").forEach( luminousGallery )

    const internalReferencesToExamples = article.querySelector(".internalReferences ul li:last-of-type")
    const examples = article.querySelector("#examples")
    if (internalReferencesToExamples && examples) internalReferencesToExamples.appendChild( examples )

    return article
}

// const reAssignHref =a=> {
//     a.href = constructInternalUrl(a.href) // za svaki slučaj, ako nije na svojoj domeni nego u poddirektoriju
//     return a
// }

const luminousGallery =figure=> {

    const images = figure.querySelectorAll("img")
    const container = document.createElement("div")
    const caption = figure.querySelector("figcaption")
    container.classList.add("gallery")
    let cols = images.length

    images.forEach(img=> {
        const a = document.createElement("a")
        const imgURI = img.src.substring(0, img.src.lastIndexOf("/") + 1)
        const imgName = img.src.substring(img.src.lastIndexOf("/") + 1, img.src.lastIndexOf("."))
        const imgFormat = img.src.substring(img.src.lastIndexOf(".", img.src.length))
        var poll = setInterval(function () {
            if (img.naturalWidth) {
                clearInterval(poll)
                if (img.naturalWidth > img.naturalHeight) {
                    a.classList.add("landscape" + Math.round(img.naturalWidth / img.naturalHeight))
                    // cols++
                }
            }
        }, 10);

        a.setAttribute("href", imgURI + imgName + imgFormat)
        img.src = (cols > 2 ? imgURI.replace(/\/img\//, "/img/thumb/") : imgURI) + imgName + imgFormat
        a.appendChild(img)
        container.appendChild(a)
    })

    container.classList.add("for" + cols)
    

    figure.insertBefore(container, caption)

    let prefix
    if (caption.querySelector("strong")) {
        const titl = caption.querySelector("strong").textContent
        if (titl) prefix = titl.replace(/[\s.:]/g, "")
    }
    else prefix = "gal" + parseInt(Math.random() * 100)


    const prepareGallery =()=> { 
        document.querySelectorAll(`.${prefix}-lightbox`).forEach(lightbox=> {
            const caption = lightbox.querySelector(`.${prefix}-lightbox-caption`)
            const dwg = figure.querySelector(".dwg-link")
            if (!!dwg) {
                caption.classList.add("with-dwg")
            }
            if (images.length < 2) lightbox.querySelectorAll(`.lum-gallery-button`).forEach(b=> b.style.display = "none")
            const wrapper = lightbox.querySelector(`.${prefix}-lightbox-image-wrapper`)
            dragElement(wrapper)
        })
    }
    const removeListeners =()=> {
        document.querySelectorAll(`.${prefix}-lightbox-image-wrapper`).forEach(wrap=> {
            wrap.style.top = ""
            wrap.style.left = ""
            wrap.onmousedown = null
        })
    }

    const galleryOptions = {
        // Whether pressing the arrow keys should move to the next/previous slide.
        arrowNavigation: images.length > 1
    }
    const luminousOptions = {
        // Prefix for generated element class names (e.g. `my-ns` will
        // result in classes such as `my-ns-lightbox`. Default `lum-`
        // prefixed classes will always be added as well.
        namespace: prefix,
        // Which attribute to pull the lightbox image source from.
        sourceAttribute: "href",
        // Captions can be a literal string, or a function that receives the Luminous instance's trigger element as an argument and returns a string. Supports HTML, so use caution when dealing with user input.
        caption: trigger=> trigger.parentNode.parentNode.querySelector("figcaption").innerHTML,
        // The event to listen to on the _trigger_ element: triggers opening.
        openTrigger: "click",
        // The event to listen to on the _lightbox_ element: triggers closing.
        closeTrigger: "dblclick",
        // Allow closing by pressing escape.
        closeWithEscape: true,
        // Automatically close when the page is scrolled.
        closeOnScroll: false,
        // Disable close button
        showCloseButton: true,
        // A node to append the lightbox element to.
        appendToNode: document.body,
        // A selector defining what to append the lightbox element to.
        // This will take precedence over `appendToNode`.
        appendToSelector: "#root > main",
        // If present (and a function), this will be called
        // whenever the lightbox is opened.
        onOpen: prepareGallery,
        // If present (and a function), this will be called
        // whenever the lightbox is closed.
        onClose: removeListeners,
        // When true, adds the `imgix-fluid` class to the `img`
        // inside the lightbox. See https://github.com/imgix/imgix.js
        // for more information.
        includeImgixJSClass: false,
        // Add base styles to the page. See the "Theming"
        // section of README.md for more information.
        injectBaseStyles: true
    }

    new LuminousGallery(container.querySelectorAll("a"), galleryOptions, luminousOptions)

}

