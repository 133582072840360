import server from  "./server"
import { conzole } from  "./commons"

/*
    articleComposer dobiva cijeli userQuery (trebaju mu sections koji dobiva u Aside-u, parent[content: general] kojeg još ne dobiva, url i chapter)
    Ovdje ima nekoliko opcija koje trebaju prikladan odgovor:
        1. radi se o poglavlju => skupi sva podpoglavlja => skupi sve članke u potpoglavlju (sa svim potrebnim dodatnim formatiranjem)
        2. radi se o članku:
            2.1. radi se o običnom članku => učitaj članak (sa dodatnim formatiranjem)
            2.2. radi se o prijedlogu rješenja => učitaj uvodne članke iz roditelja (i formatiraj ih za harmonika-prikaz) + učitaj članak (sa dodatnim formatiranjem) + učitaj članke o relevantnim rješenjima (ovo još nemamo u bazi)

*/

export default userQuery=> {
    // console.debug(userQuery)
    
    if (!!userQuery.sections) {  // ako se radi o poglavlju
        const titles = composeTitle(userQuery)
        return composeChapter(userQuery.sections)
                .then(chapter=> {
                    conzole.success("Setting chapter...")

                    return assembleDOMElement([titles, ...chapter])
                }).catch(conzole.failure)
    }

    // #################################################################

    else {  // ako se radi o članku

        if (!userQuery.solutions) { // običnom članku (nije prijedlog rješenja)
            return getSectionArticle(userQuery)
                    .then(article=> {
                        conzole.success("Setting article...")
                        return article
                    })
                    .catch(conzole.failure)
        }
        else { // prijedlog je rješenja
            return composeSolutionArticle(userQuery)
                    .then(solution=> {
                        conzole.success("Setting solution article...")

                        solution = [ solution[1].querySelector("h1"), ...solution] 
                        // console.log(solution)
                        return assembleDOMElement(solution)
                    })
                    .catch(conzole.failure)
        }
    }
}

const parseHTML =htmlString=> new DOMParser().parseFromString(htmlString, "text/html").querySelector("section")


const composeChapter =sections=> new Promise((resolve, reject)=> {
        try {
            Promise.all( getSectionArticles(sections) ).then(result=> resolve(result))
        }
        catch(e) {
            reject(new Error("Something went wrong with assembling the chapter articles... 🔥🔥🔥"))
        }
    })
 

// funkcija za prolazak kroz sve članke podsekcija i spajanje ih u isti članak
const getSectionArticles =sections=> sections.reduce((chapter, section)=> {
        // console.log(chapters, sec)
        const query = { url: section.textUrl, chapter: section.chapter } // funkcija getSectionArticle je prilagođena userQuery-ju...
        if (!section.sections) chapter.push( getSectionArticle( query ) )
        else {
            const heading = createTitle( section, 2 )
            heading.className = "chapterHeader"
            chapter.push( heading )
            chapter.push( ...getSectionArticles(section.sections) )
        }
        return chapter
    }, [])

// funkcija za dohvaćanje članka (preko nje se sve dohvaća sa servera, pa je jedina async) 
async function getSectionArticle({ url, chapter }) { 
    let html = "<section><h4>" + chapter + "</h4>"
    return await server.fetchArticle(url)
                        .then(articleString=> { html += articleString + "</section>"; return html})
                        .then(htmlString=> parseHTML(htmlString) )
}

// funkcija za slaganje članaka za prijedloge rješenja
const composeSolutionArticle =({ url, chapter, solutions, quote, general })=> {
    // console.debug(general)
    // dohvati sve uvodne članke i spremi ih u <div id="intros">
    const intro = Promise.resolve( composeChapter( general ) )
        .then(intros=> wrapInIddDiv(intros, "intros"))

    // dohvati članak prijedloga rješenja
    const solution = getSectionArticle({ url, chapter })

    // dohvati sve relevantne primjere rješenja i troškovnike i spremi ih u prikladne kontejnere (ovo još ništo nemamo)
    let examples = document.createDocumentFragment()
    if (!!solutions.length > 0) {
        examples = Promise.resolve( composeChapter( solutions.map(s=> ({ textUrl: s, chapter: 10 })) ) )
            .then(articles=> {
                const links = []
                for (let i = 0; i < articles.length; i++) {
                    const articleLink = document.createElement("a")
                    articleLink.href = `?${solutions[i]}`
    
                    const title = articles[i].querySelector("h1")
                    const image = articles[i].querySelector("figure:first-of-type img:first-of-type")
                    articleLink.appendChild(title)
                    articleLink.appendChild(image)
                    links.push(articleLink)
                }
                return links
            })
            .then(examples=> wrapInIddDiv(examples, "examples"))
    }

    // spoji sve u jedan array obećanja i vrati gore (praktički isto kao i sa "člancima poglavlja")
    return new Promise((resolve, reject)=> {
        try {
            Promise.all( [ intro, solution, examples ] ).then(result=> resolve(result))
        }
        catch(e) {
            reject(new Error("Something went wrong with assembling the solution article... 🔥🔥🔥"))
        }
    })
}

const assembleDOMElement =sections=> {
    const fragment = document.createDocumentFragment()
    for ( const section of sections ) {
        fragment.appendChild( section )
    }
    return fragment
}

const wrapInIddDiv =(sections, id)=> {
    const wrapper = document.createElement("div")
    wrapper.id = id
    if (sections instanceof Array) wrapper.appendChild( assembleDOMElement( sections ) )
    else wrapper.appendChild( sections )
    return wrapper
} 

const composeTitle =chap=> {
    const iterateTroughTitles =(chap, level, tot=[])=> {
        if (!chap.sections) return [] // znači da je greškom ovdje

        if (!chap.parent) return [ createTitle(chap, level), ...tot ]
        return [...iterateTroughTitles(chap.parent, level - 1, tot), createTitle(chap, level), ...tot ]
    }

    const parentCount = (c=>{ 
        let count = 0
        while (!!c.parent) {
            count++
            c = c.parent
        }
        return count
     })(chap)
    
    return wrapInIddDiv( iterateTroughTitles( chap, parentCount || 1 ), "chapterHeaders")
}

const createTitle =({ code, chapter, title }, level)=> {
    const h = document.createElement("h" + level)
    const titleText = document.createTextNode(title)
    const chap = document.createElement("h4")
    const chapterNo = document.createTextNode(chapter + " ")
    chap.appendChild(chapterNo)
    h.appendChild(chap)
    if (code) {
        const cody = document.createElement("code")
        const codeCode = document.createTextNode(code)
        cody.appendChild(codeCode) // više stvarno ne znam kako da nazivam stvari
        h.appendChild(cody)
    }
    h.appendChild(titleText)
    return h
}


