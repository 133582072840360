import React from 'react'
import '../style/Aside.css'

const Aside =({ contents, defineQueryUpdate })=> {
        // TODO ŠMINKA: omogućiti otvarajući, zatvarajući prikaz za poglavlja 

    const queryUpdate = defineQueryUpdate({ hideSideBar: true, heroNavCleanup: false })
    
    return <aside>
        <ContentList contents={ contents } listLevel={ 1 } queryUpdate={ queryUpdate } /> 
    </aside>
}

const ContentList =({ contents, listLevel, queryUpdate })=> {
    
    if ( contents.sections === "undefined" ) return null
    if ( contents.length === 0 ) return <p className="no_result level1">Nema rezultata...</p>

    
    return <ol className={ "level" + listLevel }>

        { contents.map((chapter, i)=> <ContentEntry key={i} entry={ chapter } queryUpdate={ queryUpdate } listLevel={ listLevel }  />) }
        { contents[0].textUrl === "info/opcenito" && <li>
            <a target="_blank" href="./attachments/documents/UPPO_Prirucnik_GF_HKIG_w.pdf"> {/* ovo je glupo hardcoded, ali je jedina takva stvar. Budem ju dodao kasnije u DB kao članak... */}
                <span className="chapter-number"></span>&nbsp; <code>PDF</code>&nbsp;Priručnika</a>
        </li>}
    </ol>
}

const ContentEntry =({ entry, queryUpdate })=> {

    if ( entry.title === "" || !!entry.message ) return null // provjera da nije ostalo praznih polja u DB-u
    const query = { type: "article", url: entry.textUrl, ...entry }
    if ( query.url === undefined ) console.error( "URL je undefined za:", entry.title )



    
    const linkPrefix = entry.textUrl.indexOf("./") > -1 ? "" : "?"
    
    return <li className={ !!entry.solutions ? "solution" : !!entry.content ? entry.content : "" }>
        <a href={ linkPrefix + query.url } onClick={ (e)=> queryUpdate(e, query) } tabIndex="-1">
            <span className="chapter-number">{ entry.chapter }</span>&nbsp;
            <code>{ entry.code }</code>&nbsp;
                { entry.noveltyExpiration > new Date().getTime() && <mark className="noveltyTag">Novo</mark> }
            { entry.title }
        </a>
        { 
            !!entry.sections ? <ContentList listLevel={ entry.listLevel } contents={ entry.sections } queryUpdate={ queryUpdate }  />
            : null
        }
    </li>

}

export default Aside