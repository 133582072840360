import React, { useRef, useLayoutEffect } from 'react'
import { dragElement } from '../utils/commons'


const PopMenu =({ contents, coords, showContents, showArticle })=> {

    const sve = <NavElement  key="Sve" content="contents" text="Prikaži sadržaj" queryUpdate={ showContents } />
    const info = <NavElement key="Projekt" content="info" text="O Programu" queryUpdate={ showContents } />

    const getSolutions =(cont)=> {
        return cont.reduce((solutions, cont)=> {
            if (!!cont.solutions) solutions.push( cont )
            if (!!cont.sections) solutions.push( ...getSolutions(cont.sections) )
            return solutions
        } , [])
    }

    const createSolutionsDisplay =cont=> {
        const solutions = getSolutions(cont)
        let result
        if (solutions.length >  0) {
            result = solutions.map(solution=> <NavArticleDisplay solution={ solution } queryUpdate={ showArticle }  key={ !!solution.code ? solution.code : solution.title.replace(/\s*/g, "")  } />)
        }
        else {
            result = [<blockquote>
                { cont[0].message }
            </blockquote>]
        }
        return result
    }

    const solutions = createSolutionsDisplay(contents)

    const popup = useRef(null)
  
    useLayoutEffect(()=> {
        
        if (!!popup.current) {
            dragElement(popup.current);
        }
    }, [popup])


    return <div id="popContainer">
            <div id="popMenu" ref={ popup }  style={{ top: coords.item.y + "px", left: coords.item.x + "px" }} >
                <span id="closeBtn" onClick={(e)=> {
                        const handler = window.innerWidth > 800 ? showContents : showArticle
                        handler(e, { type: "reset", content: "contents", text: "Sve" })             
                    }
                } >x</span>
                <h1>{ contents[0].title }</h1>
                <ul>
                    { solutions }
                </ul>
                <div id="popupBtns">
                    { [sve, info] }
                </div>
            </div>
        </div>
}

const NavElement =({ content, text, queryUpdate })=> {

    const query = { type: "reset", content, text }

    return <button onClick={(e) => { queryUpdate( e, query ); } } >
        { text }
    </button>
}

const NavArticleDisplay =({ solution, queryUpdate })=> {

    const query = { type: "article", url: solution.textUrl, ...solution }

    return <li>
        <a onClick={ (e)=> queryUpdate( e, query ) } href={ "?" + query.url }  >
            <h1><code>{ solution.code }</code></h1>
            <p>{ solution.title }</p>   
        </a>
        <div className="keywords">
            { solution.summaryKeywords.map(k=> <mark>{ k }</mark>) }
        </div>
    </li>

}

export default PopMenu