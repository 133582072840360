import React from 'react'
import "../style/Footer.css"

const Footer =({ defineQueryUpdate })=> {

    const queryUpdate = defineQueryUpdate({ hideSideBar: false, heroNavCleanup: true })
    const showSideBar = ()=> defineQueryUpdate({ hideSideBar: false, heroNavCleanup: false })({}, { type: "pass" })
    const searchHandler =(e)=> { queryUpdate(e, { type: "add", search: e.target.value }) }
    const blurHandler =(e)=> {
        // ne sjećam se više čemu ovo ??? valjda sprječava da pritisak na "tab" selektira idući element u tabIndex-u 🤷‍♂️
        e.preventDefault()
        let el = e.target.querySelector( ':focus' );
        if ( el ) el.blur();
    }
    
    return <footer>
        <form onSubmit={ blurHandler } >
            <input id="searchBox" tabIndex="1" type="search" placeholder="Pretraži..." onChange={ searchHandler } onFocus={ showSideBar } autoComplete="off" spellCheck="false" />    
        </form>
    </footer>
}

export default Footer