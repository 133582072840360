import React, { useRef, useState, useEffect } from 'react'
import { attachTooltips, setArticleMenuLink } from "../utils/commons"
import "../style/Article.css"
import "../style/luminous-gallery.css"
import { ReactComponent as ZoomInIcon } from "../icons/zoomIn.svg"
import { ReactComponent as MenuIcon } from "../icons/menu.svg"
const pause = 700

const Article =({ articleHTML, showContents, showArticle, showHeroNav, shorthands })=> {

    // TODO fancy: Automatski dodavati tooltipe za sve riječi koje su skraćenice (iz popisa skraćenica) u tekstu 

    const container = useRef(null)
    const [ chapter, setChapter ] = useState(null)

    const resetApplication =(e, query)=> window.innerWidth > 800 ? showContents(e, query) : showHeroNav(e, query)

    const activateIntrosAccordion =(intros)=> {
        for (let section of intros.childNodes) {
            section.addEventListener("click", (e)=> {
                e.preventDefault()
                if ( e.target.tagName !== "BUTTON" ) section.classList.add("open")
            }, false)

            const closeContainer = document.createElement("nav")
            closeContainer.classList.add("nav")
            const closeBtn = document.createElement("button")
            closeBtn.textContent = "Zatvori" // smisliti drukčije rješenje za englesku varijantu ako će je biti
            closeBtn.addEventListener("click", (e)=> {
                e.preventDefault()
                console.log(e.target.tagName)
                section.classList.remove("open")
                section.scrollIntoView({ behavior: "smooth" })
            }, false)
            closeContainer.appendChild(closeBtn)
            section.appendChild(closeContainer)

        }
    }
    

    useEffect(()=> {

        if (!!container.current) {
            container.current.style.opacity = "0"

            attachTooltips(articleHTML, shorthands)

            articleHTML.querySelectorAll("section a").forEach( el=>{setArticleMenuLink(el, showContents)} ) // mora biti tu a ne u preprocessArticle jer mi treba showContents ... :/


            setTimeout(()=> {   
                const articleContainer = document.getElementById("articleContainer")  
                articleContainer.textContent = ""
                articleContainer.appendChild( articleHTML )
                container.current.style.opacity = "1"    
                // article.style.left = "0"  
                const intro = document.getElementById("intros")  
                if (!!intro) activateIntrosAccordion(intro)

                setChapter(container.current.querySelector("#chapterHeaders *:last-child h4, #articleContainer > section h4").textContent)
            }
            , pause)


            
        }
    }, [articleHTML])

    const toggleView =(e)=> {
        if (document.body.classList.contains("articleView")) {
            showContents(e, { type: "pass" })
        }
        else {
            showArticle(e, { type: "pass" })
        }
    }
    
    return <article ref={ container }>
        { !!chapter && <nav id="articleHeaderNav"> 
             <div className="info">
                <code className="project-name"><strong>UPPO</strong></code>
                <code className="section-number"><strong>§</strong> { chapter }</code>
            </div> 
            <div className="nav">
                <LinkElement key="Projekt" type="contentDisplay" content="info" text="" displayText="O Programu" queryUpdate={ showContents } />
                <LinkElement key="Sve" type="contentDisplay" content="contents" text="" displayText="Prikaži sadržaj" queryUpdate={ showContents } />
                <LinkElement key="Sve" type="reset" content="contents" text="" displayText="Natrag na naslovnicu" queryUpdate={ resetApplication } />
            </div>
            <button className="zoomBtn" onClick={ toggleView }>
                <MenuIcon className="menuIcon" /> 
                <ZoomInIcon className="zoomInIcon" />
                {/* <ZoomOutIcon className="zoomOutIcon" />               */}
            </button>

        </nav> }

        <div id="articleContainer"></div>

        { !!chapter && <nav id="articleFooterNav" className="nav">
                <ToTop />
                <LinkElement key="Sve" type="reset" content="contents" text="" displayText="Natrag na naslovnicu" queryUpdate={ resetApplication } />
        </nav> }
    </article>
}


const LinkElement =({ type, content, text, displayText, queryUpdate })=> {

    const query = { type, content, text }

    return <button onClick={(e) => { queryUpdate( e, query ); } } >
        { displayText }
    </button>
}

const ToTop =()=> <button key="toTop" onClick={(e)=> { e.target.parentNode.parentNode.scrollIntoView() }} >Natrag na vrh</button>

export default Article