import React, { useLayoutEffect } from 'react'
import HeroNav from './HeroNav'
import PopUp from "./PopUp"
import Article from "./Article"
import "../style/Main.css"




const Main =({ defineQueryUpdate, contents, article, shorthands })=> {
    // console.debug(`Article got to main as ${typeof article}: ${article}`)

    const queryUpdateForArticle = defineQueryUpdate({ hideSideBar: true, heroNavCleanup: false })
    const queryUpdateForHero = defineQueryUpdate({ hideSideBar: true, heroNavCleanup: true })
    const [ queryUpdateForPopUp, popUp ] = defineQueryUpdate({ hideSideBar: false, heroNavCleanup: true, getPopUpHandler: true })

    const escapePopUp =e=>{
        if (e.key === "Escape" && document.querySelector("#popMenu")) {
            queryUpdateForPopUp(e, { type: "reset", content: "contents", text: "Sve" })
        }
    }
    
    useLayoutEffect(()=>{
        window.addEventListener("keyup", escapePopUp, false)
    }, [])

    const contentToShow = !!article ? 
        <Article articleHTML={ article } showContents={ queryUpdateForPopUp } showArticle={ queryUpdateForArticle } showHeroNav={ queryUpdateForHero } shorthands={ shorthands } /> 
        : 
        <HeroNav queryUpdate={ queryUpdateForArticle } popUp={ popUp } />

    return <main>
        { contentToShow }
        { popUp.item.show && <PopUp contents={ contents } coords={ popUp } showContents={ queryUpdateForPopUp } showArticle={ queryUpdateForArticle } /> }
        </main>
}

export default Main