import React, { useLayoutEffect, useRef } from 'react'
import { constructNavBar } from "../utils/commons"
import "../style/Header.css"
import { ReactComponent as MenuIcon } from "../icons/menu.svg"

const Header =({ contents, defineQueryUpdate })=> {

    const queryUpdate = defineQueryUpdate({ hideSideBar: false, heroNavCleanup: true })
    const showSideBar = ()=> queryUpdate({}, { type: "pass" })
    const navItems = constructNavBar(contents)

    
    useLayoutEffect(() => {

        if (window.innerWidth > 1000) {
            setTimeout( showSideBar, 500 )   // treba pokazati sideBar
        }
        else {
            document.body.classList.add("articleView")
        }
    }, []) // treba se odvrtiti samo jednom. Ignoriraj glupi React-lint. Nema poima o effect dependancy-ima (ili ja nemam poima i morati ću cijelu aplikaciju iz početka jer istu situaciju imam u glavnom userQuery hooku. Vidjet ćemo.)
    
    return <header>
        <div id="logobox">
            <a href="https://www.grad.unizg.hr/" target="_blank"  rel="noopener noreferrer" title="Građevinski fakultet"><img src="./assets/GF_logo.png" alt="GF logo" /></a>
            <a href="http://www.hkig.hr/" target="_blank"  rel="noopener noreferrer" title="Hrvatska komora inženjera građevinarstva"><img src="./assets/HKIG_logo.png" alt="HKIG logo" /></a>
            <h1 onClick={ (e) => queryUpdate(e, { type: "reset", text: "" }) } title="Urgentni program postpotresne obnove">UPPO</h1>
        </div>
        <Nav items={ navItems } queryUpdate={ queryUpdate } />
    </header>
    
}

const Nav =({ items, queryUpdate })=> {
    
    const headerNav = useRef(null)

    useLayoutEffect(()=> {
        if (!!headerNav.current) {
            headerNav.current.addEventListener("click", (e)=>{
                headerNav.current.classList.toggle("open")
            }, false)
        }
    }, [headerNav])

    // TODO funkcija za izradu menua sa submenuima !!!
    return <nav ref={ headerNav }>
        <MenuIcon id="menuIcon" />

        <ul>
            { items.map(n=> { 
                const query = { ...n, type: "add" }
                return <li key={n.displayText}><a href="./" onClick={ (e)=> { queryUpdate( e, query ) } } >{ n.displayText }</a></li>
            }) }
        </ul>
    </nav>
}

export default Header