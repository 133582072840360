import React from 'react'



const LoadingScreen =({ handler })=> {

    let counter = 0
    setInterval(()=> {
        counter = counter < 3 ? ++counter : 0
        const el = document.getElementById("ellipses")

        if (!el) return
        el.textContent = ".".repeat(counter)
    }, 1000)


    return <div id="loading">

        <p>Učitavanje <span id="ellipses">.</span></p>
            
    </div>
}

export default LoadingScreen