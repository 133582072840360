import { conzole } from "./commons";
// Ovdje nastojim držati svu logiku zahtjeva korisnika i filtriranja sadržaja.
// Ne bavim se ovdje dohvaćanjem članaka jer to sama po sebi radi klopka za hendlanje statea query-ja u hooks.js-u

const filterBuilder = () => {

    
    const filterChapters = (content, text) => {
      // banalna funkcija koja gleda imali niza slova u title-u članka. Služi za sada samo za dohvaćanje glavnih sekcija preko Header navigacije i preko HeroNav-a.
      return content.reduce((chaps, c) => {
          if (c.title.length === 0) return chaps; // ako je ostalo praznih objekata u DB-u
          if (containsText(c.title.toLowerCase(), text.toLowerCase())) chaps = [...chaps, c];
          return chaps;
      }, []);
    }
    
    // -----------------------------------------------------------------------------------------------------
    
    const searchByUser = (contents, search, caseSens = false) => {

      // logiku koja dijeli search String u Array riječi i traži po svakoj riječi zasebno (naravno, izbacuje veznike i sl. stopwordse) te spaja rezultate u jedno polje (Set?)
      const searchTerms = search.split(" ").filter(t=> !(t in dictionary.stopwords) && t.length > 2)
      // provjera da li da uopće pokreće search
      if (searchTerms.length === 0) return contents 

      // TODO ŠMINKA: označiti u popisu sadržaja gdje se nalaze tražene riječi (nemam poima kako to napraviti osim ako ne vraćam novi contents sa <mark>-ovima unutra...). Dolje je za to pripremljena funkcija markSearchInTitle
      // TODO ŠMINKA: dodati u rezultate i poglavlja, ali zatvorena tako da im se mogu (i ne moraju) izlistati potpoglavlja

      const filteredChapters = contents.reduce((matchedChapters, chapterInQuestion) => {

        if (!chapterInQuestion.sections) {
          
          const searchBase = chapterInQuestion.title.replace(/\s*/g, "") + "," + String(chapterInQuestion.code) + "," + chapterInQuestion.summaryKeywords.join(",") + chapterInQuestion.keywords.join(",")  // [code] se pretvara u string jer ga možda nema pa da mi [undefined] ne radi probleme. OPREZ!!! To bi moglo izazvati probleme ako se pojavi engleska verzija sa riječi "undefined" u naslovu poglavlja.
          const searchResults = searchTerms.map(st=> {
              return st === st.toUpperCase() || st.length <= 4 ? st : st.substring(0, st.length - 2) // prvo provjerava da riječ nije prekratka za skračivanje, onda da li netko traži članak po šifri (malo trikasto, možda treba smisliti bolji test) i ako da, onda vraća šifru, ako ne izbacuje zadnja dva slova iz izraza (kako bi se izgubio nastavak)
            }).reduce((found, term)=> { // onda za svaku riječ u tražilici prolazi kroz svaku string ključnih riječi

                  const searchTerm = RegExp(`${term}[a-z]*`, caseSens ? '' : 'i')
                  if ( searchTerm.test(searchBase) )  found++ // ovo je kraća i brža verzija tražilice. Broji koliko je traženih pojmova nađeno među ključnim riječima tako da kasnije može provjeriti jesu li svi nađeni.
                  return found  
        
            }, 0 )

            if (searchResults >= searchTerms.length)  {
              return [...matchedChapters, chapterInQuestion]
            }
            else return matchedChapters
          }
          else {
            return [...matchedChapters, ...searchByUser(chapterInQuestion.sections, search)]
          }
        }, [])

      return filteredChapters // tu ih se može sortirati po score-u ako treba (ali nisam još provjerio ni da li se score dobro zbraja) 
    }

      // ------------------------------------------------------------------------------------------------------
      
      const containsText =(text, pattern)=> text.indexOf(pattern) > -1

      // class SplicerString extends String {
      //   splice(idx, rem, str) {
      //     return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
      //   }
      // }
      
      // const markSearchInTitle =( title, matches )=> title // za sada ništo ne radi. TODO: Tu bi trebala ići nekakva logika ubacivanja <mark> oznake oko riječi u naslovu koje odgovaraju pretraživanju. Pazi (!) fale zadnja dva slova u traženoj riječi pa rađe indeksiraj do idućeg razmaka (ili kraja stringa)
        

      // ########################################################################################################
        
      return { contents: (contents = [], query = {}) => {

      // raspored sadržaja je:
      //
      // manual -> contents[] |      documentation[]    |     info[][]     |     solutions[][]
      //               |                   |                    |                    |
      //           Dimnjaci[][]       Troškovnik[]            ...                   ...
      //          Tavanski z.[][]     Projektiranje[]
      //           Krovne k.[][]
      //       Nekonstrukcijski el.[]
      //       Manji neodg. zahvati[]
      //          Podupiranja[]

      // prva provjera je imamo li uopće dohvaćen sadržaj iz DB-a, a iz App.js-a dobiva "manual"[] kao argument
      if (!contents.hasOwnProperty("contents")) return [];
      // druga provjera je dolazi li query iz searchbara (ako nije onda je od klika mišem negdje na stranici)
      if (!!query.search && query.search.length > 2) return searchByUser( 
        [ ...contents.info, ...contents.contents, ...contents.documentation, ...contents.examples ], 
        query.search );
      // treća provjera je traži li se cijeli sadržaj
      if (query.content === "manual" ) return contents.contents
        
      
      // a dalje nastupa logika kopanja po contents dijelu priručnika 

      const section = contents[!!query.content ? query.content : "contents"];

      const test = [!!query.text, !!query.material, !!query.level].map((x) => String(Number(x))).join("") // glupi trik za manje tipkanja unutar switcha (koji mi za sada ničem ne služi...)
      // tu idu uvjeti za razne tipove queryja ako ikada dođe do pretrage po materijalu, nivou 
      switch (test) {
        case "100":
          return filterChapters(section, query.text)
        case "000":
        default:
          return section
      }
    },
  };
};

export default filterBuilder()

// ##########################################################################################################################

const constructQuery =query=> {
  // ovo je koma. Čuvam ovu funkciju samo zato što ću možda trebati još što raditi sa query-jem osim procesuiranja teksta inputa. Mislim da se komotno može ukloniti, ali trebalo bi onda protestirati da ju ipak nešto ne koristi.
  let processedText = ""
  if (query.text) {
    processedText = [
      "Sve", 
      "Info", 
      "Projekt", 
      "O Programu", 
      "O projektu", 
      "Sadržaj", 
      "Prikaži sadržaj", 
      "Dokumentacija", 
      "Natrag na naslovnicu"
    ].indexOf(query.text) > -1 ? "" : query.text.toLowerCase().substring(0, 4)
  }
  return  { type: "add", project: "UPPO", ...query, text: processedText || "" }
}

export const processQuery = (oldQuery, newQuery) => {
  let query
  switch (newQuery.type) {
    case "add":
      query = { ...oldQuery, ...newQuery }
      break
    case "article":
      if (oldQuery.url === newQuery.url) { // za slučaj da je kliknut već otvoreni članak
        return oldQuery
      }
      const { type, url, chapter, title, sections, general, parent, solutions, quote } = newQuery
      query = { ...oldQuery, type, url, chapter, title, sections, general, parent, solutions, quote, articleToShow: undefined }
      break // ovolko je duga linija da se ne bi resetirao popis sadržaja u sidebaru
    case "remove":
      delete oldQuery[newQuery.key]
      query = oldQuery
      break
    case "display article":
      query = { ...oldQuery, articleToShow: newQuery.html }
      break
    case "reset":
      query = newQuery
      break
    case "pass":
      return oldQuery
    case "contentDisplay":
      query = { ...newQuery, articleToShow: oldQuery.articleToShow }
      break
    default:
      conzole.failure(`User query ${newQuery} has no type or it's type is unknown!!`)
  }
  return constructQuery(query)

}

const dictionary = {
  stopwords: [  // ovo je najgluplja lista stop-riječi ikad. Izrazi duljine ispod tri znaka se ionako ne pretražuju, a ostalo je trkeljanje. Tu je ako će ikad trebati dodati prave stop-riječi za iskakanje u pretragi.
    "i", "sa",
    "u", "na", "pod",
    "djelomično", "ne znam", "tražim"
  ]
}


// ###################################################################################################
// ovdje dolje je starija kompliciranija verzija filtriranja poglavlja koja je trebala rangirati rezultate i pomoći označavati izraze u izlistanim naslovima poglavlja
// izbačena je jer se ništo od toga ne čini korisnim ili jednostavnim, a znatno usporava pretragu. Ovdje je ako će ikad zatrebati.

// contents.reduce((matchedChapters, chapterInQuestion) => {

//   if (!chapterInQuestion.sections) {
    
//     const searchBase = [ chapterInQuestion.title, String(chapterInQuestion.code), chapterInQuestion.summaryKeywords.join(", "), chapterInQuestion.keywords.join(", ") ]  // [code] se pretvara u string jer ga možda nema pa da mi [undefined] ne radi probleme. OPREZ!!! To bi moglo izazvati probleme ako se pojavi engleska verzija sa riječi "undefined" u naslovu poglavlja.
//     let score = 100 // želim nekako rangirati rezultate. U searchBase-i su prvo naslov, pa šifra, pa 
    
//     const searchResults = searchTerms.map(st=> {
//         return st === st.toUpperCase() || st.length <= 4 ? st : st.substring(0, st.length - 2) // prvo provjerava da riječ nije prekratka za skračivanje, onda da li netko traži članak po šifri (malo trikasto, možda treba smisliti bolji test) i ako da, onda vraća šifru, ako ne izbacuje zadnja dva slova iz izraza (kako bi se izgubio nastavak)
//       }).reduce((found, term)=> searchBase.reduce((result, key)=> { // onda za svaku riječ u tražilici prolazi kroz svaku ključnu riječ

//             if ( containsText( caseSens ? key : key.toLowerCase(), caseSens ? term : term.toLowerCase() )) {
//                   result.matches.add(term)
//                   result.score += score
//                 }
//             score /= 2 // nakon naslova se gleda šifra, pa dva stringa ključnih riječi
//             return result
          
//         }, found)
  
//       , { matches: new Set(), score: 0} )

//       if (searchResults.matches.size >= searchTerms.length)  {
//         chapterInQuestion.searchRank += searchResults.score
//         // chapterInQuestion.title = markSearchInTitle(chapterInQuestion.title, searchResults.matches)
//         return [...matchedChapters, chapterInQuestion]
//       }
//       else return matchedChapters
//     }
//     else {
//       return [...matchedChapters, ...searchByUser(chapterInQuestion.sections, search)]
//     }
//   }, [])