import React from 'react'
import "../style/heroNav.css"


const HeroNav =({ queryUpdate, popUp })=> {

    return <nav id="heroNav"   >
        <NavBuilding queryUpdate={ queryUpdate } popUp={ popUp } />
    </nav>
}

// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

const NavBuilding = ({ queryUpdate, popUp })=> {

	const queryfy =(str)=> ({ type: "reset", content: "contents", text: str })
	
	const deselectAll =()=> {
		[...document.getElementsByClassName("up")].forEach(element => {
			element.classList.remove("up")
		});
	}
	const resetSelection =(e)=> {
		e.preventDefault()
		e.persist()
		setTimeout(()=> {queryUpdate(e, queryfy("Sve"));}, 2000)
	}
	const getPopUp =(e)=> {
		popUp.set(e.clientX, Math.min(e.clientY, window.innerHeight * 0.666))
	}
	const closePopUp =()=> {
		popUp.close()
	}

    const addVisualCueWhenSelected =e=> {
		e.preventDefault()
		e.persist()
		deselectAll()
		closePopUp()
		
		;["KrovneKonstrukcije", "TavanskiZidovi", "Dimnjaci", "Ploče", "Podupore", "Temelj", "EGB", "egb-prizemlje", "Neodgodivi", "Zidovi"].forEach(prompt=> {
			let selection = []
			const id =str=> document.getElementById(str)
			if (e.target.id === prompt) {
				switch (prompt) {
					case "TavanskiZidovi":
						selection = [ id("zabat") ]
						break
					case "KrovneKonstrukcije":
						selection = [ id("potkrovlje") ]
						break
					case "Ploče":
						selection = [ id("ploča") ]
						break
					case "Podupore":
						selection = [ id("sruseni-zid"), id("podupore") ]
						break
					case "Neodgodivi":
						selection = [ id("osteceni-zid") ]
						break
					case "egb-prizemlje":
						selection = [ id("nekonstrukcijski") ]
						break
					default:
						selection = [ e.target.parentNode ]
				}
				// console.debug(e.target, selection)
				selection.forEach(el=> { el.classList.add("up") })
				getPopUp(e)
			}
			else { }
		})
    }

    return <svg version="1.1" x="0px" y="0px" viewBox="350 225 550 825">
	<g id="zgrada" onClick={ addVisualCueWhenSelected }>

		<g id="potkrovlje">
			<g id="krov">
				<g className="istok">
                    <g className="krov">
					    <polygon className="sjena" points="624.7,565.9 438.5,540.3 550,368.1 714,454.1 " />
                    </g>
				</g>
				<g id="grede">
					<polygon className="sjena" points="628.4,563.7 710.2,458.9 705.8,456.3 625,564.3 "/>
					<polygon className="sjever" points="625.1,564.2 705.8,457.6 705.5,452.3 625,561.4 "/>
					<polygon className="sjena" points="584.8,556.9 671.7,439.2 667,436.3 580.9,557.6 "/>
					<polygon className="sjever" points="581,557.5 667,437.7 666.7,431.8 580.9,553 "/>
					<polygon className="sjena" points="541.8,550.2 633.3,419.5 628.2,416.3 536.8,550.9 "/>
					<polygon className="sjever" points="536.9,550.8 628.2,417.9 627.9,411.3 536.8,545.1 "/>
					<polygon className="sjena" points="499,543.4 594.8,399.9 589.5,396.3 492.6,544.3 "/>
					<polygon className="sjever" points="492.8,544.1 589.5,398 589.1,390.8 492.7,539.2 "/>
					<polygon className="sjena" points="455.4,536.7 556.3,380.2 550.7,376.3 448.5,537.6 "/>
					<polygon className="sjever" points="448.7,537.5 550.7,378.2 550.4,370.3 448.5,532.9 "/>
					<polygon className="sjever" points="448.9,538 452.1,537.3 452.1,532 448.9,532.7 "/>
					<polygon className="zapad " points="627.3,563 452.1,537.3 452.1,532 627.3,559.3 "/>
					<polygon className="sjena" points="627.3,563 624,563.3 448.9,538 452.2,537.3 "/>
					<polygon className="zapad " points="676.5,506.5 521.3,445.3 521.3,439.6 676.5,502.8 "/>
					<polygon className="sjena" points="677.2,505.6 673.5,507 512.4,445.3 518,443 "/>
					<polygon className="zapad " points="604.8,430.6 599.6,428.2 599.6,422 604.8,424.4 "/>
					<polygon className="sjever" points="647.7,494.8 655,493.3 655,485.1 647.7,486.8 "/>
					<polygon className="zapad " points="797.1,543.2 655,493.1 655,485.1 797.1,538.5 "/>
					<polygon className="sjena" points="796,542.8 790.6,543.4 647.7,494.8 655.1,493.2 "/>
					<polygon className="zapad " points="746.8,492.2 587.7,417.4 587.7,410.3 746.8,488.2 "/>
					<polygon className="sjena" points="746.5,492.1 742,492.8 582,419.6 587.9,417.4 "/>
					<polygon className="zapad " points="705.5,452.3 799.3,536.1 803.7,536.3 709,452.3 "/>
					<polygon className="sjever" points="705.8,457.5 796.6,541.8 799.3,536.1 705.5,452.3 "/>
					<polygon className="sjena" points="799.3,536.1 803.8,536.3 801.6,541.2 796.6,541.8 "/>
					<polygon className="zapad " points="666.7,431.8 766.8,525.9 771.4,526.2 670.7,432.1 "/>
					<polygon className="sjever" points="667,437.7 763.9,532.3 766.8,525.9 666.7,431.8 "/>
					<polygon className="sjena" points="766.8,525.9 771.5,526.2 769.2,531.7 763.9,532.3 "/>
					<polygon className="zapad " points="627.9,411.3 734.2,515.8 739.1,516 632.3,411.9 "/>
					<polygon className="sjever" points="628.2,417.8 731.2,522.8 734.2,515.8 627.9,411.3 "/>
					<polygon className="sjena" points="734.2,515.8 739.2,516 736.8,522.2 731.2,522.8 "/>
					<polygon className="zapad " points="589.1,390.8 701.7,505.6 706.9,505.9 593.9,391.7 "/>
					<polygon className="sjever" points="589.5,398 698.5,513.4 701.7,505.6 589.1,390.8 "/>
					<polygon className="sjena" points="701.7,505.6 707,505.9 704.4,512.6 698.5,513.4 "/>
					<polygon className="sjena" points="669.1,495.5 674.7,495.7 672,503.1 665.7,503.9 "/>
					<polygon className="sjever" points="518.8,524 511.7,525.4 511.7,446 518.8,443.3 "/>
					<polygon className="zapad " points="525,524.8 518.8,523.6 518.8,443.3 525,445.5 "/>
					<polygon className="zapad " points="592.7,513.1 587.7,511.9 587.7,417.4 592.7,419.7 "/>
					<polygon className="sjena" points="587.8,422.5 524.8,445.5 518.8,443.4 581.9,419.8 "/>
					<polygon className="sjever" points="587.9,417.6 511.8,446.1 511.7,439.1 587.9,409.4 "/>
					<polygon className="sjever" points="587.8,509.6 579.4,511.4 579.4,420.8 587.8,417.6 "/>
					<polygon className="zapad " points="550.4,370.3 669.1,495.5 674.6,495.7 555.6,371.5 "/>
					<polygon className="sjever" points="550.7,378.1 665.7,503.9 669.1,495.5 550.4,370.3 "/>
				</g>
                <g className="zapad">
                    <g className="krov">
						<title>Krovne konstrukcije</title>
                        <polygon id="KrovneKonstrukcije" onClick={ (e)=> { queryUpdate(e, queryfy("Krovovi") ) }} className="osvjetljeno" points="815.3,547.2 662.7,493.5 550,368.1 714.1,453.7" />
                    </g>
                </g>
			</g>
            <g className="sjever">
                <g id="zabat">
					<g className="strgani-zid">
						<defs>
							<polygon id="tavan-clipPath" points="438.5,540.3 663.3,494.2 550,367.8 "/>
						</defs>
						<clipPath id="tavan-clipUse">
							<use href="#tavan-clipPath"  className="clip-path "/>
						</clipPath>
						<g className="tavan-clip">
							<g className="perspektiva-cigli">
								<polygon className="zapad" points="519.9,424.2 518,423.4 518,418.6 519.9,419.4 "/>
								<polygon className="zapad" points="523.2,427.4 521.3,426.6 521.3,421.8 523.2,422.6 "/>
								<polygon className="zapad" points="533.2,428 531.4,427.2 531.4,422.3 533.2,423.1 "/>
								<polygon className="zapad" points="536.8,431.4 534.9,430.6 534.9,425.7 536.8,426.4 "/>
								<polygon className="zapad" points="540.2,434.5 538.4,433.8 538.4,429 540.2,429.7 "/>
								<polygon className="zapad" points="551.2,435.5 549.2,434.7 549.2,429.7 551.2,430.5 "/>
								<polygon className="zapad" points="554.7,438.8 552.9,438.1 552.9,433.1 554.7,433.9 "/>
								<polygon className="zapad" points="558.5,442.6 556.6,441.8 556.6,436.7 558.5,437.5 "/>
								<polygon className="zapad" points="554.6,448.5 552.8,447.8 552.8,443.1 554.6,443.8 "/>
								<polygon className="sjena" points="558.5,442.6 554.6,444 552.7,443.2 556.6,441.9 "/>
								<polygon className="zapad" points="558.2,451.9 556.6,451.3 556.6,446.5 558.2,447.1 "/>
								<polygon className="zapad" points="603.8,421.9 601.4,420.8 601.4,415.2 603.8,416.4 "/>
							</g>
							<path className="cigla" d="M517.9,418.8l-6.5,2.7v4.5l6.5-2.7V418.8z M521.2,422l-6.5,2.7v4.5l6.5-2.6V422z
								 M524.5,425.3l-6.6,2.7v4.6l6.6-2.6V425.3z M531.3,422.5l-6.8,2.8v4.6l6.8-2.7V422.5z M527.9,428.6l-6.7,2.7v4.6l6.7-2.6
								V428.6z M534.7,425.8l-6.9,2.7v4.7l6.9-2.7V425.8z M531.3,431.9l-6.8,2.6v4.6l6.8-2.6V431.9z M538.2,429.2l-7,2.7v4.7l7-2.6
								V429.2z M534.7,435.3l-6.9,2.6v4.7l6.9-2.5V435.3z M541.8,432.6l-7.1,2.7v4.7l7.1-2.6V432.6z M549,429.9l-7.2,2.7v4.8
								l7.2-2.7V429.9z M538.2,438.7l-7,2.6v4.7l7-2.5V438.7z M545.4,436l-7.1,2.6v4.7l7.1-2.6V436z M552.7,433.3l-7.3,2.7v4.8
								l7.3-2.7V433.3z M601.2,415.4l-8.7,3.2v5.2l8.7-3.1V415.4z M541.8,442.2l-7.1,2.6v4.7l7.1-2.5V442.2z M549,439.5l-7.2,2.6
								v4.8l7.2-2.6V439.5z M556.5,436.8l-7.4,2.7v4.8l7.4-2.6V436.8z M596.8,422.3l-8.5,3.1v5.2l8.5-3V422.3z M605.6,419.1
								l-8.8,3.2v5.3l8.8-3.1V419.1z M545.4,445.7l-7.1,2.5v4.7l7.1-2.5V445.7z M552.7,443.1l-7.3,2.6v4.8l7.3-2.5V443.1z
								 M584.1,432l-8.2,2.9v5.1l8.2-2.8V432z M592.5,429l-8.4,3v5.1l8.4-2.9V429z M601.2,426l-8.7,3.1v5.2l8.7-3V426z M610.1,422.8
								l-8.9,3.1v5.3l8.9-3.1V422.8z M549,449.2l-7.2,2.5v4.8l7.2-2.4V449.2z M556.5,446.7l-7.4,2.6v4.8l7.4-2.5V446.7z M572,441.3
								l-7.8,2.7v5l7.8-2.6V441.3z M580,438.6l-8.1,2.8v5l8.1-2.7V438.6z M588.3,435.7l-8.3,2.9v5.1l8.3-2.8V435.7z M596.8,432.8
								l-8.5,2.9v5.2l8.5-2.9V432.8z M605.6,429.7l-8.8,3v5.3l8.8-2.9V429.7z M560.3,450.3l-7.5,2.5v4.9l7.5-2.5V450.3z M568,447.7
								l-7.7,2.6v4.9l7.7-2.5V447.7z M576,445l-8,2.7v5l8-2.6V445z M584.1,442.3L576,445v5.1l8.2-2.7V442.3z M592.5,439.5l-8.4,2.8
								v5.1l8.4-2.7V439.5z M564.1,454l-7.6,2.5v4.9l7.6-2.4V454z M572,451.4l-7.8,2.6v5l7.8-2.5V451.4z M580,448.8l-8.1,2.6v5
								l8.1-2.6V448.8z M588.3,446.1l-8.3,2.7v5.1l8.3-2.6V446.1z M568,457.7l-7.7,2.5v4.9l7.7-2.4V457.7z M576,455.2l-8,2.5v5
								l8-2.5V455.2z M584.1,452.6l-8.2,2.6v5.1l8.2-2.5V452.6z M564.1,463.9l-7.6,2.4v4.9l7.6-2.3V463.9z M572,461.5l-7.8,2.4v5
								l7.8-2.4V461.5z M580,459l-8.1,2.5v5l8.1-2.4V459z M588.3,456.4L580,459v5.1l8.3-2.5V456.4z M568,467.7l-7.7,2.3v4.9l7.7-2.3
								V467.7z M576,465.3l-8,2.4v5l8-2.3V465.3z M584.1,462.9l-8.2,2.5v5.1l8.2-2.4V462.9z M564.1,473.9l-7.6,2.2v4.9l7.6-2.2
								V473.9z M572,471.6l-7.8,2.3v5l7.8-2.2V471.6z M580,469.2l-8.1,2.4v5l8.1-2.3V469.2z M588.3,466.8l-8.3,2.4v5.1l8.3-2.3
								V466.8z M568,477.7l-7.7,2.2v4.9l7.7-2.1V477.7z M576,475.5l-8,2.3v5l8-2.2V475.5z M572,481.7l-7.8,2.2v5l7.8-2.1V481.7z "/>
							<path className="sjever fasada" d="M614.4,427.1c0,0-9.7-4.2-12.3-2.5c-2.3,1.5-1.4,4-0.6,6.1c-0.7-0.6-2.5,1.2-3.4,1.7
								c-0.9,0.4-1.9,0.6-2.7,1.1c-0.6,0.4-1,1-1.6,1.4c-1.2,0.8-3,0.1-2.7,2.1c0.2,1.2,1.4,2.7,2.2,3.6c-0.7-0.7-1.4-1.3-2-2
								c0,0.9-0.1,1.9-0.2,2.5c-0.3,1.4-0.2,1.1-1.8,1.7c-2.3,0.8-2.9,1-4.3,3c-1.5,2-3.6,4.2-4.7,6.4c-0.4,0.9-0.2,1.1-0.3,2.1
								c-0.1,1.4-0.5,1.4-1.8,2.1c1.6,0.5,3.6,0.8,4.9,1.8c1.7,1.4,1.7,3.1,2.1,5.1c0.5,2.8,0.3,2.4-2.3,3.5
								c-1.7,0.8-3.5,1.6-5.3,2.1c-1.6,0.4-4.3-0.7-5.6,0c-0.9,0.5-1.2,3-1,4.1c0.2,1.7,1.1,3.2,1,4.9c-0.1,2.1-0.9,4.3-1.3,6.4
								c-1.2-0.7-2.7-1.9-4-1.7c-1.6,0.2-3.6,1.6-5.1,2.2c-0.3-1.2-1.2-2.8-1.2-4c0-1.4,1.1-3.2,1.6-4.6c0.4-1.2,1.2-2.6,1.4-3.8
								c0.2-1.4-0.3-3.1-0.5-4.5c-0.3-3-0.3-6.2-1-9.1c-0.2-0.9-0.6-2.3-1.4-2.7c-1.3-0.7-4.2-0.4-5.6-0.5c-2.8-0.3-5-2.4-7.3-3.9
								c-2-1.3-4-2.6-6-4.2c-2.7-2.2-5.3-4.6-8.2-6.6c-0.9-0.6-2-1.2-2.9-1.8c-1.4-1-2.5-1.1-4-1.8c-2.6-1.3-3.8-4.4-5.1-6.9
								c-1.7-3.1-6.3-14.9-8-18L427.8,553l261.5-51.9L614.4,427.1z "/>
						</g>
					</g>
					<title>Tavanski zidovi</title>
                    <polygon id="TavanskiZidovi" className="pokrivalo zid" onClick={ (e)=> { queryUpdate(e, queryfy("Tavanski zidovi")) } } points="438.5,540.3 663.6,494.8 550,368.1" />
                </g>
            </g>
		</g>

        <g id="dimnjak">
            <polygon className="sjena" points="711,440.3 736,433.2 709.7,415.2 683.5,423.6"/>
			<polygon className="sjever forFx" points="708.8,469.8 691.9,456.1 691.9,425.4 708.9,420.1"/>
			<polygon className="sjever forFx" points="709.6,415.2 683.4,423.6 683.4,417.9 709.6,409.3"/>
			<polygon className="zapad" points="736,433.4 709.5,415.3 709.5,409.3 736,427.9"/>
			<polygon className="zapad" points="728.1,479.7 708.8,469.8 708.8,420.1 728.1,433"/>


			<g id="dimnjak-strgani">
				<g className="sjever">
					<defs>
						<polygon id="dimnjak-sjever-clipPath" points="708.8,469.8 691.9,456.1 691.9,435.1 691.3,434.8 691.9,425.4 708.9,420.1"/>
					</defs>
					<clipPath id="dimnjak-sjever-clipUse">
						<use href="#dimnjak-sjever-clipPath" />
					</clipPath>
					<path className="dimnjak-sjever-clip cigla" d="M699,433.2l-6.5,1.8v3.3l6.5-1.8V433.2z M702.3,435.7l-6.6,1.8v3.4l6.6-1.8V435.7z M699,440
						l-6.5,1.8v3.3l6.5-1.7V440z M705.7,438.2L699,440v3.4l6.7-1.8V438.2z M702.3,442.5l-6.6,1.7v3.4l6.6-1.7V442.5z M709.1,440.7
						l-6.8,1.8v3.4l6.8-1.7V440.7z M705.7,445l-6.7,1.7v3.4l6.7-1.7V445z M709.1,444.2l-3.4,0.9v3.4l3.4-0.8V444.2z M709.1,447.6
						l-6.8,1.7v3.4l6.8-1.6V447.6z M709.1,451.1l-3.4,0.8v3.4l3.4-0.7V451.1z"/>
					<g className="fasada-sjever">
						<path className="sjever forFx" d="M708.8,452.6c0-0.8-0.2-2.5-0.5-1.6c-0.2,0.4-0.7-1.1-1.2-1.3c-0.3-0.1-0.6,0.2-0.9,0.1
							c-0.3-0.1-0.5-0.4-0.8-0.6c-1.6-1.2-3.1-2.5-4.7-3.8c-0.5-0.4-0.7-0.7-1.3-0.9c-0.5-0.2-1.1-0.2-1.6-0.4
							c-0.6-0.3-0.9-0.9-1.4-1.3c-1-0.8,0.7,0.2-0.3-0.7c-0.3-0.3-0.4-0.7-0.5-1.2c-0.4-0.3-0.8-0.7-1.2-1.1c-0.5-0.6-1.2-1.1-1.6-1.7
							c-0.3-0.5-0.6-0.7-1.1-1.1l-0.8,20.9l18.3,14.4L708.8,452.6C708.8,452.6,708.8,452.6,708.8,452.6z"/>
						<path className="sjever lom" d="M691.3,434.8c1.8,0.8,3.8,3.2,5.2,4.6c1.4,1.5,3.4,2.9,5,4.3c1.5,1.4,2.4,2.8,4.1,3.9
							c1.7,1.2,1.6,0.5,3.1,1.9c-0.3-0.1,1,1.7,0.7,1.5c1.7-0.6-1.6-3.3-2.1-3.6c-2.1-1.3-4.7-2.3-6.8-3.6c-1.1-0.6-2.4-1.3-3.3-2.1
							c-1-0.9-1.5-2.2-2.5-3C693.4,437.6,692.7,436.3,691.3,434.8z"/>
						<path className="sjever forFx" d="M695.2,439.5c0.6,0.5,1.1,1,1.7,1.4c0.5,0.4,0.8,1,1.4,1.3c0.5,0.2,1.1,0.2,1.6,0.4
							c0.6,0.2,0.8,0.4,1.3,0.9c1.6,1.3,3,2.5,4.7,3.8c0.3,0.2,0.5,0.5,0.8,0.6c0.3,0,0.6-0.2,0.9-0.1c0.2,0.1,1.7,1.2,1.9,1.5
							l-0.4-29.7l-18.1,5l-0.1,10c0.4,0.4,3.6,3.4,3.9,3.9C695.1,438.7,695.1,439.1,695.2,439.5z"/>
					</g>
				</g>
				<g className="zapad">
					<defs>
						<polygon id="dimnjak-zapad-clipPath" points="728.1,479.7 708.8,469.8 708.8,420.1 728.1,433 729.1,461.8 728.1,461.4"/>
					</defs>
					<clipPath id="dimnjak-zapad-clipUse">
						<use href="#dimnjak-zapad-clipPath" />
					</clipPath>
					<path className="dimnjak-zapad-clip cigla" d="M724.2,436.3l-3-1.8v3.3l3,1.8V436.3z M727.1,438.1l-2.9-1.8v3.3l2.9,1.8V438.1z M730,439.8
						l-2.9-1.7v3.2l2.9,1.7V439.8z M722.7,438.7l-3-1.8v3.3l3,1.8V438.7z M725.7,440.5l-3-1.8v3.3l3,1.7V440.5z M728.6,442.2l-2.9-1.7
						v3.3l2.9,1.7V442.2z M721.2,441.1l-3.1-1.8v3.3l3.1,1.8V441.1z M724.2,442.9l-3-1.8v3.3l3,1.7V442.9z M727.1,444.6l-2.9-1.7v3.3
						l2.9,1.7V444.6z M730,446.3l-2.9-1.7v3.2l2.9,1.7V446.3z M716.6,441.8l-3.2-1.8v3.4l3.2,1.8V441.8z M719.7,443.5l-3.1-1.8v3.4
						l3.1,1.7V443.5z M722.7,445.3l-3-1.7v3.3l3,1.7V445.3z M725.7,447l-3-1.7v3.3l3,1.7V447z M728.6,448.7l-2.9-1.7v3.3l2.9,1.6V448.7
						z M711.8,449.3l-3.3-1.8v3.4l3.3,1.7V449.3z M715,451l-3.2-1.7v3.4l3.2,1.7V451z M718.1,452.7L715,451v3.4l3.1,1.7V452.7z
						M721.2,454.3l-3.1-1.7v3.3l3.1,1.6V454.3z M724.2,456l-3-1.6v3.3l3,1.6V456z M727.1,457.6l-2.9-1.6v3.3l2.9,1.6V457.6z
						M730,459.1l-2.9-1.6v3.2l2.9,1.5V459.1z M710.2,451.8l-1.6-0.9v3.5l1.6,0.8V451.8z M713.4,453.5l-3.2-1.7v3.4l3.2,1.7V453.5z
						M716.6,455.2l-3.2-1.7v3.4l3.2,1.6V455.2z M719.7,456.8l-3.1-1.6v3.4l3.1,1.6V456.8z M722.7,458.4l-3-1.6v3.3l3,1.6V458.4z
						M725.7,460l-3-1.6v3.3l3,1.5V460z M728.6,461.6l-2.9-1.5v3.3l2.9,1.5V461.6z"/>
					<g className="fasada-zapad">
						<path className="zapad" d="M726.4,461.9c-1-0.3-0.9-0.5-1.7-1.2c-1-0.8-2-0.9-3.2-1.4c-0.9-0.4-1.7-1-2.6-1.5
							c-0.6-0.3-1.2-0.7-1.8-1.1c-0.9-0.6-2-0.7-3.1-1.2c-1.5-0.7-3-1.4-4.3-2.3c-0.9-0.6-0.5-1.7-1.4-1.7l-0.5,19.2l20.3,9.4l0.5-17.7
							C728.1,462.2,726.9,462,726.4,461.9z"/>
						<path className="zapad lom" d="M709,449.4c0.5,0.4,0.4,0.8,1,1c0.6,0.3,1.5,1.4,2.1,1.7c0.3,0.2,0.7,0.4,1,0.6c0.5,0.3,1.2,0.4,1.8,0.7
							c0.2,0.1,0.2,0.2,0.4,0.3c0.3,0.2,0.6,0.2,0.9,0.3c0.2,0.1,0.4,0.2,0.7,0.4c0.4,0.3,0.7,0.7,1.2,0.6c0.4,0,0.4,0,0.8,0.2
							c0.2,0.1,0.5,0.3,0.7,0.4c0.3,0.1,0.5,0.2,0.7,0.3c0.4,0.2,0.9,0.4,1.3,0.6c0,0,0,0,0,0c1,0.8,2,1.2,3,2c0.5,0.4,0.9,0.5,1.2,1.1
							c0.1-0.6,1.4,0.5,1.7,0.7c0.3,0.3,0.6,0.5,0.8,0.9c0,0,0,0.1-0.1,0.1c0,0.1,0,0.1,0.1,0.2c-0.3-0.2-0.5-0.4-0.7-0.6
							c-0.2-0.1-0.5-0.4-0.7-0.5c-0.2-0.1-0.4-0.1-0.5-0.1c-0.3-0.1-0.5-0.3-0.7-0.5c-0.4-0.4-0.8-0.5-1.3-0.7
							c-0.3-0.1-0.5-0.1-0.7-0.2c-0.4-0.1-0.5-0.4-0.9-0.6c-0.3-0.2-0.4,0.2-0.6,0c-0.3-0.2-0.8-0.8-1.1-1c-0.5-0.3-1.2-0.7-1.7-0.9
							c-0.3-0.1-0.6-0.3-0.9-0.6c-0.2-0.2-0.2,0.3-0.5,0.2c-0.3-0.2-0.5-0.3-0.8-0.5c-0.5-0.2-0.4,0.1-0.9-0.2
							c-0.2-0.1-1.7-0.7-1.9-0.8c-0.7-0.4-1.2-1.2-1.8-1.7c-0.3-0.2-0.5-0.5-0.9-0.7c-0.5-0.4-2-0.9-2.6-1
							C708.4,451.1,709.4,449.7,709,449.4z"/>
						<path className="zapad" d="M728.8,453.4c-0.3-0.9-0.3-2.1-0.7-2.9c-0.7-1.7-0.9-1.4-2.2-2.6c-1.1-1.1-2.4,1.7-2.9,0.4
							c-0.1-0.3-1.7-0.2-2.1-0.7c-0.5-0.7-2.5-2.7-3-3.5c-0.7-1.1,0.4-2.7,0.7-3.2c0.2-0.3,2.5-0.3,3.7-0.8c1.1-0.5,1.6-0.5,2.2-0.7
							c1,0.9,1.6,0,2.1,0.5c0.4,0.5,0.9,0.8,1.2,0.8c0.3-0.7,0.2-1.5,0.7-2.1c0.1-0.1,0.1-0.1,0.2-0.2l0-5.6l-20.6-13.7l0.2,29.2
							c0.2,0.2,0.3,0.4,0.4,0.6c0.3,0.4,0,0.4,0.1,0.9c1,0,2,1.6,2.9,2.2c1.4,0.9,2.1,1.1,3.6,1.8c1,0.5,3.1,1.1,4,1.8
							c0.6,0.4,1.1,0.9,1.7,1.3c0.9,0.5,1.1,1.2,2,1.6c1.1,0.5,1.7,0.1,2.6,1c0.8,0.7,0.7,0.6,1.8,0.9c0.3,0.1,1.8,1.4,2.5,1.8
							l-1.1-8.5C728.9,453.5,728.9,453.5,728.8,453.4z"/>
					</g>
				</g>
			</g>


			<title>Dimnjaci</title>
            <polygon id="Dimnjaci" onClick={ (e)=> { queryUpdate(e, queryfy("Dimnjaci")) } } className="transparent" points="709.6,409.4 709.6,409.3 709.5,409.3 709.5,409.3 709.5,409.3 683.4,417.9 683.4,423.6 691.9,428.7 691.9,456.1 708.9,469.9 708.9,469.8 728.1,479.7 728.1,435.4 735.8,433.3 736,433.4 736,427.9 "/>
        </g>

		<g id="prizemlje">

			<g className="sjever" >
			    <g className="zid">
					<defs>

					<path id="prizemlje-cigla" className="cigla prizemlje-clip" d="M440.1,680.3l-5.9-0.9l0.2,4.5l5.9,1L440.1,680.3z M446.2,681.3l-6.1-0.9l0.2,4.6l6.1,1
						L446.2,681.3z M452.4,682.2l-6.3-1l0.2,4.7l6.3,1L452.4,682.2z M458.8,683.2l-6.4-1l0.3,4.7l6.4,1.1L458.8,683.2z M465.5,684.3
						l-6.6-1l0.3,4.8l6.6,1.1L465.5,684.3z M472.3,685.3l-6.8-1.1l0.3,4.9l6.8,1.1L472.3,685.3z M479.4,686.4l-7-1.1l0.3,5l7.1,1.2
						L479.4,686.4z M486.6,687.5l-7.3-1.1l0.3,5l7.3,1.2L486.6,687.5z M494.1,688.7l-7.5-1.2l0.3,5.1l7.5,1.2L494.1,688.7z
						M501.9,689.9l-7.7-1.2l0.3,5.2l7.7,1.3L501.9,689.9z M509.9,691.1l-8-1.2l0.3,5.3l8,1.3L509.9,691.1z M518.1,692.4l-8.3-1.3
						l0.3,5.4l8.3,1.4L518.1,692.4z M526.7,693.8l-8.5-1.3l0.3,5.5l8.6,1.4L526.7,693.8z M535.5,695.1l-8.8-1.4l0.3,5.5l8.8,1.5
						L535.5,695.1z M544.7,696.6l-9.2-1.4l0.3,5.6l9.2,1.5L544.7,696.6z M554.2,698l-9.5-1.5l0.3,5.7l9.5,1.6L554.2,698z M564,699.6
						l-9.8-1.5l0.3,5.8l9.8,1.6L564,699.6z M574.2,701.1l-10.2-1.6l0.3,6l10.2,1.7L574.2,701.1z M584.8,702.8l-10.6-1.6l0.3,6.1
						l10.6,1.8L584.8,702.8z M595.8,704.5l-11-1.7l0.3,6.2l11,1.8L595.8,704.5z M607.2,706.3l-11.4-1.8l0.3,6.3l11.4,1.9L607.2,706.3
						z M619.1,708.1l-11.9-1.8l0.3,6.4l11.9,2L619.1,708.1z M631.5,710l-12.4-1.9l0.3,6.5l12.4,2.1L631.5,710z M644.4,712l-12.9-2
						l0.4,6.7l12.9,2.1L644.4,712z M657.8,714.1l-13.4-2.1l0.4,6.8l13.5,2.2L657.8,714.1z M449.5,686.4l-6.2-1l0.2,4.6l6.2,1.1
						L449.5,686.4z M455.9,687.5l-6.4-1.1l0.2,4.7l6.4,1.1L455.9,687.5z M462.4,688.6l-6.5-1.1l0.3,4.8l6.5,1.2L462.4,688.6z
						M469.1,689.7l-6.7-1.1l0.3,4.8l6.7,1.2L469.1,689.7z M476.1,690.8l-6.9-1.2l0.3,4.9l6.9,1.2L476.1,690.8z M483.2,692l-7.2-1.2
						l0.3,5l7.2,1.3L483.2,692z M490.6,693.3l-7.4-1.2l0.3,5.1l7.4,1.3L490.6,693.3z M498.2,694.5l-7.6-1.3l0.3,5.1l7.6,1.3
						L498.2,694.5z M506.1,695.8l-7.9-1.3l0.3,5.2l7.9,1.4L506.1,695.8z M514.3,697.2l-8.1-1.4l0.3,5.3l8.1,1.4L514.3,697.2z
						M522.7,698.6l-8.4-1.4l0.3,5.4l8.4,1.5L522.7,698.6z M569.4,706.3l-10-1.7l0.3,5.9l10,1.8L569.4,706.3z M579.8,708.1l-10.4-1.7
						l0.3,6l10.4,1.8L579.8,708.1z M590.6,709.9l-10.8-1.8l0.3,6.1l10.8,1.9L590.6,709.9z M601.8,711.7l-11.2-1.9l0.3,6.2l11.2,2
						L601.8,711.7z M613.4,713.7l-11.7-1.9l0.3,6.4l11.7,2.1L613.4,713.7z M625.6,715.7l-12.1-2l0.3,6.5l12.1,2.1L625.6,715.7z
						M638.2,717.8l-12.6-2.1l0.3,6.6l12.6,2.2L638.2,717.8z M651.4,720l-13.2-2.2l0.4,6.8l13.2,2.3L651.4,720z M452.9,691.7
						l-6.3-1.1l0.2,4.7l6.3,1.2L452.9,691.7z M459.4,692.8l-6.4-1.1l0.3,4.7l6.5,1.2L459.4,692.8z M466,694l-6.6-1.2l0.3,4.8l6.6,1.2
						L466,694z M472.8,695.2L466,694l0.3,4.9l6.8,1.3L472.8,695.2z M479.9,696.5l-7.1-1.2l0.3,5l7.1,1.3L479.9,696.5z M487.2,697.8
						l-7.3-1.3l0.3,5l7.3,1.4L487.2,697.8z M574.8,713.3l-10.2-1.8l0.3,6l10.2,1.9L574.8,713.3z M585.4,715.1l-10.6-1.9l0.3,6.1
						l10.6,2L585.4,715.1z M596.4,717.1l-11-1.9l0.3,6.2l11,2.1L596.4,717.1z M607.9,719.1l-11.4-2l0.3,6.3l11.4,2.1L607.9,719.1z
						M619.8,721.2l-11.9-2.1l0.3,6.4l11.9,2.2L619.8,721.2z M632.2,723.4l-12.4-2.2l0.3,6.5l12.4,2.3L632.2,723.4z M645.1,725.7
						l-12.9-2.3l0.4,6.7l12.9,2.4L645.1,725.7z M602.5,724.4l-11.2-2.1l0.3,6.2l11.2,2.2L602.5,724.4z M614.1,726.6l-11.7-2.2
						l0.3,6.4l11.7,2.3L614.1,726.6z M626.3,728.9l-12.2-2.3l0.3,6.5l12.2,2.4L626.3,728.9z M608.6,731.9l-11.5-2.3l0.3,6.3l11.5,2.4
						L608.6,731.9z M620.5,734.3l-11.9-2.4l0.3,6.4l11.9,2.5L620.5,734.3z M666.6,750.4l-13.8-2.9l0.4,6.9l13.8,3L666.6,750.4z
						M660.7,756.2l-14.2-3.2l0.4,6.8l14,3.3L660.7,756.2z M667.4,764.5L652,761l1.9,7.3l13.8,3.3L667.4,764.5z M660.8,769.9
						l-13.5-3.3l0.4,6.8l13.5,3.4L660.8,769.9z M668.1,778.6l-13.8-3.5l0.4,6.9l13.8,3.6L668.1,778.6z M661.5,783.8l-13.5-3.6
						l0.4,6.8l13.5,3.7L661.5,783.8z M655,788.9l-13.3-3.6l0.4,6.8l13.3,3.8L655,788.9z M668.9,792.7l-13.8-3.8l0.4,6.9l13.8,3.9
						L668.9,792.7z M662.2,797.8l-13.5-3.8l0.4,6.8l13.5,4L662.2,797.8z M655.8,802.7l-13.3-3.9l0.4,6.8l13.3,4L655.8,802.7z
						M669.6,806.8l-13.8-4.1l0.4,6.9l13.8,4.2L669.6,806.8z M663,811.7l-13.6-4.1l0.4,6.8l13.6,4.3L663,811.7z M656.5,816.5
						l-13.3-4.2l0.4,6.8l13.3,4.3L656.5,816.5z M670.4,820.9l-13.9-4.4l0.4,6.9l13.9,4.5L670.4,820.9z M650.1,821.2l-13-4.2l0.4,6.7
						l13,4.4L650.1,821.2z M663.7,825.6l-13.6-4.4l0.4,6.8l13.6,4.6L663.7,825.6z M643.9,825.8l-12.8-4.3l0.3,6.6l12.8,4.4
						L643.9,825.8z M657.2,830.3l-13.3-4.5l0.4,6.8l13.3,4.6L657.2,830.3z M671.1,835l-13.9-4.7l0.4,6.9l13.9,4.8L671.1,835z
						M650.9,834.9l-13-4.5l0.4,6.7l13,4.7L650.9,834.9z M664.5,839.6l-13.6-4.7l0.4,6.8l13.6,4.9L664.5,839.6z M644.6,839.3
						l-12.8-4.6l0.3,6.6l12.8,4.7L644.6,839.3z M658,844.1l-13.3-4.8l0.4,6.8l13.3,4.9L658,844.1z M671.8,849.1l-13.9-5l0.4,6.9
						l13.9,5.1L671.8,849.1z M651.6,848.5l-13-4.8l0.4,6.7l13.1,4.9L651.6,848.5z M665.2,853.5l-13.6-5l0.4,6.8l13.6,5.2L665.2,853.5
						z M645.4,852.8l-12.8-4.8l0.3,6.6l12.8,5L645.4,852.8z M658.7,857.9l-13.3-5.1l0.4,6.8l13.3,5.2L658.7,857.9z M672.6,863.2
						l-13.9-5.3l0.4,6.9l13.9,5.4L672.6,863.2z M652.3,862.2l-13.1-5.1l0.4,6.7l13.1,5.2L652.3,862.2z M665.9,867.5l-13.6-5.3
						l0.4,6.8l13.6,5.5L665.9,867.5z M659.4,871.7l-13.3-5.3l0.4,6.8l13.4,5.5L659.4,871.7z M673.3,877.2l-13.9-5.6l0.4,6.9l13.9,5.7
						L673.3,877.2z M666.7,881.4l-13.6-5.6l0.4,6.8l13.6,5.7L666.7,881.4z M674.1,891.3l-13.9-5.9l0.4,6.9l13.9,6L674.1,891.3z
						M667.4,895.3l-13.7-5.9l0.4,6.8l13.7,6L667.4,895.3z M674.8,905.4l-14-6.2l0.4,6.9l14,6.3L674.8,905.4z M668.1,909.3l-13.7-6.2
						l0.4,6.8l13.7,6.3L668.1,909.3z M675.6,919.5l-14-6.5l0.4,6.9l14,6.6L675.6,919.5z M668.9,923.2l-13.7-6.5l0.4,6.8l13.7,6.6
						L668.9,923.2z"/>
					</defs>

					<g id="osteceni-zid">
						<defs>
							<polygon id="fasada-clipPath" points="663.8,933.7 660.3,714.3 437.4,679.8 439.5,819.4"/>
						</defs>
						<clipPath id="fasada-clipUse">
							<use href="#fasada-clipPath" />
						</clipPath>
						<use href="#prizemlje-cigla" />
						

						<path id="fasada-neodgodivi" className="fasada fasada-clip" d="M661.5,919.7l0-4.8l-0.6-8.4l0-6.7l-0.3-12.5l0-0.2l0.7-9.7l0.2-9.9l-1.2-13.3
							l0-0.3l0.3-4.1l0.6-6.3l-0.5-3.6l0-0.4l0.2-3.8l0.7-10l-1-28.8l0-0.2l0.7-9.2l0-9l-0.9-10.3l0-0.2l-0.3-30.7l0-0.1l0.8-12.5
							l-0.4-9.6l-10.7-1c-0.5,0-1,0.1-1.3,0.2c-2,0.6-3.1,2.6-4.8,3.5c-4.3,2.5-9.7-1-13.9-2.4c-3.4-1.1-5.6-2.3-8.8-4
							c-1.2-0.6-2.4-1.3-3.6-1.9l-0.9-0.2l-0.6,0.3l-1.3,0.1l-3.5-0.9l-8.3-1.1l-0.3-0.1l-1.6-0.5c-0.7,0.7-1.3,1.4-2,1.6
							c-1.2,0.3-4.2-1.1-5.4-1.3c-3.8-0.7-7.3-0.5-11.1-0.3c-4.5,0.3-10.6,1.3-14.9-0.6c-1.6-0.7-2.6-2.4-4.2-3c-2.7-1-6.7-0.2-9.4,0
							c-2.5,0.2-3.4-0.1-5.6-1.7c-1.7-1.3-3.5-2.4-5.3-3.4l-13.5-3.3l-7.3-1.3l-9.5-0.8l-0.2,0l-1-0.2c-0.6,0-1.2,0-1.7,0
							c-3.4-0.2-7.4-0.3-10.7,0.5c-2.6,0.6-2.9,1-5.4,0.1c-3.1-1.1-5.2-3.7-8.2-2.8c-1.8,0.6-2.4,1.3-4.4,1.1
							c-2.3-0.2-4.3-1.9-6.4-2.8c-0.9,1-3.1,3.8-4.6,3.8c-1.4,0.1-3.3-2.1-4.5-2.8c-1.6-1-3.1-1.7-4.3-3.1c-0.7-0.7-1.2-1.5-1.6-2.4
							l-2.2-0.2L450,684l-0.3,0l-4.5-1l-0.4,0c0.2,0.8,0.3,1.6,0.1,2.5c-0.7,3.4-1.9,6-1.9,9.7c0,4.5,0.2,9-0.7,13.4
							c-0.4,1.9-0.6,2.8-1.8,4.4c-0.2,0.2-0.4,0.5-0.6,0.8c0.1,2.5,0.4,5.1,0.6,8.2l0,0.5c0.4,4.7,0.3,6.8,0.1,10.5
							c0,0.8-0.1,1.7-0.1,2.7c-0.1,3.6-0.5,11-0.8,14.6c-0.1,1.5,0.1,2.7,0.4,4c0.3,1.4,0.6,2.9,0.4,4.9c-0.1,0.7-0.1,1.4-0.2,2
							c-0.1,1.4-0.2,2.8-0.4,4c-0.2,1.2-0.1,2.5,0.1,3.9c0.1,0.9,0.2,1.8,0.2,2.7c0,1.9-0.1,3.9-0.3,5.8c-0.2,3.4-0.5,7,0,10.2
							c0.5,3.3,0.4,5.1,0,8.7c-0.3,2.3-0.6,4.9-0.1,6.7c0.1,0.5,0.2,0.8,0.3,1.1c0.1,0.4,0.2,0.7,0.4,1.7c0.1,0.7,0,1.2-0.5,1.9
							l-0.5,0.6l0.1,0.8c0.1,0.5,0.1,1,0.2,1.6c0.2,1.3,0.4,2.9,0.4,4.4l0,0.1l0,0.1c0,0.2,0,0.3,0,0.6l0,0.2c0.1,0.7,0.1,2.2,0.1,3.1
							l-0.7,1.3l221.5,112.4l0-4.5l0.7-3.5L661.5,919.7z"
							/>

						<path id="lom-neodgodivi" className="lom fasada-clip" d="M662.8,713.5l-1.6-0.8l0,0.5l-221.8-33.4c-0.1-0.1-0.2-0.1-0.3,0l-0.7-0.1l0.2,0.1
							c-0.1,0-0.1-0.1-0.2-0.1L438,820.5c2.2,0.2,1.3-3.9,1.3-5.1c0.1-2.3-0.4-4.5-0.6-6.6c-0.1-1.1,1.2-1.5,1-2.5
							c-0.3-1.5-0.3-1.2-0.7-2.8c-0.5-2.1-0.2-5,0.1-7.1c0.4-3.4,0.5-5.1,0-8.4c-0.8-5.2,0.2-10.9,0.2-16.2c0-2.2-0.6-4.7-0.3-6.8
							c0.3-1.9,0.4-4,0.6-6c0.3-3.5-1-5.4-0.8-8.8c0.3-3.6,0.7-10.9,0.8-14.6c0.2-5.7,0.4-7.5,0-13.1c-0.7-8.3-1.2-13.5-0.5-21.9
							c0.3-3.7,0.7-10.5,0.7-14.1c0-1.3,0.2-4.5-0.1-6.1l2.5,1.4l3.1,0.2l4.7,1l7.7,0.3l6.7,0.7l5.4,1.1l7.5,1.4l4.4,1.2l15.3,1.8
							l15.8,2.6l9.7,0.8l7.4,1.3l20.1,4.9l6.6,0.2l1.9-0.5l8.7,0.9l6.3,2.1l5.4,0.6l11.3,1.2l5.3,0.9l7.6,2.2l8.4,1.1l3.9,1l1.2-0.5
							l7.5,1.6l7.6,0.1l3,0.2l7,2.1l18.1,1.8l1.8-0.2l0.5,10.9l-0.8,12.5l0.3,30.8l0.9,10.4l0,9.1l-0.7,9.3l1,28.9l-0.7,10l-0.2,3.9
							l0.6,3.9l-0.6,6.4l-0.3,4.2l1.2,13.5l-0.2,9.9l-0.7,9.7l0.3,12.6l0,6.8l0.6,8.4l0,4.8l0.3,5.4l-0.7,3.8l0.8,5l0.1-0.3
							L662.8,713.5z"/>

						<polygon id="Neodgodivi" onClick={ (e) => { queryUpdate(e, queryfy("Neodgodivi")) }  } className="pokrivalo zid"  points="671.1,714.9 663,713.7 663,713.6 438.6,679.8 438.6,820.1 663,934 663,723.4 670.9,718.5" />

					</g>

					<g id="sruseni-zid">
						<defs>
							<polyline id="prizemlje-clipPath" points="662.8,933.7 659.4,715 437.4,679.1 438.6,820.1"/>
						</defs>
						<clipPath id="prizemlje-clipUse">
							<use href="#prizemlje-clipPath"  className="clip-path "/>
						</clipPath>
						<use href="#prizemlje-cigla" />
						

						<g id="izlazak-cigli">
							<path className="osvjetljeno cigla" d="M659.4,715l-13.4-2.1l3.9-0.8l13.3,2.1L659.4,715z M649.9,712.1l-12.8-2l-3.9,0.8l12.8,2L649.9,712.1z
								M637.1,710.1l-12.3-1.9l-4,0.8l12.3,1.9L637.1,710.1z M624.8,708.2l-11.8-1.8l-4,0.7l11.9,1.9L624.8,708.2z M613,706.4
								l-11.4-1.8l-4,0.7l11.4,1.8L613,706.4z M601.6,704.6l-11-1.7l-4.1,0.7l11,1.7L601.6,704.6z M590.6,702.9l-10.6-1.6l-4.1,0.7
								l10.6,1.7L590.6,702.9z M580,701.3l-10.2-1.6l-4.1,0.6l10.2,1.6L580,701.3z M569.8,699.7l-9.9-1.5l-4.1,0.6l9.9,1.5
								L569.8,699.7z M559.9,698.2l-9.5-1.5l-4.1,0.6l9.5,1.5L559.9,698.2z M550.3,696.7l-9.2-1.4l-4.1,0.6l9.2,1.4L550.3,696.7z
								M541.1,695.3l-8.9-1.4l-4.1,0.5l8.9,1.4L541.1,695.3z M532.2,693.9l-8.6-1.3l-4.1,0.5l8.6,1.4L532.2,693.9z M523.6,692.6
								l-8.4-1.3l-4.1,0.5l8.4,1.3L523.6,692.6z M515.2,691.3l-8.1-1.3l-4.1,0.5l8.1,1.3L515.2,691.3z M507.1,690l-7.9-1.2l-4.1,0.5
								l7.8,1.2L507.1,690z M499.3,688.8l-7.6-1.2l-4.1,0.5l7.6,1.2L499.3,688.8z M491.7,687.6l-7.4-1.1l-4.1,0.4l7.4,1.2L491.7,687.6
								z M484.3,686.5l-7.2-1.1l-4.1,0.4l7.2,1.1L484.3,686.5z M477.1,685.4l-7-1.1l-4.1,0.4l7,1.1L477.1,685.4z M470.1,684.3l-6.8-1
								l-4.1,0.4l6.8,1.1L470.1,684.3z M463.4,683.3l-6.6-1l-4,0.4l6.6,1L463.4,683.3z M456.8,682.2l-6.4-1l-4,0.4l6.4,1L456.8,682.2z
								M450.4,681.3l-6.2-1l-4,0.4l6.2,1L450.4,681.3z"/>
							<path className="sjena cigla" d="M659.4,715.1L659.4,715.1l-149.2-23.4h0l-23.6-3.7h0l-28.3-4.4h0l-6.6-1l0,0l-6.4-1l0,0l-6.2-1l5-0.4
								l7.2,1.1l0,0l6.4,1l0,0l6.6,1l0,0l13.7,2.1h0l22.1,3.4h0l80.6,12.5h0l83.1,12.8L659.4,715.1z M646.7,712.9l12.7,2l3.2-0.7
								l-12.6-2L646.7,712.9z M633.9,710.9l12.1,1.9l3.2-0.7l-12.1-1.9L633.9,710.9z M621.6,709l11.6,1.8l3.2-0.7l-11.6-1.8L621.6,709
								z M609.7,707.1l11.1,1.7l3.2-0.6l-11.1-1.7L609.7,707.1z M598.3,705.3L609,707l3.3-0.6l-10.7-1.6L598.3,705.3z M587.3,703.6
								l10.2,1.6l3.3-0.6l-10.2-1.6L587.3,703.6z M576.7,701.9l9.8,1.5l3.3-0.5l-9.8-1.5L576.7,701.9z M566.5,700.3l9.4,1.5l3.3-0.5
								l-9.4-1.5L566.5,700.3z M556.6,698.8l9.1,1.4l3.3-0.5l-9.1-1.4L556.6,698.8z M547.1,697.3l8.7,1.4l3.3-0.5l-8.7-1.3
								L547.1,697.3z M537.9,695.8l8.4,1.3l3.3-0.5l-8.4-1.3L537.9,695.8z M529,694.4l8,1.3l3.2-0.4l-8.1-1.2L529,694.4z M520.4,693.1
								l7.7,1.2l3.2-0.4l-7.8-1.2L520.4,693.1z M512,691.8l7.5,1.2l3.2-0.4l-7.5-1.2L512,691.8z M503.9,690.5l7.2,1.1l3.2-0.4
								l-7.2-1.1L503.9,690.5z M496.1,689.3l6.9,1.1l3.2-0.4l-6.9-1.1L496.1,689.3z M488.5,688.1l6.7,1l3.2-0.4l-6.7-1L488.5,688.1z
								M481.1,686.9l6.4,1l3.1-0.3l-6.4-1L481.1,686.9z M474,685.8l6.2,1l3.1-0.3l-6.2-1L474,685.8z M467,684.7l6,0.9l3.1-0.3l-6-0.9
								L467,684.7z M460.3,683.7l5.8,0.9l3.1-0.3l-5.8-0.9L460.3,683.7z M453.8,682.7l5.6,0.9l3.1-0.3l-5.6-0.9L453.8,682.7z
								M447.4,681.7l5.4,0.8l3-0.3l-5.4-0.8L447.4,681.7z M441.2,680.7l5.2,0.8l3-0.3l-5.2-0.8L441.2,680.7z"/>
							<polygon className="sjena cigla" points="663,714.2 659.8,715.1 664.1,929.1 667.2,925.8 					"/>
							<path className="test cigla" d="M662.9,714.3l0.1,6.4l-3,1l-0.1-6.5L662.9,714.3 M663,721.1l0.1,6.4l-3,1l-0.1-6.5L663,721.1 M663.2,728
								l0.1,6.4l-3,1.1l-0.1-6.5L663.2,728 M663.3,734.8l0.1,6.4l-3,1.2l-0.1-6.5L663.3,734.8 M663.4,741.6l0.1,6.4l-3,1.2l-0.1-6.5
								L663.4,741.6 M663.6,748.5l0.1,6.4l-3,1.3l-0.1-6.5L663.6,748.5 M663.7,755.3l0.1,6.4l-3,1.4l-0.1-6.5L663.7,755.3
								M663.8,762.2l0.1,6.4l-3,1.4l-0.1-6.5L663.8,762.2 M664,769l0.1,6.4l-3,1.5l-0.1-6.5L664,769 M664.1,775.8l0.1,6.4l-3,1.6
								l-0.1-6.5L664.1,775.8 M664.2,782.7l0.1,6.4l-2.9,1.7l-0.1-6.5L664.2,782.7 M664.4,789.5l0.1,6.4l-2.9,1.7l-0.1-6.5
								L664.4,789.5 M664.5,796.4l0.1,6.4l-2.9,1.8l-0.1-6.4L664.5,796.4 M664.7,803.2l0.1,6.4l-2.9,1.9l-0.1-6.4L664.7,803.2
								M664.8,810l0.1,6.4l-2.9,1.9l-0.1-6.4L664.8,810 M664.9,816.9l0.1,6.4l-2.9,2l-0.1-6.4L664.9,816.9 M665.1,823.7l0.1,6.4
								l-2.9,2.1l-0.1-6.4L665.1,823.7 M665.2,830.6l0.1,6.4l-2.9,2.2l-0.1-6.4L665.2,830.6 M665.3,837.4l0.1,6.3l-2.9,2.2l-0.1-6.4
								L665.3,837.4 M665.5,844.2l0.1,6.3l-2.9,2.3l-0.1-6.4L665.5,844.2 M665.6,851.1l0.1,6.3l-2.9,2.4l-0.1-6.4L665.6,851.1
								M665.7,857.9l0.1,6.3l-2.9,2.4l-0.1-6.4L665.7,857.9 M665.9,864.8l0.1,6.3l-2.9,2.5l-0.1-6.4L665.9,864.8 M666,871.6l0.1,6.3
								l-2.9,2.6l-0.1-6.4L666,871.6 M666.1,878.4l0.1,6.3l-2.9,2.6l-0.1-6.4L666.1,878.4 M666.3,885.3l0.1,6.3l-2.9,2.7l-0.1-6.4
								L666.3,885.3 M666.4,892.1l0.1,6.3l-2.9,2.8l-0.1-6.4L666.4,892.1 M666.5,899l0.1,6.3l-2.9,2.9l-0.1-6.4L666.5,899
								M666.7,905.8l0.1,6.3l-2.9,2.9l-0.1-6.4L666.7,905.8 M666.8,912.6l0.1,6.3l-2.9,3l-0.1-6.3L666.8,912.6 M666.9,919.5l0.1,6.3
								l-2.9,3.1l-0.1-6.3L666.9,919.5 M663.1,714l-3.4,1l0.1,6.9l3.4-1.1L663.1,714L663.1,714z M663.2,720.9l-3.4,1.1l0.1,6.9
								l3.4-1.2L663.2,720.9L663.2,720.9z M663.4,727.7l-3.4,1.2l0.1,6.9l3.4-1.2L663.4,727.7L663.4,727.7z M663.5,734.5l-3.4,1.2
								l0.1,6.9l3.4-1.3L663.5,734.5L663.5,734.5z M663.6,741.3l-3.4,1.3l0.1,6.9l3.4-1.4L663.6,741.3L663.6,741.3z M663.8,748.2
								l-3.4,1.4l0.1,6.9l3.4-1.5L663.8,748.2L663.8,748.2z M663.9,755l-3.4,1.5l0.1,6.9l3.4-1.6L663.9,755L663.9,755z M664,761.8
								l-3.4,1.6l0.1,6.9l3.3-1.6L664,761.8L664,761.8z M664.2,768.7l-3.3,1.6l0.1,6.9l3.3-1.7L664.2,768.7L664.2,768.7z M664.3,775.5
								l-3.3,1.7l0.1,6.9l3.3-1.8L664.3,775.5L664.3,775.5z M664.4,782.3l-3.3,1.8l0.1,6.9l3.3-1.9L664.4,782.3L664.4,782.3z
								M664.6,789.2l-3.3,1.9l0.1,6.9l3.3-2L664.6,789.2L664.6,789.2z M664.7,796l-3.3,2l0.1,6.9l3.3-2L664.7,796L664.7,796z
								M664.8,802.8l-3.3,2l0.1,6.9l3.3-2.1L664.8,802.8L664.8,802.8z M665,809.7l-3.3,2.1l0.1,6.9l3.3-2.2L665,809.7L665,809.7z
								M665.1,816.5l-3.3,2.2l0.1,6.9l3.3-2.3L665.1,816.5L665.1,816.5z M665.3,823.3l-3.3,2.3l0.1,6.9l3.3-2.4L665.3,823.3
								L665.3,823.3z M665.4,830.2l-3.3,2.4l0.1,6.9l3.3-2.4L665.4,830.2L665.4,830.2z M665.5,837l-3.3,2.4l0.1,6.9l3.3-2.5L665.5,837
								L665.5,837z M665.6,843.8l-3.3,2.5l0.1,6.9l3.3-2.6L665.6,843.8L665.6,843.8z M665.8,850.7l-3.3,2.6l0.1,6.9l3.3-2.7
								L665.8,850.7L665.8,850.7z M665.9,857.5l-3.3,2.7l0.1,6.9l3.3-2.8L665.9,857.5L665.9,857.5z M666.1,864.3l-3.3,2.8l0.1,6.9
								l3.3-2.8L666.1,864.3L666.1,864.3z M666.2,871.2l-3.3,2.8l0.1,6.9l3.3-2.9L666.2,871.2L666.2,871.2z M666.3,878l-3.3,2.9
								l0.1,6.9l3.3-3L666.3,878L666.3,878z M666.5,884.8l-3.3,3l0.1,6.9l3.3-3.1L666.5,884.8L666.5,884.8z M666.6,891.7l-3.3,3.1
								l0.1,6.9l3.3-3.2L666.6,891.7L666.6,891.7z M666.7,898.5l-3.3,3.2l0.1,6.9l3.3-3.2L666.7,898.5L666.7,898.5z M666.9,905.3
								l-3.3,3.2l0.1,6.9l3.3-3.3L666.9,905.3L666.9,905.3z M667,912.2l-3.3,3.3l0.1,6.9l3.3-3.4L667,912.2L667,912.2z M667.1,919
								l-3.3,3.4l0.1,6.9l3.3-3.5L667.1,919L667.1,919z"/>
						</g>

						<polygon id="fasada-podupore" className="fasada kontrast prizemlje-clip" points="673.7,709.8 641.1,720.3 621.9,731.5 620.5,731.3 612,738.2 585.1,715.2 
							566.9,711.5 559,703 555.5,698.6 516.4,692.5 516.3,692.5 506.7,699.7 491.1,694.9 479.2,700.9 457.7,694.7 444.3,686.2 
							438.6,680.1 438.8,820.3 662.9,933.7 663,882.9 654,875.3 643.8,862.5 642.5,857.3 638,852 642.4,834.9 647.7,823.8 
							654.1,808.7 648.3,798.8 656.1,788.7 656.9,774 652.2,758.6 655.7,752.3 661.8,748.1 664.3,738.1 664.7,720.2 673.7,709.8 				
							"/>
					</g>
				</g>   
			</g>
		
			<g className="zapad" >
				<polygon className="zid" points="815.7,802.1 663,933.7 662.9,719.3 815.7,678.6 "/>
				<g id="streha">
					<polygon className="osvjetljeno" points="820.5,675 814.7,674.6 663,713.6 671.1,714.9 "/>
					<polygon className="zapad" points="820.4,677.1 671.1,718.4 671.1,714.8 820.4,675 "/>
					<polygon className="sjena" points="816,678.6 663,723.5 671.1,718.4 820.2,677.2 "/>
				</g>
				<g className="prozor">
					<polygon className="sjever" points="791.3,801.8 790.4,801.6 790.4,786.8 791.3,787 "/>
					<polygon className="zapad" points="806.4,790.2 791.3,801.8 791.3,786.5 806.4,775.8 "/>
					<g>
						<polygon className="zapad" points="806.4,772.3 791.3,782.6 791.3,705.7 806.4,700.2 "/>
						<polygon className="staklo" points="794.4,723.1 791.3,724.4 791.3,709 794.4,707.8 "/>
						<polygon className="staklo" points="798,721.4 795.1,722.7 795.1,707.6 798,706.5 "/>
						<polygon className="staklo" points="802.2,719.6 799.3,720.9 799.3,706 802.2,704.9 "/>
						<polygon className="staklo" points="805.6,718.1 802.8,719.3 802.8,704.7 805.6,703.6 "/>
						<polygon className="staklo" points="794.4,742.4 791.3,744 791.3,728.4 794.4,727 "/>
						<polygon className="staklo" points="798,740.5 795.1,742 795.1,726.7 798,725.4 "/>
						<polygon className="staklo" points="802.2,738.3 799.3,739.8 799.3,724.8 802.2,723.5 "/>
						<polygon className="staklo" points="805.6,736.5 802.8,738 802.8,723.2 805.6,721.9 "/>
						<polygon className="staklo" points="794.4,759.8 791.3,761.6 791.3,746 794.4,744.4 "/>
						<polygon className="staklo" points="798,757.6 795.1,759.4 795.1,744 798,742.5 "/>
						<polygon className="staklo" points="802.2,755.1 799.3,756.8 799.3,741.8 802.2,740.3 "/>
						<polygon className="staklo" points="805.6,753.1 802.8,754.7 802.8,739.9 805.6,738.4 "/>
						<polygon className="staklo" points="794.4,777.2 791.3,779.3 791.3,763.7 794.4,761.9 "/>
						<polygon className="staklo" points="798,774.8 795.1,776.7 795.1,761.4 798,759.6 "/>
						<polygon className="staklo" points="802.2,772 799.3,773.9 799.3,758.9 802.2,757.1 "/>
						<polygon className="staklo" points="805.6,769.7 802.8,771.5 802.8,756.7 805.6,755 "/>
					</g>
					<polygon className="zapad" points="811.4,775.6 793.5,788.5 793.5,785.9 811.4,773.2 "/>
					<polygon className="osvjetljeno" points="791.3,704 795.4,704.5 811.2,698.7 807.4,698.3 "/>
					<polygon className="sjever" points="795.4,707.5 791.3,707.1 791.3,704 795.4,704.5 "/>
					<polygon className="sjever" points="793.5,788.5 788.1,787.3 788.1,784.8 793.5,786 "/>
					<polygon className="osvjetljeno" points="808.6,772.7 808,773.1 808,772.5 806.4,772.2 788.1,784.8 793.3,786 811.4,773.2 "/>
					<polygon className="sjever" points="808,773.1 806.3,772.7 806.3,702.8 808,702.9 "/>
					<polygon className="zapad" points="811.2,701.6 795.4,707.5 795.4,704.5 811.2,698.7 "/>
				</g>


				<g id="vrata">
					<polygon className="zapad" points="774.5,834.7 754.7,850.6 754.7,721.8 774.5,715 "/>
					<polygon className="osvjetljeno forFx" points="772.6,782.7 755.4,793.9 755.4,728.5 772.6,722.3 "/>
					<polygon className="osvjetljeno forFx" points="772.6,832.7 755.4,846.3 755.4,798.9 772.6,787.4 "/>
					<path className="sjena" d="M773.3,783.1c0,0.9-0.9,2.1-1.9,2.8c-1.1,0.6-1.9,0.4-1.9-0.5c0-0.9,0.9-2.2,1.9-2.8 C772.4,782,773.3,782.2,773.3,783.1z "/>
					<polygon className="sjena" points="775.8,835.1 774.5,834.7 774.5,714.9 775.8,715 "/>
				</g>
				<g className="prozor">
					<polygon className="zapad" points="716.6,859.7 686,883.4 686,861.2 716.6,839.4 "/>
					<polygon className="sjever" points="686,883.4 684.3,882.7 684.3,861.2 686,861.8 "/>
					<polygon className="osvjetljeno" points="722.4,832.8 719.3,835 719.3,831.8 719.1,831.8 682.7,856.8 690,859.5 725.9,833.9 "/>
					<polygon className="zapad" points="716.6,833.6 686,854.6 686,743.2 716.6,732.2 "/>
					<g>
						<polygon className="staklo" points="692.4,767.5 686,770.3 686,748 692.4,745.6 "/>
						<polygon className="staklo" points="699.9,764.2 693.8,766.9 693.8,745.1 699.9,742.8 "/>
						<polygon className="staklo" points="708.3,760.5 702.5,763.1 702.5,741.8 708.3,739.6 "/>
						<polygon className="staklo" points="715.1,757.5 709.6,759.9 709.6,739.2 715.1,737.1 "/>
						<polygon className="staklo" points="692.4,795.4 686,798.7 686,776.2 692.4,773.3 "/>
						<polygon className="staklo" points="699.9,791.4 693.8,794.6 693.8,772.6 699.9,769.8 "/>
						<polygon className="staklo" points="708.3,787 702.5,790 702.5,768.6 708.3,766 "/>
						<polygon className="staklo" points="715.1,783.5 709.6,786.3 709.6,765.4 715.1,762.8 "/>
						<polygon className="staklo" points="692.4,820.4 686,824.2 686,801.7 692.4,798.3 "/>
						<polygon className="staklo" points="699.9,815.9 693.8,819.6 693.8,797.6 699.9,794.3 "/>
						<polygon className="staklo" points="708.3,810.9 702.5,814.3 702.5,792.9 708.3,789.8 "/>
						<polygon className="staklo" points="715.1,806.8 709.6,810.1 709.6,789.1 715.1,786.2 "/>
						<polygon className="staklo" points="692.4,845.6 686,849.8 686,827.3 692.4,823.4 "/>
						<polygon className="staklo" points="699.9,840.5 693.8,844.6 693.8,822.6 699.9,818.9 "/>
						<polygon className="staklo" points="708.3,834.8 702.5,838.7 702.5,817.3 708.3,813.8 "/>
						<polygon className="staklo" points="715.1,830.2 709.6,833.9 709.6,813 715.1,809.6 "/>
					</g>
					<polygon className="sjever" points="684.9,744.1 693.8,745.8 693.8,741.4 684.9,739.8 "/>
					<polygon className="sjever" points="689.9,863.2 682.7,860.5 682.7,856.8 689.9,859.4 "/>
					<polygon className="zapad" points="725.9,837.3 689.9,863.2 689.9,859.4 725.9,833.9 "/>
					<polygon className="sjever" points="719.3,835 716.6,834.2 716.6,729.8 719.3,730.2 "/>
					<polygon className="zapad" points="725.7,733.8 693.8,745.9 693.8,741.4 725.7,729.8 "/>
					<polygon className="osvjetljeno" points="725.7,729.8 717.2,728.5 684.9,739.8 693.8,741.4 "/>
					<polygon className="zapad" points="719.3,736.3 719.3,835 722.4,832.8 722.4,735.1 "/>
				</g>
			</g>

		</g>

		<g id="temelji">
			<polygon className="zapad pozadina forFx" points="623.3,773.3 475.4,829.3 455.4,819.7 623.3,760.9 	"/>
			<polygon className="sjever pozadina forFx" points="787.6,816.7 623.3,773.3 623.3,761.1 802.2,804.6 	"/>
			<path className="osvjetljeno pozadina" d="M622,755l-188.2,62.7L662.9,934l10.3-8.7l144.1-124.6L622,755z M662.6,920.1L455.4,819.7l167.9-58.6 l178.9,43.5L662.6,920.1z"/>
			<polygon className="sjever forFx" points="664.3,976.7 423.1,836.7 423.1,821.2 664.3,949.2 	"/>
			<polygon className="zapad forFx" points="825.9,803 664.3,949.2 664.3,976.8 825.9,818.6 	"/>
			<polygon className="osvjetljeno" points="825.9,803 815.7,800.2 815.7,802.1 662.9,934 438.6,820.1 438.6,816.1 423.1,821.3 664.3,949.2 	"/>
			<title>Temelji</title>
			<polygon id="Temelj" className="transparent"  onClick={ (e)=> { queryUpdate(e, queryfy("Temelji")) } }points="825.9,803 815.7,800.2 815.7,802.1 662.9,934 438.6,820.1 438.6,816.1 423.1,821.2 423.1,821.2 423.1,836.7 664.3,976.7 664.3,976.8 825.9,818.6 	"/>
		</g>

		<g id="podupore">
			{/* <polygon className="pokrivalo zid"  points="671.1,714.9 663,713.7 663,713.6 438.6,679.8 438.6,820.1 663,934 663,723.4 670.9,718.5" /> */}
			<polygon className="zapad" points="463.2,844.6 472.7,840.9 472.7,726.4 463.2,728.2 "/>
			<polygon className="zapad" points="583.2,908.3 593,902.1 593,755.4 583.2,758.4 "/>
			<polygon className="zapad" points="486.3,956 583.2,896 583.2,908.3 486.3,970.2 "/>
			<polygon className="zapad" points="370.7,869.1 463.2,834.6 463.2,844.6 370.9,880.9 "/>
			<polygon className="sjever" points="477.6,963.3 486.3,970.1 486.3,956 477.6,949.4 "/>
			<polygon className="sjever" points="365,876.2 370.9,880.8 371.3,869.4 365,864.7 "/>
			<polygon className="osvjetljeno forFx" points="463.3,835.2 463.4,828.9 365,864.7 371.3,869.4 "/>
			<polygon className="osvjetljeno forFx" points="583.2,887.2 583.2,896.7 486.3,956 477.5,949.4 "/>
			<polygon className="sjever" points="574.5,756.1 583.8,758.4 583.8,897 574.5,892.3 "/>
			<polygon className="sjever" points="455,725.8 463.6,728 463.6,835.9 455,831.6 "/>
			<polygon className="osvjetljeno forFx" points="473.3,726.2 464.7,724.1 455,725.8 463.6,728 "/>
			<polygon className="osvjetljeno forFx" points="593,755.4 584.1,753.3 574.5,756.1 583.4,758.3 "/>
			<polygon className="zapad" points="511,925.6 499,932.6 499,948.7 511,941.4 "/>
			<polygon className="sjever" points="499,932.6 376,849 376,860.8 499,948.7 "/>
			<polygon className="osvjetljeno forFx" points="376,849 499,932.6 511,925.6 386.9,845.3 "/>
			<polygon className="zapad" points="573.6,792.4 583.8,788.7 583.8,773.1 573.6,776.5 "/>
			<polygon className="osvjetljeno forFx" points="444.3,739.3 573.4,776.6 583.7,773.2 454.3,737.3 "/>
			<polygon className="sjever" points="444.8,751.3 573.6,792.2 573.5,776.6 444.8,739.4 "/>
			<polygon className="zapad" points="519.5,935.8 584,798.5 583.9,788.6 573.6,792.2 511,925.6 511,941.4 "/>
			<polygon className="osvjetljeno forFx" points="511,925.6 501.5,919.7 563.7,789 573.6,792.2 "/>
			<polygon className="osvjetljeno forFx" points="403.8,856.5 463.7,757.4 454.2,754.3 396.8,852 "/>
			<polygon className="zapad" points="403.8,856.5 404.9,857 413.7,853.7 463.6,768.7 463.7,757.4 "/>
			<title>Podupore</title>
			<path className="transparent" id="Podupore" onClick={ (e)=> { queryUpdate(e, queryfy("Podupore")) } } d="M574.5,756.1v14.5l-101.8-28.2v-16l-0.4-0.1l1-0.2l-8.6-2.1l-9.7,1.7v11.7c-3.4,0.3-7.3,0.9-10.7,1.8
				l0.5,0.1v11.9l10.2,3.2v0l-0.8-0.3l-57.2,97.5l-10.1-6.5L376,849v11.6l-11,4l0,0v11.5l5.9,4.6l0-0.4l0,0.5l21.4-8.4l97.5,69.7
				l-12.3,7.2l0,0v13.8l8.8,6.9v0c35.6-22.7,71.1-45.4,106.7-68.1V755.4C586.7,752.9,580.6,753.1,574.5,756.1z M455,783.4l0.4,48.4
				l-36.7,13.3L455,783.4z M415,863.5l57.7-22.7v-80.7l91,28.9l-62.2,130.5L415,863.5z M574.5,892.3l-47.8,28.1l47.8-101.7V892.3z "/>
		</g>

		<g id="kat">

			<g id="ploča" >
				<g className="pozadina">
					<polygon className="zapad kontrast" points="623.3,661.2 438.6,679.9 438.5,540.3 623.3,565.7"/>
					<polygon className="sjena " points="815.3,551.9 622.5,566.3 438.5,543.4 663.8,503.9"/>
					<polygon className="sjena kontrast" points="815.3,678.7 623.3,661.2 623.3,565.7 815.3,551.9"/>
				</g>
				<g className="grede">
					<polygon className="osvjetljeno" points="799.5,674.2 811.3,675 814.6,674.1 802.9,673.3 "/>
					<polygon className="sjena" points="799.5,674.2 811.3,675 811.5,678.9 799.8,678 "/>
					<path className="zapad" d="M814.9,677.9c0,0-0.1,0.6-1.1,1.1c-1.1,0.5-2.2-0.2-2.2-0.2l-0.3-3.9l3.3-0.9L814.9,677.9z"/>
					<polygon className="osvjetljeno" points="788,676.6 801.2,677.6 804.9,676.7 791.8,675.7 "/>
					<polygon className="sjena" points="787.8,676.6 801.2,677.6 801.5,681.6 788.1,680.6 "/>
					<path className="zapad" d="M805.2,680.6c0,0-0.1,0.7-1.2,1.2c-1.2,0.5-2.4-0.2-2.4-0.2l-0.3-4l3.7-1L805.2,680.6z"/>
					<polygon className="osvjetljeno" points="776.9,679.4 790.2,680.5 794.2,679.4 781.1,678.4 "/>
					<polygon className="sjena" points="776.9,679.4 790.1,680.5 790.5,684.7 777.3,683.6 "/>
					<path className="zapad" d="M794.5,683.6c0,0-0.1,0.7-1.4,1.3c-1.3,0.6-2.7-0.2-2.7-0.2l-0.3-4.2l4-1.1L794.5,683.6z"/>
					<polygon className="osvjetljeno" points="763,682.4 777.9,683.7 782.4,682.5 767.6,681.3 "/>
					<polygon className="sjena" points="763,682.4 777.9,683.7 778.2,688.1 763.3,686.7 "/>
					<path className="zapad" d="M782.7,686.9c0,0-0.1,0.8-1.5,1.4c-1.4,0.6-2.9-0.1-2.9-0.1l-0.3-4.5l4.5-1.2L782.7,686.9z"/>
					<polygon className="osvjetljeno" points="750.6,685.9 764.3,687.2 769.2,685.9 755.7,684.7 "/>
					<polygon className="sjena" points="750.6,685.9 764.2,687.2 764.6,691.9 750.9,690.6 "/>
					<path className="zapad" d="M769.6,690.5c0,0-0.1,0.8-1.7,1.5c-1.6,0.7-3.3-0.1-3.3-0.1l-0.3-4.7l5-1.3L769.6,690.5z"/>
					<polygon className="osvjetljeno" points="732.8,689.6 749,691.2 754.6,689.7 738.6,688.2 "/>
					<polygon className="sjena" points="732.8,689.6 749,691.2 749.4,696.2 733.1,694.5 "/>
					<path className="zapad" d="M754.9,694.6c0,0-0.1,0.8-1.9,1.6c-1.8,0.7-3.7,0-3.7,0l-0.4-5l5.6-1.5L754.9,694.6z"/>
					<polygon className="osvjetljeno" points="712.8,693.8 731.4,695.8 737.8,694.1 719.4,692.2 "/>
					<polygon className="sjena" points="712.8,693.8 731.4,695.8 731.8,701.1 713.1,698.9 "/>
					<path className="zapad" d="M738.2,699.3c0,0-0.1,0.9-2.2,1.7c-2,0.8-4.2,0-4.2,0l-0.4-5.3l6.3-1.6L738.2,699.3z"/>
					<polygon className="osvjetljeno" points="695.9,699.2 711.6,701 718.8,699.1 703.4,697.3 "/>
					<polygon className="sjena" points="683.3,697.5 711.6,701 712,706.7 683.7,703 "/>
					<path className="zapad" d="M719.2,704.7c0,0-0.2,1-2.5,1.9c-2.3,0.9-4.7,0.1-4.7,0.1l-0.4-5.7l7.2-1.9L719.2,704.7z"/>
					<polygon className="osvjetljeno" points="673.8,705 688.8,706.9 697.1,704.8 682.4,702.9 "/>
					<polygon className="sjena" points="662.8,703.4 688.8,706.9 689.3,713 663.2,709.1 "/>
					<path className="zapad" d="M697.6,710.8c0,0-0.2,1-2.9,2.1c-2.7,1-5.4,0.2-5.4,0.2l-0.4-6.1l8.3-2.1L697.6,710.8z"/>
					<polygon className="osvjetljeno" points="651.3,712.2 663.1,713.9 672.7,711.4 661.1,709.7 "/>
					<polygon className="sjena" points="663,720.2 438.6,683.8 438.6,679.9 663,713.8 "/>
					<path className="zapad" d="M672.9,717.5c0,0-0.1,1.4-3.2,2.6s-6.7,0.1-6.7,0.1l-0.1-6.3l9.6-2.5L672.9,717.5z"/>
					<g className="pod">
						<polygon className="osvjetljeno" points="811.3,666.8 619.9,653.3 438.8,672 658.8,700.9 "/>
						<polygon className="zapad" points="811.3,673.9 659,713.2 659,700.9 811.3,666.8 "/>
						<polygon className="sjever forFx" points="659,713.2 438.6,679.9 438.6,672 659,700.9"/>
					</g>
				</g>
				<polygon className="sjever pokrivalo" points="663,701.3 438.6,672 438.6,679.9 663,713.8 663,713.9 815.3,674.6 815.3,667.7 "/>
				<polygon className="sjever forFx" points="672,503.1 438.5,546.6 438.5,539.4 672,491.5"/>
				<polygon className="zapad" points="815.3,552.3 663.6,503.7 663.6,493.4 815.3,546.3"/>
				<title>Ploče</title>
				<polygon id="Ploče" onClick={ (e)=> { queryUpdate(e, queryfy("Ploče")) } } className="transparent" points="663,688.2 438.4,663.5 438.4,687.9 663,726.4 663,726.1 816.5,681.4 816.5,659.5"/>
			</g>

			<g className="zapad" onClick={ resetSelection }>
				<g className="zid">
					<polygon className="podloga" points="815.1,667.7 663,701.8 663.1,503.1 815.3,551.9" />
				</g>
				<g className="prozor">
					<polygon className="zapad" points="716.9,646.4 686.1,649.4 686.1,537.8 716.9,545.7 "/>
					<polygon className="staklo" points="692.5,566.1 686.1,565 686.1,542.6 692.5,544.1 "/>
					<polygon className="staklo" points="700.1,567.3 694,566.3 694,544.5 700.1,546 "/>
					<polygon className="staklo" points="708.6,568.8 702.8,567.8 702.8,546.6 708.6,548 "/>
					<polygon className="staklo" points="715.4,570 709.9,569 709.9,548.3 715.4,549.6 "/>
					<polygon className="staklo" points="692.5,593.9 686.1,593.4 686.1,570.8 692.5,571.8 "/>
					<polygon className="staklo" points="700.1,594.5 694,594 694,572 700.1,572.9 "/>
					<polygon className="staklo" points="708.6,595.2 702.8,594.7 702.8,573.3 708.6,574.2 "/>
					<polygon className="staklo" points="715.4,595.7 709.9,595.3 709.9,574.4 715.4,575.3 "/>
					<polygon className="staklo" points="692.5,619 686.1,619 686.1,596.4 692.5,596.8 "/>
					<polygon className="staklo" points="700.1,619 694,619 694,596.9 700.1,597.4 "/>
					<polygon className="staklo" points="708.6,619 702.8,619 702.8,597.6 708.6,598 "/>
					<polygon className="staklo" points="715.4,619 709.9,619 709.9,598.1 715.4,598.5 "/>
					<polygon className="staklo" points="692.5,644.1 686.1,644.6 686.1,622 692.5,622 "/>
					<polygon className="staklo" points="700.1,643.5 694,644 694,622 700.1,621.9 "/>
					<polygon className="staklo" points="708.6,642.8 702.8,643.3 702.8,621.9 708.6,621.8 "/>
					<polygon className="staklo" points="715.4,642.2 709.9,642.7 709.9,621.8 715.4,621.7 "/>
					<polygon className="staklo" points="691,536.5 684.7,537.4 684.7,533.6 691,532.7 "/>
					<polygon className="staklo" points="719,646.6 716.7,646.5 716.7,545.6 719,545.3 "/>
					<polygon className="staklo" points="687.3,678.3 686.1,678.2 686.1,655.2 687.3,655.2 "/>
					<polygon className="staklo" points="691.9,655.8 682.7,655.2 682.7,649.6 691.9,650.1 "/>
					<g>
						<polygon className="zapad" points="718.1,672.6 687.3,678.3 687.3,654.4 718.1,650.6 "/>
						<polygon className="zapad" points="726.9,546 691,536.6 691,532.7 726.9,542.6 "/>
						<polygon className="osvjetljeno" points="722.9,646.2 719,646.6 719,646.1 682.7,649.6 691.9,650.1 728.2,646.4 "/>
						<polygon className="zapad" points="728.2,651.5 691.9,655.8 691.9,650.1 728.2,646.4 "/>
						<polygon className="sjena" points="719,545.3 723.8,546.4 726.9,546 691,536.5 684.7,537.4 719,546.2 "/>
					</g>
				</g>
				<g className="prozor">
					<polygon className="zapad" points="767.8,641.9 746.6,644 746.6,552.3 767.8,557.7 "/>
					<polygon className="staklo" points="751,575.3 746.6,574.6 746.6,556.2 751,557.3 "/>
					<polygon className="staklo" points="756.2,576.2 752,575.5 752,557.5 756.2,558.5 "/>
					<polygon className="staklo" points="762,577.2 758,576.5 758,558.9 762,559.9 "/>
					<polygon className="staklo" points="766.8,578 762.9,577.4 762.9,560.1 766.8,561.1 "/>
					<polygon className="staklo" points="751,598.3 746.6,597.9 746.6,579.4 751,580.1 "/>
					<polygon className="staklo" points="756.2,598.7 752,598.4 752,580.2 756.2,580.8 "/>
					<polygon className="staklo" points="762,599.2 758,598.9 758,581.1 762,581.7 "/>
					<polygon className="staklo" points="766.8,599.6 762.9,599.3 762.9,581.9 766.8,582.5 "/>
					<polygon className="staklo" points="751,619 746.6,619 746.6,600.4 751,600.7 "/>
					<polygon className="staklo" points="756.2,619 752,619 752,600.8 756.2,601.1 "/>
					<polygon className="staklo" points="762,619 758,619 758,601.2 762,601.5 "/>
					<polygon className="staklo" points="766.8,619 762.9,619 762.9,601.6 766.8,601.8 "/>
					<polygon className="staklo" points="751,639.7 746.6,640 746.6,621.5 751,621.4 "/>
					<polygon className="staklo" points="756.2,639.3 752,639.6 752,621.4 756.2,621.4 "/>
					<polygon className="staklo" points="762,638.8 758,639.1 758,621.4 762,621.3 "/>
					<polygon className="staklo" points="766.8,638.4 762.9,638.7 762.9,621.3 766.8,621.3 "/>
					<polygon className="zapad" points="774.9,557.6 749.9,551.1 749.9,547.9 774.9,554.8 "/>
					<polygon className="staklo" points="746.6,667.8 747.4,667.8 747.4,648.1 746.6,648 "/>
					<polygon className="staklo" points="749.9,547.9 744.3,548.5 744.3,551.7 749.9,551.1 "/>
					<polygon className="staklo" points="767.8,641.9 769.3,642 769.3,557.3 767.8,557.5 "/>
					<g>
						<polygon className="zapad" points="768.7,663.8 747.4,667.8 747.4,648.1 768.7,645.5 "/>
						<polygon className="sjena" points="769.3,557.3 771.5,557.9 774.9,557.6 750,551.1 744.3,551.7 769.3,558.1 "/>
						<polygon className="zapad" points="775.8,646.3 750.6,649.3 750.6,644.6 775.8,642.1 "/>
						<polygon className="staklo" points="743.2,649 750.6,649.3 750.6,644.6 743.2,644.3 "/>
						<polygon className="osvjetljeno" points="771.1,641.9 769.3,642 769.3,641.9 768.8,641.8 743.2,644.3 750.6,644.6 775.8,642.1 "/>
					</g>
				</g>
				<g className="prozor">
					<polygon className="zapad" points="806.1,638.4 791.1,639.9 791.1,563.2 806.1,567 "/>
					<polygon className="staklo" points="794.2,582.4 791.1,581.9 791.1,566.5 794.2,567.2 "/>
					<polygon className="staklo" points="797.8,583 794.9,582.5 794.9,567.4 797.8,568.1 "/>
					<polygon className="staklo" points="802,583.7 799.1,583.2 799.1,568.4 802,569.1 "/>
					<polygon className="staklo" points="805.4,584.3 802.6,583.8 802.6,569.3 805.4,569.9 "/>
					<polygon className="staklo" points="794.2,601.6 791.1,601.4 791.1,585.9 794.2,586.4 "/>
					<polygon className="staklo" points="797.8,601.9 794.9,601.7 794.9,586.5 797.8,586.9 "/>
					<polygon className="staklo" points="802,602.3 799.1,602 799.1,587.1 802,587.5 "/>
					<polygon className="staklo" points="805.4,602.5 802.6,602.3 802.6,587.6 805.4,588.1 "/>
					<polygon className="staklo" points="794.2,619 791.1,619 791.1,603.5 794.2,603.7 "/>
					<polygon className="staklo" points="797.8,619 794.9,619 794.9,603.7 797.8,603.9 "/>
					<polygon className="staklo" points="802,619 799.1,619 799.1,604 802,604.2 "/>
					<polygon className="staklo" points="805.4,619 802.6,619 802.6,604.3 805.4,604.5 "/>
					<polygon className="staklo" points="794.2,636.3 791.1,636.6 791.1,621.1 794.2,621 "/>
					<polygon className="staklo" points="797.8,636 794.9,636.3 794.9,621 797.8,621 "/>
					<polygon className="staklo" points="802,635.7 799.1,635.9 799.1,621 802,621 "/>
					<polygon className="staklo" points="805.4,635.4 802.6,635.6 802.6,621 805.4,620.9 "/>
					<polygon className="staklo" points="806.1,638.4 807.2,638.5 807.2,566.7 806.1,566.8 "/>
					<polygon className="staklo" points="790.6,659.8 791.7,659.8 791.7,643.3 790.6,643.3 "/>
					<polygon className="staklo" points="787.3,644.4 793.9,644.6 793.9,640.4 787.3,640.2 "/>
					<polygon className="staklo" points="788.5,562.5 793.4,562.1 793.4,559.4 788.5,559.9 "/>
					<g>
						<polygon className="zapad" points="811.2,566.8 793.4,562.1 793.4,559.4 811.2,564.3 "/>
						<polygon className="zapad" points="806.7,657 791.7,659.8 791.7,643.3 806.7,641.4 "/>
						<polygon className="sjena" points="807.2,566.7 808.5,567 811.2,566.8 793.6,562.1 788.5,562.5 806.5,567.1 807.2,567.1 "/>
						<polygon className="osvjetljeno" points="807.7,638.5 807.2,638.6 807.2,638.5 805.6,638.5 787.3,640.2 794,640.4 811.9,638.7 "/>
						<polygon className="zapad" points="811.9,642.3 793.9,644.4 793.9,640.4 811.9,638.7 "/>
					</g>
				</g>
				<g className="streha">
					<polygon className="zapad" points="819.3,548.5 671.9,497.3 671.9,492.2 819.3,545.7"/>
					<polygon className="sjena" points="819.3,548.5 815.3,552.8 663,504.6 671.9,497.3"/>
				</g>
			</g>

			<g className="sjever">
				<g id="zidovi">
					<g className="puknuti-zid">
						<defs>
							<polygon id="kat-clipPath" points="672,497.4 671.9,492.3 438.5,540.3 438.6,672.2 663,701.3 663,504.6 "/>
						</defs>
						<clipPath id="kat-clipUse">
							<use href="#kat-clipPath"  className="clip-path"/>
						</clipPath>
						<polygon id="crnilo" className="sjena kat-clip" points="672,497.4 671.9,492.3 662.9,494.3 438.5,540.3 438.6,672.2 663,701.3 663,504.6 "/>
						<g id="pukotina" className="kat-clip">
							<polygon className="cigla" points="579.2,651.1 574.7,650.9 574.7,650.9 579.2,651.1 "/>
							<polygon className="cigla" points="579.2,656.7 579.2,651.1 588.5,651.7 588.4,657.4 "/>
							<polygon className="cigla" points="565.8,644.9 570.2,645.1 565.8,644.9 565.8,644.9 "/>
							<polygon className="cigla" points="574.7,645.3 570.2,645.1 570.2,645.1 574.7,645.3 "/>
							<polygon className="cigla" points="593.2,651.9 593.3,651.9 593.2,651.9 593.2,651.9 "/>
							<polygon className="cigla" points="579.2,639.9 583.8,640 579.2,639.9 579.2,639.9 "/>
							<polygon className="cigla" points="583.8,651.4 583.8,645.7 588.5,646 592.7,646.2 595.3,651.5 593.2,651.9 593.2,651.9 "/>
							<polygon className="cigla" points="588.5,640.2 583.8,640 583.8,640 588.5,640.2 "/>
							<polygon className="cigla" points="592,640.3 588.5,640.2 588.5,640.2 592,640.3 "/>
							<polygon className="cigla" points="588.5,645.9 588.5,640.2 592,640.4 593.6,644 592.6,646.1 "/>
							<polygon className="cigla" points="603,664.2 603,658.4 603.4,658.5 608.1,664.6 "/>
							<polygon className="cigla" points="608,670.6 608,664.7 608.2,664.7 611.4,670.9 "/>
							<polygon className="cigla" points="598.1,669.7 598.1,663.9 608,664.7 607.9,670.6 "/>
							<polygon className="cigla" points="588.5,668.8 588.5,663.1 594,663.6 598.1,663.9 598,669.7 "/>
							<polygon className="cigla" points="593.3,651.9 593.2,651.9 593.2,651.9 593.3,651.9 "/>
							<polygon className="cigla" points="574.7,639.7 574.7,634.1 583.8,634.4 583.7,640 "/>
							<polygon className="cigla" points="549.1,622.8 549.1,622.8 545,622.8 545,622.8 549.1,622.8 "/>
							<polygon className="cigla" points="541.1,617.5 541.1,617.5 545,617.5 541.1,617.5 "/>
							<polygon className="cigla" points="545,617.5 549.1,617.5 545,617.5 545,617.5 "/>
							<polygon className="cigla" points="545.1,606.9 553.2,606.8 553.1,612.1 545.1,612.2 "/>
							<polygon className="cigla" points="541.1,617.5 541.1,612.3 549.1,612.2 549,617.5 "/>
							<polygon className="cigla" points="541.1,622.8 545,622.8 541.1,622.8 541.1,622.8 "/>
							<polygon className="cigla" points="565.8,633.9 565.8,633.9 561.5,633.8 561.5,633.8 565.8,633.9 "/>
							<polygon className="cigla" points="565.9,639.3 565.9,633.9 574.7,634.2 574.6,639.7 "/>
							<polygon className="cigla" points="549.1,628.1 549,628.1 549.1,628.1 "/>
							<polygon className="cigla" points="557.3,633.7 561.5,633.8 561.5,633.8 557.3,633.7 557.3,633.7 "/>
							<polygon className="cigla" points="553.2,633.5 553.2,628.2 561.5,628.4 561.5,633.7 "/>
							<polygon className="cigla" points="556.4,628.2 553.2,628.2 553.2,628.2 556.4,628.2 "/>
							<polygon className="cigla" points="553.2,628.2 549.1,628.1 549.1,628.1 553.2,628.2 "/>
							<polygon className="cigla" points="545,606.9 545,606.9 549.1,606.8 545,606.9 "/>
							<polygon className="cigla" points="537.2,612.3 537.2,612.3 541.1,612.3 537.2,612.3 "/>
							<polygon className="cigla" points="541.5,607 545,606.9 541.5,607 "/>
							<polygon className="cigla" points="579.2,639.9 574.7,639.7 574.7,639.7 579.2,639.9 "/>
							<polygon className="cigla" points="541.1,612.3 542.5,612.3 541.1,612.3 541.1,612.3 "/>
							<polygon className="cigla" points="570.2,639.5 565.8,639.4 565.8,639.4 570.2,639.5 "/>
							<polygon className="cigla" points="565.8,639.4 565.8,639.4 561.5,639.2 561.5,639.2 565.8,639.4 "/>
							<polygon className="cigla" points="574.7,639.7 570.2,639.5 570.2,639.5 574.7,639.7 "/>
							<polygon className="cigla" points="537.2,607.1 545,607 545,612.2 537.2,612.3 "/>
							<polygon className="cigla" points="544.1,585.8 545,585.9 545,586.9 "/>
							<polygon className="cigla" points="549.1,590.9 548.4,590.9 548.4,590.9 549.1,590.9 "/>
							<polygon className="cigla" points="548.4,590.9 549.1,590.9 549,591.5 "/>
							<polygon className="cigla" points="549.1,606.8 549.1,606.8 553.2,606.7 549.1,606.8 "/>
							<polygon className="cigla" points="553.2,612 553.2,606.7 555,606.7 558.1,612.1 "/>
							<polygon className="cigla" points="556.7,606.6 557.3,606.6 556.7,606.6 556.7,606.6 "/>
							<polygon className="cigla" points="555,606.7 553.2,606.7 553.2,606.7 555,606.7 "/>
							<polygon className="cigla" points="553.2,596 553.1,596 553.2,596.1 553.2,596 "/>
							<polygon className="cigla" points="549.1,592.7 552.7,596 549.1,596.1 "/>
							<polygon className="cigla" points="593.2,651.9 593.2,651.9 588.5,651.7 588.5,651.7 593.2,651.9 "/>
							<polygon className="cigla" points="570.2,645 570.3,639.5 579.2,639.9 579.1,645.5 "/>
							<polygon className="cigla" points="579.2,651.1 583.8,651.4 579.2,651.1 579.2,651.1 "/>
							<polygon className="cigla" points="588.5,657.4 588.5,651.7 597.5,652.1 598,658 "/>
							<polygon className="cigla" points="574.7,650.8 574.7,645.3 583.8,645.8 583.7,651.4 "/>
							<polygon className="cigla" points="588.5,651.7 588.5,651.7 583.8,651.4 583.8,651.4 588.5,651.7 "/>
							<polygon className="cigla" points="579.2,645.5 579.2,639.9 588.5,640.2 588.4,645.9 "/>
							<polygon className="cigla" points="583.8,645.7 588.5,645.9 583.8,645.7 583.8,645.7 "/>
							<polygon className="cigla" points="579.2,645.5 579.2,645.5 583.8,645.7 579.2,645.5 "/>
							<polygon className="cigla" points="593.2,663.5 593.3,657.7 603,658.4 602.9,664.2 "/>
							<polygon className="cigla" points="585.4,662.9 588.5,663.1 585.4,662.9 "/>
							<polygon className="cigla" points="583.8,662.7 583.8,657.1 593.2,657.8 593.2,663.5 "/>
							<polygon className="cigla" points="588.5,663.1 588.5,663.1 593.2,663.5 588.5,663.1 "/>
							<polygon className="cigla" points="593.2,657.7 598.1,658.1 598.1,658.1 593.2,657.7 593.2,657.7 "/>
							<polygon className="cigla" points="603,658.4 603,658.4 598.1,658.1 598.1,658.1 603,658.4 "/>
							<polygon className="cigla" points="593.2,663.5 594,663.6 593.2,663.5 593.2,663.5 "/>
							<polygon className="cigla" points="506.8,539.6 504.6,540 504.6,540 506.8,539.6 "/>
							<polygon className="cigla" points="501.3,540.6 508,539.4 507.9,544.3 501.3,545.3 "/>
							<polygon className="cigla" points="504.6,540 501.2,540.6 501.2,540.6 504.6,540 "/>
							<polygon className="cigla" points="511.4,558.5 508,559 508,559 511.4,558.5 "/>
							<polygon className="cigla" points="525.8,582 533.3,581.5 533.3,586.6 525.8,587.1 "/>
							<polygon className="cigla" points="529.5,592 529.4,592 529.5,592 "/>
							<polygon className="cigla" points="533.3,591.8 529.5,592 529.5,592 533.3,591.8 "/>
							<polygon className="cigla" points="533.3,576.3 531,576.5 533.3,576.3 "/>
							<polygon className="cigla" points="537.2,596.7 537.2,596.7 533.3,596.9 533.3,596.9 537.2,596.7 "/>
							<polygon className="cigla" points="515,568 522.1,567.3 522.1,572.2 515,572.9 "/>
							<polygon className="cigla" points="522.2,567.2 528.2,566.6 529.3,566.5 529.5,566.7 529.5,571.5 522.2,572.2 "/>
							<polygon className="cigla" points="518.6,562.6 524.5,561.9 525.8,563.1 525.7,566.8 518.6,567.5 "/>
							<polygon className="cigla" points="518.5,577.6 520.8,577.4 518.5,577.6 518.5,577.6 "/>
							<polygon className="cigla" points="514.9,563 516.8,562.8 514.9,563 514.9,563 "/>
							<polygon className="cigla" points="529.6,566.7 534.7,571 529.6,571.5 "/>
							<polygon className="cigla" points="518.6,572.6 525.8,571.9 525.7,577 518.6,577.6 "/>
							<polygon className="cigla" points="525.8,571.9 533.3,571.2 533.3,576.3 525.8,576.9 "/>
							<polygon className="cigla" points="529.3,566.4 528.2,566.5 529.3,566.4 "/>
							<polygon className="cigla" points="522.2,560.2 524.5,561.9 522.2,562.1 "/>
							<polygon className="cigla" points="525.8,563 529.2,566.5 525.8,566.7 "/>
							<polygon className="cigla" points="534.2,591.7 533.3,591.8 533.3,591.8 534.2,591.7 "/>
							<polygon className="cigla" points="537.2,601.9 534.3,602.1 537.2,601.9 "/>
							<polygon className="cigla" points="538.5,601.9 537.2,601.9 537.2,601.9 538.5,601.9 "/>
							<polygon className="cigla" points="545,591.1 541.7,591.3 545,591.1 "/>
							<polygon className="cigla" points="515,558.1 518.6,557.6 522.1,560.2 522.1,562.2 515,563 "/>
							<polygon className="cigla" points="518.6,557.6 518.6,557.6 518.5,557.6 518.5,557.6 518.6,557.6 "/>
							<polygon className="cigla" points="533.4,571.1 534.8,571 536.1,572.3 538.5,575.9 533.4,576.2 "/>
							<polygon className="cigla" points="533.3,576.3 537.2,575.9 533.3,576.3 533.3,576.3 "/>
							<polygon className="cigla" points="522.2,577.3 529.5,576.7 529.5,581.8 522.2,582.3 "/>
							<polygon className="cigla" points="529.6,576.6 531,576.5 537.2,576 537.1,581.1 529.6,581.7 "/>
							<polygon className="cigla" points="505.4,549.6 504.6,549.7 504.6,549.7 505.4,549.6 "/>
							<polygon className="cigla" points="514.8,548.1 513.1,548.4 514.8,548.1 514.8,548.1 "/>
							<polygon className="cigla" points="515,548.8 516,553 515,553 "/>
							<polygon className="cigla" points="511.5,553.6 516.1,553 518.5,557.6 511.5,558.5 "/>
							<path className="cigla" d="M518.6,557.6"/>
							<polygon className="cigla" points="504.6,549.7 501.2,550.2 501.2,550.2 504.6,549.7 "/>
							<polygon className="cigla" points="508,549.2 514.8,548.2 514.9,553.1 508,554 "/>
							<polygon className="cigla" points="518.5,577.6 514.9,577.9 514.9,577.9 518.5,577.6 "/>
							<polygon className="cigla" points="508.2,554.1 508,554.1 508,554.1 508.2,554.1 "/>
							<polygon className="cigla" points="508,554.1 507.9,554.1 508,554.1 "/>
							<polygon className="cigla" points="518.5,557.6 518.5,557.6 514.9,558.1 514.9,558.1 518.5,557.6 "/>
							<polygon className="cigla" points="514.9,558.1 511.4,558.5 511.4,558.5 514.9,558.1 "/>
							<polygon className="cigla" points="504.6,554.6 511.4,553.6 511.4,558.5 504.6,559.4 "/>
							<polygon className="cigla" points="513.5,568.2 511.4,568.4 511.4,568.4 513.5,568.2 "/>
							<polygon className="cigla" points="511.5,563.5 518.5,562.6 518.5,567.6 511.5,568.3 "/>
							<polygon className="cigla" points="511.4,568.4 508,568.8 508,568.8 511.4,568.4 "/>
							<polygon className="cigla" points="514.9,563 511.4,563.5 511.4,563.5 514.9,563 "/>
							<polygon className="cigla" points="511.5,573.3 518.5,572.7 518.5,577.6 511.5,578.2 "/>
							<polygon className="cigla" points="511.4,578.2 511.4,578.2 514.9,577.9 511.4,578.2 "/>
							<polygon className="cigla" points="508,563.9 508,563.9 511.4,563.5 511.4,563.5 508,563.9 "/>
							<polygon className="cigla" points="504.6,559.4 504.6,559.4 508,559 504.6,559.4 "/>
							<polygon className="cigla" points="508,559 515,558.1 514.9,563 508,563.8 "/>
							<polygon className="cigla" points="552.1,612.1 553.2,612.1 552.1,612.1 "/>
							<polygon className="cigla" points="504.3,559.5 504.6,559.4 504.3,559.5 "/>
							<polygon className="cigla" points="572.2,622.9 570.2,622.9 570.2,622.9 572.2,622.9 "/>
							<polygon className="cigla" points="565.8,622.9 563.9,622.9 565.8,622.9 "/>
							<polygon className="cigla" points="570.2,622.9 565.8,622.9 565.8,622.9 570.2,622.9 "/>
							<polygon className="cigla" points="542.6,586 541.1,586.1 541.1,586.1 542.6,586 "/>
							<polygon className="cigla" points="541.1,617.5 539.9,617.5 541.1,617.5 "/>
							<polygon className="cigla" points="591.7,646.1 588.5,645.9 588.5,645.9 591.7,646.1 "/>
							<polygon className="cigla" points="603.4,658.4 603,658.4 603,658.4 603.4,658.4 "/>
							<polygon className="cigla" points="557.3,633.7 554.2,633.6 557.3,633.7 "/>
							<polygon className="cigla" points="537.2,612.3 534.2,612.4 537.2,612.3 "/>
							<polygon className="cigla" points="613.1,677.1 608,676.6 608,676.6 613.1,677.1 "/>
							<polygon className="cigla" points="565.9,617.3 565.9,617 566.2,617.4 "/>
							<polygon className="cigla" points="508,530 508.5,530.8 510.2,531.6 511.5,533.9 508,534.4 "/>
							<polygon className="cigla" points="541.1,601.8 549.1,601.6 549,606.8 541.1,607 "/>
							<polygon className="cigla" points="549.1,601.5 553.8,601.4 555.2,605.8 554.9,606.7 549.1,606.8 "/>
							<polygon className="cigla" points="553.2,601.3 553.2,596.6 554.2,598.4 553.7,601.3 "/>
							<polygon className="cigla" points="545.1,596.4 552.7,596.1 553.1,596.6 553.1,601.4 545.1,601.6 "/>
							<polygon className="cigla" points="545,591.1 546.7,591 545,591.1 545,591.1 "/>
							<polygon className="cigla" points="545.1,589.3 546.7,591 545.1,591 "/>
							<polygon className="cigla" points="541.1,591.3 541.7,591.3 546.7,591 549,592.7 549,596.2 541.1,596.5 "/>
							<polygon className="cigla" points="537.2,586.3 541.1,586.1 542.8,586 545,589.4 545,591.1 537.2,591.5 "/>
							<polygon className="cigla" points="541.1,581.8 542.7,586 541.1,586 "/>
							<polygon className="cigla" points="579.2,645.5 574.7,645.3 574.7,645.3 579.2,645.5 "/>
							<polygon className="cigla" points="588.5,657.4 585,657.2 588.5,657.4 "/>
							<polygon className="cigla" points="593.2,657.7 593.2,657.7 588.5,657.4 588.5,657.4 593.2,657.7 "/>
							<polygon className="cigla" points="508,539.4 512.3,538.7 512.8,543.5 508,544.2 "/>
							<polygon className="cigla" points="533.4,581.5 540.6,580.9 541,581.8 541,586.1 533.4,586.6 "/>
							<polygon className="cigla" points="537.2,586.3 541.1,586.1 537.2,586.3 537.2,586.3 "/>
							<polygon className="cigla" points="534.2,586.6 537.2,586.3 534.2,586.6 "/>
							<polygon className="cigla" points="537.2,575.9 538.5,575.9 540.5,580.9 537.2,581.1 "/>
							<polygon className="cigla" points="529.6,586.9 537.2,586.4 537.1,591.5 529.6,591.9 "/>
							<polygon className="cigla" points="537.2,575.9 537.2,575.9 538.5,575.8 537.2,575.9 "/>
							<polygon className="cigla" points="533.3,607.3 534.2,607.2 533.3,607.3 533.3,607.3 "/>
							<polygon className="cigla" points="533.4,591.8 541.1,591.4 541,596.6 533.4,596.9 "/>
							<polygon className="cigla" points="537.2,596.7 540.3,596.6 537.2,596.7 537.2,596.7 "/>
							<polygon className="cigla" points="533.4,602.1 537.2,602 541.1,601.9 541,607 533.4,607.2 "/>
							<polygon className="cigla" points="537.2,596.7 545,596.4 545,601.7 537.2,601.9 "/>
							<polygon className="cigla" points="504.6,544.8 511.4,543.8 511.4,548.7 505.4,549.5 504.6,549.6 "/>
							<polygon className="cigla" points="516.7,552.8 518.5,552.6 518.5,555.7 "/>
							<polygon className="cigla" points="511.5,543.7 512.9,543.5 514.8,548.2 513.1,548.4 511.5,548.6 "/>
							<polygon className="cigla" points="570.2,622.9 570.3,620.6 572.9,622.9 "/>
							<polygon className="cigla" points="574.7,628.5 574.7,624.4 578.3,628.5 "/>
							<polygon className="cigla" points="561.5,622.8 561.6,617.4 565.8,617.4 566.3,617.4 570.2,620.7 570.2,622.9 "/>
							<polygon className="cigla" points="565.9,628.3 565.9,622.9 570.2,623 573.1,623 574.2,623.3 574.6,624.2 574.6,628.5 "/>
							<polygon className="cigla" points="618.3,683.6 618.3,677.6 624.9,677.7 628.2,684.6 "/>
							<polygon className="cigla" points="618.3,677.6 618.3,677.6 619.1,677.6 618.3,677.6 "/>
							<polygon className="cigla" points="623.6,684.2 628.3,684.7 623.6,684.2 623.6,684.2 "/>
							<polygon className="cigla" points="623.6,690.2 623.6,684.2 628.3,684.8 629.2,686 629.6,688.4 631.8,691.2 "/>
							<polygon className="cigla" points="497.8,531.6 494.6,532.2 494.6,532.2 497.8,531.6 "/>
							<polygon className="cigla" points="629,697.1 629,690.9 631.9,691.3 636.9,698.1 "/>
							<polygon className="cigla" points="497.9,526.8 504.6,525.5 504.5,530.3 497.9,531.5 "/>
							<polygon className="cigla" points="504.6,525.4 506,525.2 507.8,529.7 504.6,530.2 "/>
							<polygon className="cigla" points="553.2,612.1 553.2,612.1 556.9,612 553.2,612.1 "/>
							<polygon className="cigla" points="553.3,622.8 553.2,622.8 553.2,622.8 553.3,622.8 "/>
							<polygon className="cigla" points="549.1,622.8 553.2,622.8 549.1,622.8 549.1,622.8 "/>
							<polygon className="cigla" points="549.1,628.1 549.1,622.8 557.3,622.9 557.3,628.2 "/>
							<polygon className="cigla" points="557.4,617.4 557.4,612.2 562.8,614.2 565.8,617 565.8,617.4 "/>
							<polygon className="cigla" points="545,622.7 545.1,617.5 553.2,617.5 553.1,622.8 "/>
							<polygon className="cigla" points="549.1,617.4 549.1,612.2 556.9,612.1 557.3,612.3 557.3,617.4 "/>
							<polygon className="cigla" points="561.6,633.7 561.6,628.3 570.2,628.5 570.2,633.9 "/>
							<polygon className="cigla" points="553.2,617.5 557.3,617.4 557.3,617.4 553.2,617.5 553.2,617.5 "/>
							<polygon className="cigla" points="549.1,617.5 549.1,617.5 553.2,617.5 553.2,617.5 549.1,617.5 "/>
							<polygon className="cigla" points="608,676.6 608,676.6 603,676.1 603,676.1 608,676.6 "/>
							<polygon className="cigla" points="603,676.1 603,676.1 598.8,675.6 603,676.1 "/>
							<polygon className="cigla" points="583.8,640 583.8,634.3 585.6,634.4 591.9,640.3 "/>
							<polygon className="cigla" points="579.2,634.2 579.2,628.7 585.5,634.3 "/>
							<polygon className="cigla" points="574.7,634.1 570.2,634 570.2,634 574.7,634.1 "/>
							<polygon className="cigla" points="580.1,634.2 579.2,634.2 579.2,634.2 580.1,634.2 "/>
							<polygon className="cigla" points="579.2,634.2 574.7,634.1 574.7,634.1 579.2,634.2 "/>
							<polygon className="cigla" points="565.8,633.9 570.2,634 565.8,633.9 565.8,633.9 "/>
							<polygon className="cigla" points="511.5,533.9 512.2,538.7 511.5,538.7 "/>
							<polygon className="cigla" points="504.6,535.1 511.4,533.9 511.4,538.8 506.8,539.6 504.6,539.9 "/>
							<polygon className="cigla" points="561.5,617.4 561.5,617.4 565.8,617.4 565.8,617.4 561.5,617.4 "/>
							<polygon className="cigla" points="565.8,617.4 565.8,617.4 566.3,617.4 565.8,617.4 "/>
							<polygon className="cigla" points="618.3,677.5 613.1,677 613.1,673 621.7,677.6 "/>
							<polygon className="cigla" points="613.1,677.1 618.3,677.6 613.1,677.1 613.1,677.1 "/>
							<polygon className="cigla" points="608,682.4 608,676.5 618.3,677.6 618.2,683.6 "/>
							<polygon className="cigla" points="613.1,683 618.3,683.6 618.3,683.6 613.1,683 613.1,683 "/>
							<polygon className="cigla" points="603,676 603,670.2 611.5,671 613.1,673 613,677 "/>
							<polygon className="cigla" points="623.6,684.2 623.6,684.2 618.3,683.6 618.3,683.6 623.6,684.2 "/>
							<polygon className="cigla" points="611.5,682.9 613.1,683 611.5,682.9 "/>
							<polygon className="cigla" points="565.8,628.4 562.7,628.3 565.8,628.4 "/>
							<polygon className="cigla" points="570.2,628.4 565.8,628.4 565.8,628.4 570.2,628.4 "/>
							<polygon className="cigla" points="557.3,628.2 557.4,622.8 565.8,622.9 565.8,628.3 "/>
							<polygon className="cigla" points="553.2,622.8 553.2,617.5 561.5,617.5 561.5,622.8 "/>
							<polygon className="cigla" points="574.7,628.5 570.2,628.4 570.2,628.4 574.7,628.5 "/>
							<polygon className="cigla" points="578.8,628.6 574.7,628.5 574.7,628.5 578.8,628.6 "/>
							<polygon className="cigla" points="557.3,617.4 557.3,617.4 561.5,617.4 561.5,617.4 557.3,617.4 "/>
							<polygon className="cigla" points="570.2,633.9 570.3,628.5 579,628.6 579.2,628.8 579.1,634.2 "/>
							<polygon className="cigla" points="501.3,530.9 507.8,529.7 507.9,530 507.9,534.5 501.3,535.7 "/>
							<polygon className="cigla" points="599.1,658 599.1,651.8 599.9,652 601.2,652.9 604.3,658.4 "/>
							<polygon className="cigla" points="540.8,581.8 540.5,580 548.2,579.4 548.2,584.7 543.2,585 "/>
							<polygon className="cigla" points="553.9,578.9 556.5,578.7 553.9,578.9 "/>
							<polygon className="cigla" points="555.9,605.7 554.1,600.4 556.5,600.4 556.4,605.8 "/>
							<polygon className="cigla" points="556.5,600.3 565,600.1 564.9,605.5 556.5,605.7 "/>
							<polygon className="cigla" points="553.4,597.4 552.4,595.1 560.7,594.8 560.6,600.2 554.1,600.4 "/>
							<polygon className="cigla" points="521.3,536.1 521.3,536.1 517.7,536.7 517.7,536.7 521.3,536.1 "/>
							<polygon className="cigla" points="517.7,536.7 515.8,537 517.7,536.7 "/>
							<polygon className="cigla" points="514.1,537.3 521.3,536.1 521.2,541.1 514.1,542.2 "/>
							<polygon className="cigla" points="536.3,564.7 536.3,564.7 532.5,565.1 532.5,565.1 536.3,564.7 "/>
							<polygon className="cigla" points="532.2,565.1 532.5,565.1 532.5,565.1 532.2,565.1 532.2,565.1 "/>
							<polygon className="cigla" points="532.5,560 528.7,560.4 528.7,560.4 532.5,560 "/>
							<polygon className="cigla" points="529.1,565.1 524.5,560.7 526.7,560.7 532.5,560 532.4,565.1 "/>
							<polygon className="cigla" points="528.7,560.4 526.7,560.7 528.7,560.4 "/>
							<polygon className="cigla" points="519.1,556.4 521.3,556.3 521.2,557.3 "/>
							<polygon className="cigla" points="525,559.4 521.3,557.3 521.3,556.3 528.7,555.3 528.6,560.4 526.3,560.7 "/>
							<polygon className="cigla" points="536.3,564.7 540.2,564.3 540.2,564.3 536.3,564.7 536.3,564.7 "/>
							<polygon className="cigla" points="540.2,559 536.3,559.5 536.3,559.5 540.2,559 "/>
							<polygon className="cigla" points="532.5,560 536.3,559.5 532.5,560 532.5,560 "/>
							<polygon className="cigla" points="532.5,560 540.2,559.1 540.2,564.3 532.5,565.1 "/>
							<polygon className="cigla" points="528.7,555.3 536.3,554.3 536.3,559.5 528.7,560.4 "/>
							<polygon className="cigla" points="524.9,555.8 524.9,555.8 521.3,556.3 521.3,556.3 524.9,555.8 "/>
							<polygon className="cigla" points="536.3,554.3 532.5,554.8 532.5,554.8 536.3,554.3 "/>
							<polygon className="cigla" points="544.2,563.8 540.2,564.3 540.2,564.3 544.2,563.8 "/>
							<polygon className="cigla" points="536.4,564.7 544.2,563.9 544.1,569.1 536.4,569.8 "/>
							<polygon className="cigla" points="528.5,565.1 536.3,564.7 536.3,569.9 534.9,570 "/>
							<polygon className="cigla" points="536.7,573.1 534.9,570 540.2,569.6 540.2,574.7 538.9,574.8 "/>
							<polygon className="cigla" points="532.5,554.8 528.7,555.3 528.7,555.3 532.5,554.8 "/>
							<polygon className="cigla" points="521.3,556.3 521.3,556.3 519.7,556.5 521.3,556.3 "/>
							<polygon className="cigla" points="528.7,555.3 528.7,555.3 524.9,555.8 524.9,555.8 528.7,555.3 "/>
							<polygon className="cigla" points="612.2,646.2 609.5,646.1 612.2,646.2 "/>
							<polygon className="cigla" points="639.2,691.4 636.4,691.1 639.2,691.4 "/>
							<polygon className="cigla" points="644.9,692.1 644.9,692.1 639.2,691.4 639.2,691.4 644.9,692.1 "/>
							<polygon className="cigla" points="612.3,664.2 612.3,658.2 622.7,659 622.7,665 "/>
							<polygon className="cigla" points="525,540.5 524.9,540.5 524.9,540.5 525,540.5 "/>
							<polygon className="cigla" points="512.2,537.7 514.1,537.4 514,542.3 513.1,542.4 "/>
							<polygon className="cigla" points="521.3,541.1 517.7,541.7 517.7,541.7 521.3,541.1 "/>
							<polygon className="cigla" points="513.7,545.6 513.1,542.5 517.7,541.8 517.6,546.7 514.7,547.1 "/>
							<polygon className="cigla" points="517.7,541.7 516.3,541.9 517.7,541.7 "/>
							<polygon className="cigla" points="524.9,540.5 521.3,541.1 521.3,541.1 524.9,540.5 "/>
							<polygon className="cigla" points="544.2,574.4 552.3,573.7 552.3,579 544.2,579.6 "/>
							<polygon className="cigla" points="538.9,574.9 540.2,574.8 544.2,574.4 544.1,579.7 540.4,579.9 "/>
							<polygon className="cigla" points="548.2,574 548.2,574 552.3,573.7 548.2,574 "/>
							<polygon className="cigla" points="552.3,573.7 552.3,573.7 556.5,573.3 552.3,573.7 "/>
							<polygon className="cigla" points="540.2,574.7 540.2,574.7 544.2,574.4 540.2,574.7 "/>
							<polygon className="cigla" points="544.2,574.4 548.2,574 544.2,574.4 544.2,574.4 "/>
							<polygon className="cigla" points="538.9,574.9 538.9,574.9 540.2,574.7 538.9,574.9 "/>
							<polygon className="cigla" points="544.2,563.8 547,563.5 544.2,563.8 544.2,563.8 "/>
							<polygon className="cigla" points="540.3,569.5 548.2,568.8 548.2,574 540.3,574.7 "/>
							<polygon className="cigla" points="525,550.7 532.5,549.7 532.4,554.8 528.7,555.2 525,555.7 "/>
							<polygon className="cigla" points="517.7,555 517.7,551.7 525,550.8 524.9,555.8 519.7,556.4 "/>
							<polygon className="cigla" points="529.8,544.9 528.7,545.1 528.7,545.1 529.8,544.9 "/>
							<polygon className="cigla" points="517.7,541.7 525,540.6 524.9,545.6 517.7,546.7 "/>
							<polygon className="cigla" points="521.3,546.2 517.7,546.7 517.7,546.7 521.3,546.2 "/>
							<polygon className="cigla" points="521.3,546.2 524.9,545.6 521.3,546.2 521.3,546.2 "/>
							<polygon className="cigla" points="528.7,545.1 524.9,545.6 524.9,545.6 528.7,545.1 "/>
							<polygon className="cigla" points="521.3,546.2 525,545.7 528.7,545.1 528.6,550.2 521.3,551.2 "/>
							<polygon className="cigla" points="514.8,547.2 517.7,546.8 521.3,546.2 521.2,551.2 515.9,551.9 "/>
							<polygon className="cigla" points="517.7,546.7 514.7,547.2 514.7,547.2 517.7,546.7 "/>
							<polygon className="cigla" points="569.4,616.6 569.4,616.5 569.3,616.5 569.4,616.6 "/>
							<polygon className="cigla" points="602.1,628.1 597.2,628 597.2,628 602.1,628.1 "/>
							<polygon className="cigla" points="605.8,628.1 602.1,628.1 602.1,628.1 605.8,628.1 "/>
							<polygon className="cigla" points="585.5,633.6 585,632.8 587.6,631.1 587.7,627.9 597.2,628.1 597.2,633.8 "/>
							<polygon className="cigla" points="592.4,627.9 588,627.9 592.4,627.9 "/>
							<polygon className="cigla" points="597.2,633.8 597.3,628 607.1,628.2 607.1,634.1 "/>
							<polygon className="cigla" points="597.2,628 592.4,627.9 592.4,627.9 597.2,628 "/>
							<polygon className="cigla" points="569.3,616.5 569.3,616.5 569.4,616.5 569.4,616.5 569.3,616.5 "/>
							<polygon className="cigla" points="582.9,616.4 586,616.4 582.9,616.4 582.9,616.4 "/>
							<polygon className="cigla" points="578.3,616.5 578.3,616.5 582.9,616.4 578.3,616.5 "/>
							<polygon className="cigla" points="569.4,616.5 569.4,616.5 573.8,616.5 569.4,616.5 "/>
							<polygon className="cigla" points="573.8,616.5 573.8,616.5 578.3,616.5 573.8,616.5 "/>
							<polygon className="cigla" points="613.6,670.3 617.4,670.7 622.7,671.2 622.7,677.2 "/>
							<polygon className="cigla" points="633.6,690.7 633.7,684.5 644.9,685.8 644.9,692 "/>
							<polygon className="cigla" points="632.6,690.6 628.4,685.1 628.4,684 633.6,684.6 633.6,690.7 "/>
							<polygon className="cigla" points="628.3,683.9 628.2,677.8 639.2,678.9 639.2,685.1 "/>
							<polygon className="cigla" points="625.5,677.5 628.1,677.8 628.1,682.4 "/>
							<polygon className="cigla" points="628.3,684 630.8,684.2 628.3,684 628.3,684 "/>
							<polygon className="cigla" points="639.4,698.3 639.5,692.1 645.1,692.8 650.9,693.5 650.9,699.8 "/>
							<polygon className="cigla" points="650.9,699.8 650.9,693.7 656.5,694.4 662.3,695.1 662.3,701.3 "/>
							<polygon className="cigla" points="625.5,677.5 624.5,677.4 622.7,677.3 622.8,671.1 633.6,672.1 633.6,678.3 "/>
							<polygon className="cigla" points="637.9,698 632.6,690.6 636.4,691.1 639.2,691.5 639.6,698.3 "/>
							<polygon className="cigla" points="633.6,678.3 633.7,672.1 644.9,673.1 644.9,679.4 "/>
							<polygon className="cigla" points="592.4,627.9 592.4,622.2 602.1,622.3 602.1,628 "/>
							<polygon className="cigla" points="582.9,627.8 583,622.1 592.4,622.2 592.3,627.9 "/>
							<polygon className="cigla" points="578.8,627.7 577.6,626 576.9,622.1 582.9,622.2 582.9,627.8 "/>
							<polygon className="cigla" points="578.3,622 578.4,616.5 586,616.5 587.6,616.5 587.6,622.1 "/>
							<polygon className="cigla" points="574.1,621.7 566.9,616.5 573.8,616.5 578.3,616.5 578.3,622 "/>
							<polygon className="cigla" points="506.4,526.1 505.2,524.2 510.6,523.2 510.5,528.1 507.9,528.5 "/>
							<polygon className="cigla" points="560.7,605.6 569.4,605.5 569.3,611 560.7,611.1 "/>
							<polygon className="cigla" points="562,613.3 559.9,611.1 565,611.1 565,614.3 "/>
							<polygon className="cigla" points="573.5,610.9 573.8,610.9 573.5,610.9 "/>
							<polygon className="cigla" points="565,611 565.5,611 565,611 565,611 "/>
							<polygon className="cigla" points="573.8,610.9 578.3,610.8 573.8,610.9 573.8,610.9 "/>
							<polygon className="cigla" points="560.7,611.1 560.7,611.1 565,611 560.7,611.1 "/>
							<polygon className="cigla" points="556.5,589.5 560.7,589.3 556.5,589.5 556.5,589.5 "/>
							<polygon className="cigla" points="624.3,665.2 628.1,665.5 624.3,665.2 "/>
							<polygon className="cigla" points="613.6,670.3 611.9,668.4 611.2,664.1 617.4,664.7 617.4,670.6 "/>
							<polygon className="cigla" points="628.1,665.5 633.6,665.9 628.1,665.5 628.1,665.5 "/>
							<polygon className="cigla" points="597.2,639.7 602.1,639.9 597.2,639.7 597.2,639.7 "/>
							<polygon className="cigla" points="593.3,639.5 592.4,633.7 602.1,634 602.1,639.8 "/>
							<polygon className="cigla" points="586.4,633.6 592.4,633.8 593.6,639.7 "/>
							<polygon className="cigla" points="596.2,651.4 595.9,647.8 594,645.3 602.1,645.8 602.1,651.6 "/>
							<polygon className="cigla" points="603.7,657.6 607.1,657.9 603.7,657.6 603.7,657.6 "/>
							<polygon className="cigla" points="593.3,639.6 593.3,639.6 597.2,639.7 593.3,639.6 "/>
							<polygon className="cigla" points="610.8,658.1 607.1,657.9 607.1,657.9 610.8,658.1 "/>
							<polygon className="cigla" points="607.1,651.9 607.1,651.9 602.1,651.6 602.1,651.6 607.1,651.9 "/>
							<polygon className="cigla" points="603.7,657.6 601.4,654.5 601.3,652 599.4,652 599,651.4 607.1,652 607.1,657.8 "/>
							<polygon className="cigla" points="593.9,645.3 592.9,643.1 593.4,640 593.3,639.5 597.2,639.7 597.2,645.5 "/>
							<polygon className="cigla" points="611.1,664.1 611.2,664 603.7,657.6 612.2,658.3 612.2,664.2 "/>
							<polygon className="cigla" points="599,651.5 602.1,651.6 599,651.5 599,651.5 "/>
							<polygon className="cigla" points="511.8,532.8 519.5,531.5 519.5,536.5 512.2,537.6 "/>
							<polygon className="cigla" points="550.5,584.5 552.3,584.4 550.5,584.5 "/>
							<polygon className="cigla" points="548.2,590.8 548.3,590 556.5,589.6 556.4,594.9 552.2,595.1 "/>
							<polygon className="cigla" points="556.5,589.5 552.3,589.8 552.3,589.8 556.5,589.5 "/>
							<polygon className="cigla" points="552.3,584.4 556.5,584.1 556.5,584.1 552.3,584.4 552.3,584.4 "/>
							<polygon className="cigla" points="548.2,590 552.3,589.8 548.2,590 548.2,590 "/>
							<polygon className="cigla" points="544.2,586.1 544.2,585 552.3,584.4 552.3,589.8 547.6,590 "/>
							<polygon className="cigla" points="560.7,605.6 560.7,605.6 565,605.5 560.7,605.6 "/>
							<polygon className="cigla" points="556.5,605.8 556.5,605.8 560.7,605.6 556.5,605.8 "/>
							<polygon className="cigla" points="559.9,611.1 560.7,611.1 559.9,611.1 559.9,611.1 "/>
							<polygon className="cigla" points="559.9,611.1 555.9,605.7 560.7,605.7 560.6,611.1 "/>
							<polygon className="cigla" points="565,605.5 565,605.5 569.4,605.4 565,605.5 "/>
							<polygon className="cigla" points="548.3,579.4 553.9,579 556.5,578.8 556.4,584.1 548.3,584.6 "/>
							<polygon className="cigla" points="617.4,670.7 620.7,671 617.4,670.7 617.4,670.7 "/>
							<polygon className="cigla" points="578.8,627.7 587.6,627.9 587.6,631.1 583.3,631.1 "/>
							<polygon className="cigla" points="617.4,670.6 617.5,664.6 624.3,665.2 628.1,665.5 628.1,671.6 "/>
							<polygon className="cigla" points="613.6,670.3 613.6,670.3 617.4,670.7 613.6,670.3 "/>
							<polygon className="cigla" points="597.2,645.5 597.3,639.7 607.1,640.1 607.1,645.9 "/>
							<polygon className="cigla" points="602.1,651.6 602.2,645.8 612.2,646.3 612.2,652.2 "/>
							<polygon className="cigla" points="607.1,651.9 612.2,652.2 607.1,651.9 607.1,651.9 "/>
							<polygon className="cigla" points="628.1,671.6 628.2,665.5 639.2,666.4 639.2,672.5 "/>
							<polygon className="cigla" points="607.1,640.1 612.2,640.2 607.1,640.1 607.1,640.1 "/>
							<polygon className="cigla" points="613.8,652.3 612.2,652.2 612.2,652.2 613.8,652.3 "/>
							<polygon className="cigla" points="602.1,639.9 602.1,639.9 607.1,640.1 602.1,639.9 "/>
							<polygon className="cigla" points="511.8,531.5 509.4,530.7 508,528.6 514.1,527.5 514,532.4 511.8,532.7 "/>
							<polygon className="cigla" points="569.3,616.5 565,614.3 565,611 573.8,610.9 573.8,616.5 "/>
							</g>
						<g id="fasada" className="kat-clip">
							<path className="sjever fasada" d="M633.8,695.2c-2.4-3-7.3-5.3-8.1-9.2c-0.2-1,0.1-0.1-1.2-2.5c-0.8-1.5-4.1-3.3-5.1-4.7
								c-1.5-2.1-4.1-1.7-5.9-5.1c-2.6-1.5-4.6-3-6.9-5c-1.1-0.9-1.9-2.2-3.1-3.1c-1.8-1.5-3.8-2.4-5.6-4.1c-0.9-0.9-2.2-2.2-3.3-3.7
								c-2.6-1.6-5.2-3.6-7.1-5.5c-1.8-1.8-3.9-3.7-5.1-5.9c-0.9-1.7-1-3.6-1.7-5.3c-2.6-0.7-5.2-1.9-6.6-2.6
								c-4.7-2.6-7.7-7.4-12.3-9.8c-1.8-0.9,5.6-0.7,4-1.7c-1.6-1-2.5-3.5-4.4-4.7c-2.9-1.8-5-0.9-8.5-1.6c-2.6-0.5-4.4-3.1-6.9-3.5
								c-1.3-0.2-1.1-0.5-2.2-0.9c-0.9-0.3,0,0-0.5-0.8c-1.2-2,4.1-5.1,2.9-7c-2.4-3.6-4.9-8.6-7.8-11.8c-2.2-2.3-0.1-3.1-2.1-5.6
								c-2.1-2.7-2-3.1-0.6-6.1c1.7-4-12.1-6-12.8-10.1c-0.4-2.3-0.2-4-0.3-6.3c0-2.6-8.7-3.6-9.7-5.7c-2.2-4.8-3.2-8.8-4.2-13.6
								c-0.7-3.4-2.8-11-3.9-14.3c-0.9-2.8-2.7-1.8-1.8-5.1c0.3-0.9,0.7-1.7,1.1-2.5c-0.1-0.2-0.3-0.4-0.4-0.6l-65.5,13.4l0,132.6
								l198.1,25.4C635.7,697.3,634.7,696.3,633.8,695.2z"/>
							<path className="sjever fasada" d="M673.8,491.5l-9,1.9l-158.2,32.5c0.3,0.2,0.6,0.5,0.9,0.7c1.7,1.8,1.9,4.4,3.6,6.2c2,2.1,7.4,1.9,9,4.9
								c0.8,1.4,1.1,3.9,1.8,5.6c1,2.1,1,4.1,1.5,6.5c0.5,2.1,0.4,2.9,2.5,4.4c1.6,1.1,4.5,1.6,5.5,3.2c0.5,0.8,0.5,2.1,0.8,3.1
								c2.5,0.4,6.1,2.8,8.1,4.3c0.5,0.4,2-0.4,2.4,0.1c0.9,1.2,0.6,3.2,1,4.6c0.2,0.8,1.6,0.3,1.7,1.1c0.1,2.7,2.2,5.3,3.2,7.9
								c1,2.7,1.4,7.9,2.3,10.4c1,2.7,2.3,1.4,2.9,4.1c0.5,2.5,2.2,4,3.4,6.2c0.6,1.2,1.1,3.3,2.2,4.2c0.8,0.7,2.5,0.8,3.5,1.3
								c1.3,0.8,2.4,1.8,3.2,3c2.5,3.8,5.3,7,9.6,9.1c4,2,8.2,4.1,12.3,6.2c2.1,1.1,5.8,1.8,8.4,1.1c1.3-0.3,2.5-1.2,3.8-1
								c1.7,0.2,1.8-0.1,1.9,1.3c0.1,1.2-0.4,1.1-0.5,2.5c-0.5,3.6-1.2,4.4,0.5,7c0.5,0.8-1.3,5.7-1,6.7c0.7,2.1,3.9-0.9,4.8,0.6
								c1.3,2.4,1.4,1.4,0.3,3.8c-1.4,3.4-4.8,4.5-1.8,6.3c2.1,1.3,0.9,3.7,3.6,7.1c4.5,0.9,9.5,4.2,11.7,7.1c1.5,0.1,4.1,6.9,5.8,8.9
								c1.8,2.2,1.5,4.1,2.7,6.6c0.8,1.8,2.4,3.8,3.7,5.5c2.1,2.5,4.7,4.7,7.5,6.3c2.3,1.3,4.4,4,6,6.3l18.4,2.3l1.4-197.6l9-7.2
								L673.8,491.5z"/>
						</g>
					</g>

					<polygon id="Zidovi" onClick={ (e) => { queryUpdate(e, queryfy("Prezidavanje")) }  } className="pokrivalo zid" points="672,497.4 671.9,492.3 662.9,494.3 438.5,540.3 438.6,672.2 663,701.3 663,504.6" />
				</g>
			</g>

		</g>		

		<g id="nekonstrukcijski">
			<polygon className="sjever" points="812.9,528.3 815.3,528 815.3,522.9 	"/>
			<polygon className="zapad" points="817.3,528.8 815.3,528 815.3,522.9 	"/>
			<polygon className="sjever forFx" points="759,477.5 735.7,492.3 731.9,509.2 	"/>
			<polygon className="sjever" points="668.9,465.3 672,464.6 672,457.9 	"/>
			<polygon className="zapad" points="675.3,465.8 672,464.4 672,457.9 	"/>
			<polygon className="sjever" points="672,486.6 659,489.4 663.3,494.2 672,492.4 	"/>
			<circle className="forFx" cx="672.3" cy="471" r="6.6"/>
			<polygon className="zapad" points="675.3,482.9 672,481.6 672,475.7 675.3,477 	"/>
			<polygon className="sjever" points="668.9,482.3 672,481.6 672,475.7 668.9,476.4 	"/>
			<path className="forFx" d="M672,481.6c-4,0-7.7,0.8-7.7,1.7s0,5,0,5l7.7-1.6l7.2,2.7c0,0,0-4,0-4.9S676,481.6,672,481.6z"/>
			<polygon className="osvjetljeno forFx" points="759.8,476.5 754.6,477.6 727.8,507.6 731.9,509.2 	"/>
			<path className="forFx" d="M815.3,537.7c-1.6,0-3.4,0.2-3.4,0.8c0,0.2,0,2.6,0,2.6l3.4-0.4v0l3.5,1.3c0,0,0-2.4,0-3
				S817,537.7,815.3,537.7z"/>
			<circle className="forFx" cx="815.4" cy="531.5" r="4"/>
			<polygon className="zapad" points="783.9,528.9 759.8,476.5 731.8,509.2 672,486.6 672,492.2 819.3,545.8 819.3,542.3 	"/>
			<polygon className="zapad kontrast" points="778.3,525.1 738.9,509.9 759.9,484.1 	"/>
			<path className="zapad forFx" d="M763,505.2c0,4.4-1.8,7.2-4.2,6.3c-2.3-0.9-4.3-5.3-4.3-9.8c0-4.5,1.9-7.3,4.3-6.2
				C761.2,496.4,763,500.8,763,505.2z"/>
			<polygon className="sjena" points="759.9,484.1 758.6,485.8 776.1,524.3 778.3,525.1 	"/>
			<polygon className="sjever" points="812.9,538.5 815.4,538.2 815.4,534 812.9,534.3 	"/>
			<polygon className="zapad" points="817.3,538.9 815.4,538.2 815.4,534 817.3,534.8 	"/>
			<path id="EGB" onClick={ (e)=> { queryUpdate(e, queryfy("Nekonstrukcijski")) } } className="transparent" d="M818.8,539.1c0-0.4-0.7-0.8-1.6-1v-3c1.2-0.7,2.1-2,2.1-3.5c0-1.6-1-3.1-2.4-3.7l-1.6-4.9l-2.4,5.4l0,0
				c-1,0.7-1.6,1.9-1.6,3.2c0,1.3,0.6,2.4,1.6,3.2v3.3c-0.6,0.1-1,0.3-1,0.6c0,0.1,0,0.5,0,0.9l-28-10.6l-24.1-52.4l-0.9,1l0,0l0.9-1
				l-5.2,1l-5.6,6.3l-13.4,8.5l-2,8.7l-5.8,6.6l4,1.6l0.2-0.2l-0.3,0.3l-52.6-19.9c0,0,0-4,0-4.9c0-0.7-1.6-1.8-3.9-2.4v-5l-0.1,0
				c2.2-1.1,3.7-3.3,3.7-5.9c0-2.7-1.6-5.1-4-6.1l-2.9-7l-3.2,7.4l0,0c-1.9,1.2-3.2,3.3-3.2,5.7c0,2.4,1.3,4.5,3.2,5.7v5.1
				c-2.6,0.3-4.6,0.9-4.6,1.5c0,0.9,0,5,0,5l-5.2,1.1l4.3,4.8l8.7-1.8v-0.2l147.3,53.5v-3.5l-0.5-0.2
				C818.8,542.1,818.8,539.7,818.8,539.1z M672,481.6L672,481.6L672,481.6C672,481.6,672,481.6,672,481.6z M672.1,464.4
				C672.1,464.4,672.1,464.4,672.1,464.4L672.1,464.4L672.1,464.4z"/>

			<g id="nekonstrukcijski-prizemlje">
				<g class="prag">
					<polygon className="zapad forFx" points="786.2,839.7 763.3,860.8 763.3,855.7 786.2,835.1 "/>
					<polygon className="sjever forFx" points="763.2,860.8 752.6,857.1 752.6,852.1 763.2,855.7 "/>
					<polygon className="osvjetljeno" points="777.7,832.5 775.8,834.1 775.8,832.3 752.6,852.1 763.3,855.7 786.2,835.1 "/>
				</g>
				<path className="sjever" d="M825.9,721.4l-38.4-4.3v67.7v42.7l5.5,1.5v-43l0,0C793,785.5,791,720.5,825.9,721.4z "/>
				<path className="zapad kontrast" d="M818.8,835.6c0,0.9,3.7,3.1,3.7-3.4c0-6,0-111.9,0-111.9l-3.7,1.7C818.8,721.9,818.8,835.1,818.8,835.6z" />
				<polygon className="osvjetljeno" points="825.9,714 779.3,709.3 721.7,726.8 776.3,734.3 "/>

				<polygon className="osvjetljeno forFx" points="795.1,681.3 759.8,715.5 776.9,730.2 "/> {/* ona piramida */}

				<path className="zapad kontrast" d="M770.1,886.9c0,9.4-5.6,7.7-5.6,5.5c0-1.1,0-144.4,0-144.4l5.6-1.4C770.1,746.6,770.1,878.8,770.1,886.9z" />
				<polygon className="zapad" points="823.1,702.6 821.9,712.2 820.7,715.5 816.6,717.2 801.9,681.4 783.6,731.1 775.1,734.6  773.5,731.2 770.1,721 768.4,733.2 768.4,747.4 825.9,721.4 825.9,710.7 "/>
				<polygon className="zapad kontrast" points="817.1,721.8 784.5,735.9 802.5,687.2 "/>
				<path className="zapad forFx" d="M804.9,711.2c0,5.2-1.3,9.9-3,10.5c-1.7,0.7-3.1-3.1-3.1-8.4c0-5.3,1.4-10.1,3.1-10.6 C803.6,702.2,804.9,706,804.9,711.2z "/> {/* oko usred piramide */}
				<g>
					<polygon className="sjever forFx" points="795.1,681.3 801.9,681.4 783.6,731.1 776.9,730.2 "/>
					<polygon className="sjever forFx" points="763.8,720.2 770.1,721 768.4,733.3 762.1,732.3 "/>
					<polygon className="sjever forFx" points="818.7,702.4 823.4,702.5 821.9,712.2 817.9,711.8 "/>
					<polygon className="sjever forFx" points="817.9,711.8 816.5,714.7 820.7,715.5 821.9,712.2 "/>
				</g>
				
				<path className="sjena forFx" d="M741.9,741.3c0-2.6-2-4.7-4.6-5.1c-0.7-0.1-1.4,0-2,0.2l-2.7,0.3l0.5,1.5c-0.3,0.6-0.5,1.2-0.5,2 c0,1.8,1,3.4,2.4,4.5l0.5,1.8l4-1.5C739.7,745,741.9,743.2,741.9,741.3z "/>
				<polygon className="osvjetljeno" points="815.2,721.6 817.1,721.8 784.5,735.9 785,734.5 "/>
				<polygon className="sjena" points="815.2,721.6 817.1,721.8 802.5,687.2 801.7,689.3 "/>
				<path className="sjever" d="M722.3,726.8V740l0.8,51.9l0.5,89.6l8.6,3.4l-0.3-58.6c0,0-4.9-75.2,36.3-78.9v-14.2L722.3,726.8z  M733.7,736.7c-2.5-0.3-4.4,1.4-4.5,3.9v-4.5L733.7,736.7z M729.1,778.5v-37.4c0.1,2.6,2.2,5,4.8,5.4c2.7,0.4,4.8-1.4,4.8-4.1 c0-2.7-2.1-5.1-4.7-5.6l27.9,3.6c0,0,0,2.8,0.1,2.8C736,748.8,729.1,778.5,729.1,778.5z "/>
				<path className="zapad forFx" d="M768.4,747.4c-29.1,2.4-37.2,39.6-36.1,78.8l0.1,58.9l8.8-7.9v-40.5C741.1,836.7,737.4,757.5,768.4,747.4z "/>
				<path className="zapad forFx" d="M811.7,727.8c-8.5,1.6-18.1,18.2-18.7,58.2v43l4.1-3.7v-42.1C797.1,783.3,796.5,734.1,811.7,727.8z "/>
				
				<path id="egb-prizemlje" onClick={ (e)=> { queryUpdate(e, queryfy("Nekonstrukcijski")) } } className="transparent" d="M823.3,703c0-0.2,0-0.3,0.1-0.5c-0.9,0-4.1-0.6-4.6-0.1c-0.7,0.7-0.4,4.7-0.5,5.7c-0.1,1.2,0.2,3.5-0.7,4.5
					c-0.6,0.6-2,0.7-2.7,0c-0.6-0.6-1-2.3-1.3-3.1c-0.8-1.9-1.6-3.8-2.3-5.7c-1.9-4.6-3.8-9.3-5.7-13.9c-1.2-2.8-3.5-8.5-3.5-8.5l0,0
					c-2,0-5.4-0.8-7.1,0.1c-1.7,0.9-3.4,3.3-4.8,4.6c-6.2,6.1-12.5,12.1-18.7,18.2c-2.7,2.6-5.3,5.2-8,7.8c-0.9,0.9-2,2.4-3.1,3
					c-1.6,0.9-4,1.2-5.8,1.8c-4,1.2-8.1,2.4-12.1,3.7c-6.9,2.1-13.7,4.2-20.6,6.2c1.2,0,0.6,6.6,0.6,7.8c0,1.9,0,3.9,0,5.8
					c0,4.8,0.2,9.7,0.2,14.5c0.2,12.3,0.5,24.7,0.6,37c0.3,23.6,0.3,47.3,0.4,70.9c0,5.5,0.1,11.1,0.1,16.6c0,0.6-0.2,1.7,0.2,2.2
					c0.8,0.9,3.8,1.5,4.9,1.9c0.8,0.3,3.2,0.8,3.5,1.6c1.6-1.4,3.2-2.9,4.8-4.3c1-0.9,3.4-2.4,4-3.6c0.3-0.8,0-2.4,0-3.2
					c0-2.4,0-4.7,0-7.1c0-10,0.2-19.9,0-29.9c-0.3-18.1,0.3-36.3,4.4-54.1c2.8-12.1,7.5-27.2,19.1-33.8c0,21.3,0,42.7,0,64
					c0,24.6,0,49.3,0,73.9c0,1.6-0.4,3.7,0,5.3c0.3,1.4,1.7,2.1,3.1,1.5c2.5-1.1,2.5-5.2,2.5-7.4c0.1-7.2,0-14.4,0-21.6
					c0-27.5,0-54.9,0-82.4c0-10,0-20.1,0-30.1c0-1.3-0.6-4.4,0-5.6c0.4-0.7,2.4-1.2,3.1-1.5c3-1.4,6-2.7,9-4.1c1.8-0.8,3.5-1.6,5.3-2.4
					c0,14.5,0,29,0,43.4c0,13.1,0,26.2,0,39.4c0,1.1-0.7,5,0,5.9c0.4,0.6,4.1,1.3,4.9,1.3c1.5,0.1,2.5-1.5,3.7-2.5
					c1.6-1.4,1.1-2.8,1.1-4.9c0-4.1,0-8.2,0-12.3c0-12.1-0.4-24.2,0.3-36.2c0.5-9.6,1.4-19.3,4-28.5c1.1-4.1,2.6-8.4,5.1-11.9
					c2-2.8,4.5-4.2,7.6-5.6c1.6-0.7,3.2-1.4,4.7-2.1c0,17.6,0,35.3,0,52.9c0,18.1,0,36.3,0,54.4c0,1-0.4,2.7,0,3.6
					c0.4,1,1.4,1.4,2.4,0.9c1.5-0.8,1.3-3.4,1.3-4.8c0.1-5.8,0-11.7,0-17.5c0-21.8,0-43.6,0-65.4c0-7.9,0-15.9,0-23.8
					c0-2.1,0.2-2,2.2-2.9c1.7-0.8,1.2-1.4,1.2-3.2c0-3.6,0.4-7-0.8-10.3C824.5,706.6,823.9,704.8,823.3,703z M729.1,736.2
					c1.4,0.2,2.8,0.4,4.1,0.5c-2.3,0.2-4,1.6-4.1,4C729.1,739.2,729.1,737.7,729.1,736.2z M732.2,826.2c0,17,0.1,34.1,0.1,51.1
					c0,2.5,0,5,0,7.4c-0.1-19.8,0.4-39.6-0.3-59.4c-0.5-12.5,0.3-25.2,2.8-37.4C732.4,800.5,731.9,813.5,732.2,826.2z M729.1,778.5
					c0-12.5,0-24.9,0-37.4c0.2,4.8,4.8,6,8.5,4.7c3.5-1.3,6.2-5,2.5-8.2c4.5,0.6,8.9,1.1,13.4,1.7c2.4,0.3,4.8,0.5,7.2,0.9
					c0.2,0,1.1,0,1.2,0.2c0.5,0.5,0,2.7-0.3,2.9c-0.4,0.4-2.2,0.6-2.8,0.8c-0.9,0.3-1.8,0.6-2.7,1c-1.8,0.7-3.5,1.6-5.2,2.6
					c-3.1,1.8-5.9,4.1-8.4,6.7c-4.3,4.4-7.6,9.7-10.2,15.3C731.1,772.4,729.9,775.4,729.1,778.5z M775.1,734.6
					c-0.1-0.2-0.2-0.3-0.2-0.5c0.3,0,0.8,0.1,1.1,0.1C775.7,734.4,775.4,734.5,775.1,734.6z M793,786c-0.3-10.2,0.7-20.6,2.8-30.6
					C793.9,765.5,793.2,775.8,793,786z M805.4,731.9c0.7-1,1.3-2,2.6-2.4C807.1,730.2,806.2,731,805.4,731.9z M821.9,712.2
					c-0.2,0.4-0.3,0.9-0.5,1.3C821.5,713.2,821.8,712.4,821.9,712.2z"/>
			</g>
		</g>

	</g>
</svg>
}


export default HeroNav