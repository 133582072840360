import { useState, useEffect } from 'react'
import axios from 'axios'
import { conzole, processContents } from "./commons"

const database = "./db.json"
// const port = window.location.hostname === "localhost" ? ":5000" : ""

const constructHref =str=> {
    str = typeof str !== "undefined" || !!str ? str : "404" 
    const newUrl = "./articles/" + str + ".html"
    return newUrl
}

export const constructInternalUrl =href=> {
    const subRoot = window.location.pathname
    const newUrl = href.replace( /(\?.*)/, `${subRoot.substring(1, subRoot.length)}$1`)
    // console.log(newUrl)
    return newUrl
}

const fetch =url=> axios.get(url)


// ############################################################################################################################## 

const serverBuilder =()=> ({
    
    database,
    fetch,
    fetchContentsFromDatabse: ()=> {
        conzole.holla(`Fetching from database: '${database}'.`)
        return fetch(database).then(response=> response.data)
    },
    fetchArticle: articleUrl=> {
        const fullArticleUrl = constructHref(articleUrl)
        conzole.holla(`Fetching article: '${fullArticleUrl}'`)
        return fetch(fullArticleUrl).then(response=> response.data)
    }
})
const server = serverBuilder()
export default server

// ##############################################################################################################################

export const useDatabaseForContents =()=> {

    // custom hook po primjeru sa https://reactjs.org/docs/hooks-custom.html

    const [ contents, setContents ] = useState({}) 
    const setContentsWithLog =data=> { conzole.success("Setting contents..."); setContents(data) }

     useEffect(()=> {
       server.fetchContentsFromDatabse()
         .then(contents=> {
            contents.manual.contents = processContents(contents.manual)
            console.debug("Logging database response:", contents); 
            setContentsWithLog(contents)
         })
         .catch(conzole.failure)
     }, []) 
     return contents
}
    






