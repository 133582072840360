import { useState, useReducer, useEffect } from 'react'
import { conzole } from  "./commons"
import { processQuery } from "./filter"
import assembleArticle from "./articleComposer"
import preprocessArticle from "./preprocessArticle"

export const useUserQueryReducer =()=> {
    
    // ovdje samo postavljam klopke za state query-ja. Logika oblikovanja query-ja je u filter.js-u
    const [ userQuery, setUserQuery ] = useReducer(processQuery, { type: "reset", content: "info", text: "" } ) 
    if (!!userQuery) userQuery.__proto__.update =q=> { setUserQuery(q) }
    
    // efekt dohvaća članak ako query ima url u sebi, a ima url ako je kliknut link konkretnog članka (Aside) ili ako filtriranjem sadržaja ostao samo jedan članak (App)
    useEffect(()=> {
        if (!!userQuery.url || userQuery.url === "") { // ako postoji url
            if (!userQuery.title || !userQuery.chapter) {
                console.error("This query is insufficient for propper article display!", userQuery)
            }
            assembleArticle(userQuery)
                .then(article=> preprocessArticle(article))
                .then(processedArticle=> {
                // console.debug(article)
                setUserQuery({ type: "display article", html: processedArticle })  // pokazat će se jer procesQuery reducer iz filter.js-a u taj article postavlja za USER_QUERY.articleToShow
            })
        }
        else {
            conzole.holla("Skipping the fetching article part.")
        }
    }, [userQuery.chapter]) // !!!  O P R E Z !  NE DIRAJ !  Ovdje ne slušati React-lint-drek. Dependancy-ji su dobri ovakvi kakvi jesu jer se efekt treba ponovno pokretati samo kada se promijeni traženi članak !!!

    return userQuery
}

export const usePopUp =()=> {

    const [ item, set ] = useState({ x: 0, y: 0, show: false })
    return {
       item,
       set: (x, y)=> set({ x: x, y: y, show: true }),
       close:   ()=> set({ show: false })
    } 
}