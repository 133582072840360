import React, { useEffect } from 'react';
import Header from './components/Header.js';
import Main from './components/Main.js';
import Aside from './components/Aside.js';
import Footer from './components/Footer.js';
import LoadingScreen from './components/LoadingScreen.js';
import { useDatabaseForContents } from './utils/server'  
import filter from './utils/filter'  
import { conzole, handleUserInteraction, handleHistory } from "./utils/commons"
import { useUserQueryReducer, usePopUp } from './utils/hooks' 
import './style/App.css';
// import './style/temp.css';

const App =()=> {
  /* nekoliko stvari:
    1. prvi puta kada se stranica učitava dohvaća sadržaj iz db.jsona i proslijeđuje ga klopki setContents (to bi se trebalo dogoditi samo jednom)
    2. dalje sa svakim klikom se nanovo postavlja USER_QUERY kojem url može biti ili undefined ili adresa za html dokument (to je cijela logika aplikacije)
    3. ako je url za html dokument, proslijeđuje se preko USER_QUERY-a setArticle klopci koja nanovo renderira App sa Contentom članka u Mainu,
       a inaće se po USER_QUERY objektu stvara polje contentToShow pomoću koje se renderira popis filtriranih tema u Asideu
  */

  // samo u prvom učitavanju stranice učitaj sadržaj iz db.json
  const contents = useDatabaseForContents()
  
  // glavno upravljanje prikazom sadržaja, stalno se mijenja kada god korisnik klikne da filtrira listu sadržaja ili prikaže članak
  const USER_QUERY = useUserQueryReducer()
  
  // mehanizam za upravljanje popupom. Klopka je objekt koji pamti da li se popup treba prikazati i gdje.
  const popUp = usePopUp()
  

  useEffect(()=>{
    // glavni mehanizam upravljanja history-jem, handla samo popstate u commons.js
    handleHistory(USER_QUERY.update)
  }, [])

  useEffect(()=>{

    if (contents.hasOwnProperty("manual")) {
      // prva provjera nakon učitavanja je je li korisnik došao preko poveznice za članak (ili kroz history)
      const startURL = window.location.href.indexOf("?")
      let requestArticle
      if (startURL > -1 && !USER_QUERY.title ) { // ovo je provjera je li našao članak (ako je već pronađen ikakav članak, znači da korisnik nije upravo došao preko linka). To je tu potrebno kako ne bismo završili u beskonačnoj petlji.
        const request = window.location.href.substring(startURL + 1, window.location.href.length)
        // console.log(request)
        const findByURL =(requestURL, contents)=> {
          return contents.reduce((gotIt, chapter)=>{
            // console.log(gotIt, chapter)
            if (chapter.textUrl === requestURL) {
              requestArticle = { type: "article", ...chapter, url: chapter.textUrl }
              return requestArticle || gotIt
            }
            else if ( !!chapter.sections ) return findByURL(requestURL, chapter.sections)
            else return gotIt
          }, null)
        }
  
        for (const chapter of Object.entries(contents.manual)) {
            const [ chap, chapArr ] = chapter
            if (!requestArticle && chap !== "_comment") {
              findByURL(request, chapArr)
            }
        }
        if ( !!requestArticle ) {
          USER_QUERY.update( requestArticle )
        } 
        else window.history.replaceState("", document.title, window.location.pathname )
      }
    }
  }, [contents])
  
  // provjeri je li uopće učitan sadržaj i ako nije vrati praznu stranicu
  if (!contents.hasOwnProperty("manual")) return <LoadingScreen />
  else {

  // inaće profiltriraj sadržaj po query-ju i 
      // profiltriraj sadržaj za prikaz po tome što je traženo
      const contentToShow = filter.contents(contents.manual, USER_QUERY)   // <= OVO JE GLAVNI MEHANIZAM ZA FILTRIRANJE SADRŽAJA
      
      // ako je rezultat filtriranja samo jedan članak, prikaži ga
      if (contentToShow instanceof Array && !USER_QUERY.url) {      // userQuery.url je tu zato da le loopa u beskonačnost kada se profiltrira samo 1 članak, već da ga otvori      
        if (contentToShow.length === 1 && !contentToShow[0].sections) {
          const article = contentToShow[0]
          conzole.info("Found exact article!", article)
          USER_QUERY.update({ type: "article", url: article.textUrl, chapter: article.chapter })
        }
        else {
          
            
          }
        }
        
        // pomoćna funkcija za proslijeđivanje setQuery klopke komponentama uz mogućnost da svaka komponenta dapravi nove verzije sa ugrađenim sideefektima na sučelju
        const userInteractionDefiner = handleUserInteraction(USER_QUERY.update, popUp)

        

      // prikaži aplikaciju
      return <>
        <Header defineQueryUpdate={ userInteractionDefiner } contents={ contents.manual } />
        <Main   defineQueryUpdate={ userInteractionDefiner } contents={ contentToShow } article={ USER_QUERY.articleToShow } shorthands={ contents.shorthands } />
        <Aside  defineQueryUpdate={ userInteractionDefiner } contents={ contentToShow } />
        <Footer defineQueryUpdate={ userInteractionDefiner } />
        {/* <div id="debug">UQ: { JSON.stringify(USER_QUERY).replace(/,/g, ", ").replace(/:/g, ": ").replace('{"', '{ "').replace('"}', '" }') }</div> */}
      </>

  }
}
  


export default App;
