export const constructNavBar =contents=> {

    const makeDisplaytext =key=> {
        switch (key) {
            case "info":
                return "O Programu"
            case "contents":
                return "Sadržaj"
            case "quotes":
                return "Troškovnici"
            case "documentation":
                return "Dokumentacija"
            case "examples":
                return "Primjeri"
            default:
                return
        }   
    }
    

    const navItems = Object.keys(contents).reduce((nav, content)=>{

        if (content.indexOf("_") !== -1) return nav

        const displayText = makeDisplaytext(content)

        return [ ...nav, { content, displayText, text: "" } ]
     }, [])
    //  navItems.push({ content: "manual", text: "", displayText: "Sadržaj" })

     return navItems
}

// ------------------------------------------------------------------------------------------------------------------------------

export const handleUserInteraction =(querySetter, popUpHandler)=> 
// handleUserInteraction služi kako bi se definirala funkcija za updadateanje USER_QUERY statea korisničkim interakcijama, a definira se u tri koraka:
//  1. u App.js se funkciji doda Reactova funkcija za updateanje USER_QUERY statea i popUp objekt koji sadrži sve elemente svog statea, vraćena funkcija se prosljeđuje svim komponentama
//  2. komponente onda mogu iz nje napraviti verzije queryUpdate funkcije u kojima je definirano otvaraju li ili zatvaraju sidebar i/ili čiste ili ne stanje poklikanih stvari u HeroNav-u, te dobiju nazad definiranu funkciju za updateanje
//  3. dobivena funkcija za updateanje se proslijeđuje podkomponentama ili elementima kao handler za onClick ili onChange ili štoveć, a prima event i novi query

    (sideEffects={ hideSideBar: false, heroNavCleanup: false, getPopUpHandler: false })=> {
    
        Object.values(sideEffects).forEach(eff=> { if (!(typeof eff === "boolean")) throw new Error("All sideEffects settings must be booleans.") })
        if (!sideEffects.getPopUpHandler) sideEffects.getPopUpHandler = false

        const handleContentBar = handleSidebar()
        const handleHeroNavOpenElements = handleHeroNav()
        const sideBarHandler = !!sideEffects.hideSideBar ? ()=> {setTimeout(handleContentBar.hide, 100)} : handleContentBar.show,
              heroNavHandler = !!sideEffects.heroNavCleanup ? handleHeroNavOpenElements.close : ()=>{}  
        const handler = (e, query)=> {
            if (e.preventDefault) e.preventDefault()
            popUpHandler.close()
            sideBarHandler()
            heroNavHandler()
            querySetter(query)
            if (!!query.url) {
                window.history.pushState(query, query.title, `?${query.url}`)
            } else if (query.type === "reset") {
                window.history.pushState("", "Home", window.location.pathname)
            }
        }
        return sideEffects.getPopUpHandler ? [handler, popUpHandler] : handler
}

export const handleHistory =setter=> {
    window.addEventListener("popstate", event=> { 
        conzole.debug(event.state)
        if (event.state) {
            setter(event.state) 
        }
        else {
            setter({ type: "reset", text: "" })
        }
    }, false)
}

const handleSidebar =()=> {
    const root =()=> document.body
    const show =()=> { root().classList.remove("articleView") }
    const hide =()=> { root().classList.add("articleView") }
    return { show, hide }
}
const handleHeroNav =()=> {
    const close =()=> { document.querySelectorAll('#heroNav .up').forEach(el=> el.classList.remove("up")) }
    return { close }
}

// ---------------------------------------------------------------------------------------------------------------------------------


export const processContents =manual=> {

    const runThroughChapters =(chapter, level, parent, general=[])=>  {
        chapter.listLevel = level
        chapter.parent = parent

        // (poglavlja sa "relevantnim općim podatcima" mogu biti tu, mogu biti u ContentEntry-ju... dosta je nekonzistentan sadržaj, pa samo pokušavam uloviti zadnje poglavlje ili članak sa oznakom "general")
        // rješenje treba moći podržati sljedeće slućajeve:

            // 1.  chapter
            //         general
            //             article
            //         solutions
            //             general-article
            //             solution
            
            // 2.  chapter
            //         general-article
            //         general-article
            //         chapter
            //             general-article
            //             solution
            //         chapter
            //             general-article
            //             solution
            
            // 3.  general // (ovo je samo u EGB-u i ignoriram da postoji jer je smeće)
            //         article
            //         article
            //         solution

        // PRVO se bavimo pojedinačnim člancima, ako su nanizani ili ugnježđeni
        // provjeri je li ovaj članak opći
        if (chapter.content === "general" && !chapter.sections) general = [...general, chapter]
        // ako nije provjeri jesli li već našao poglavlje sa općim podatcima i pripiši ga članku ako je prijedlog rješenja
        else if (general.length > 0 && !!chapter.solutions) chapter.general = general

        // DRUGO se bavimo sa poglavljima sastavljenim od poglavlja sa općim podatcima kojeg kasnije slijedi poglavlje sa rješenjima
        // provjeri je li jedno od njegove djece poglavlje sa općim podatcima
        if ( !!chapter.sections ) {
            // ovo je glupo rješenje sa glupim reduce fiksom, trebam refaktorirati ovo dolje 💩💩💩
            if ( chapter.content !== "general" ) { // da se ne bi dupliciralo kasnije. (prvo pokupi opće članke iz sekcija i tek onda ulazi u pojedine sekcije)
                general = [ ...general, ...chapter.sections.map( sec=> sec.content !== "general" ? [] : !!sec.sections ? sec.sections : !general.includes(sec) ? sec : [] ) ].reduce((gen, g)=> { 
                                                                                                                                                                                if (Array.isArray(g)) return [...gen, ...g]
                                                                                                                                                                                return [...gen, g]
                                                                                                                                                                            }, [])
            }
            chapter.sections  = chapter.sections.map(section=> runThroughChapters(section, level + 1, chapter, general) )
        }
            
        return chapter
    }
    

    
    return manual.contents.map( chapter=> runThroughChapters(chapter, 2, manual) )
}

// -----------------------------------------------------------------------------------------------------------------------------------------

export const setArticleMenuLink =(link, handler)=> {
    
    if (!!link.search) {

        if (link.search.indexOf("/") < 1 && (parseInt(link.port) === 3000 || link.href.indexOf("hcpi.hr/UPPO") > 0)) { // radi samo u UPPO poddirektoriju
            console.log(link.href)

            link.classList.add("menuBLink")
    
            link.addEventListener("click", (e)=>{
                e.preventDefault()
                if (e.target) {
                    let query =((href)=> {
                        switch (href) {
                            case "sve":
                            case "sadržaj":
                            case "sadrzaj":
                                return { content: "contents" }
                            case "primjeri":
                                return { content: "examples" }
                            case "troskovnici":
                                return { content: "quotes" }
                            case "projekt_UPPO":
                                return { content: "documentation" }
                            case "KK":
                            case "TD":
                            case "TZZ":
                                const text =((str)=>{
                                    switch (str) {
                                        case "KK":
                                            return "krov"
                                        case "TD":
                                            return "dimnjaci"
                                        case "TZZ":
                                            return "tavan"
                                        case "EGB":
                                            return "nekonstrukcijski"
                                        case "neodgodivi_zahvati":
                                            return "neodgodivi"
                                        default:
                                            return str
                                    }
                                })(href)
                                return { content: "contents", text }
                            
                            default:
                                return href
                        }
                    })(e.target.href.match(/\/\?(.*)/)[1])
                    
                    // console.log(query)
                    handler(e, { type: "contentDisplay", ...query })
                }
            }, false)
        }
    }
}

// ---------------------------------------------------------------------------------------------------------------------------------

export const attachTooltips =(html, shorthands)=> {
    const stopChar = `(?!\\d|\\?|\\/|\\p{L})`
    const stupidUnmatchable = "&#268;"
    html.querySelectorAll("p, li, dt, dd, blockquote, figcaption").forEach(el=>{
        for (let shorthand of shorthands) {
            el.innerHTML = el.innerHTML.replace(/\s/g, " ").replace("Č", stupidUnmatchable).replace(
                new RegExp(`${stopChar}\\b${shorthand.short.replace("Č", stupidUnmatchable)}${stopChar}\\b`, "g"),
                `<mark class="shorthand" ><span data-title="${shorthand.for}">${shorthand.short}</span></mark>`
            )
        }
    })
}


// #################################################################################################################################
// ovo dolje ne treba ni gledati jer nema veze sa logikom aplikacije


// numberChapters je izbačen jer brojevi poglavlja moraju točno odgovarati tiskanom izdanju
// export const numberChapters =(manual)=> {
    
//     const chapCounter = [0, 0, 0] // nalazi se izvan numberingMachine funkcije jer je ona rekurzivna
//     const numberingMachine =(contents, level=0 )=> {

//         if (contents instanceof Array) 
        
//         return contents.reduce((tot, p)=> {
//                 if (p.title === "") return tot // provjerava ima li praznih polja sadržaja u db-u

//                 chapCounter[level]++ // brojač
//                 if (chapCounter[level + 1] !== undefined) chapCounter[level + 1] = 0  // ako je 
//                 if (chapCounter[level + 2] !== undefined) chapCounter[level + 2] = 0

//                 p.chapter = [chapCounter[0], chapCounter[1] === 0 ? "" : chapCounter[1],  chapCounter[2] === 0 ? "" : chapCounter[2] ].join("").trim().split("").join(".") + "."
//                 // console.log(level, "\t", p.chapter, "\t", p.title)
//                 tot = [...tot, p]
//                 if (p.sections instanceof Array) {
//                     numberingMachine(p.sections, level + 1)
//                 }
//                 return tot
//             }, [])
        
//         else if (contents instanceof Object) return Object.keys(contents).reduce((tot, p)=> {
//             // console.log(p.toUpperCase().split("").join("  ")) // ovuda prođe samo prvi put
//             chapCounter[0] = 0
//             chapCounter[1] = 0
//             chapCounter[2] = 0
//             tot[p] = numberingMachine(contents[p], level )
//             return tot
//         }, {})
//     }
    
//     return numberingMachine(manual)

// }



const conzoleBuilder = function() {
    const style = {
        g: [ "✨", "padding: 5px 20px 6px 0; background: rgba(100, 200, 0, .5); color: wheat; border-left: 6px solid khaki;"],
        b: [ "💩", "padding: 5px 20px 6px 0; background: rgba(200, 100, 0, .5); color: wheat; border-left: 6px solid orange;"],
        h: [ "🤪", "padding: 5px 20px 6px 0; background: rgba(0, 100, 200, .5); color: wheat; border-left: 6px solid darkturquoise;"],  
        i: [ "💊", "padding: 5px 20px 6px 0; background: rgba(100, 0, 200, .5); color: wheat; border-left: 6px solid rebeccapurple;"]    
    }
    const fill =(messages, s)=> messages.reduce((msgs, m)=> {
        if (typeof m === "string") return msgs.concat([`%c ${s[0]} ${m}`, s[1]])
        return msgs.concat(m)
    }, [])

    this.success = (...messages) => { console.log( ...fill(messages, style.g) ) }
    this.failure = (...messages) => { console.trace( ...fill(messages, style.b) ) }
    this.holla =   (...messages) => { console.log( ...fill(messages, style.h) ) }
    this.info =    (...messages) => { console.info( ...fill(messages, style.i) ) }
    this.trace =   (...messages) => { console.trace( ...fill(messages, style.i) ) }
    this.dir = console.dir
    this.warn = console.warn
    this.table = console.table
    this.debug = console.debug
    this.assert = console.assert
    this.error = console.failure

}
export const conzole = new conzoleBuilder()

export const dragElement =(elmnt)=> {
    
    const coords = elmnt.getBoundingClientRect()
    elmnt.style.top = elmnt.style.top || coords.top + "px"
    elmnt.style.left = elmnt.style.left || coords.left + "px"
    let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0
    elmnt.onmousedown = dragMouseDown 

    function dragMouseDown(e) {
        e = e || window.event
        e.preventDefault()
        // get the mouse cursor position at startup:
        pos3 = e.clientX
        pos4 = e.clientY
        document.onmouseup = closeDragElement
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag
    }

    function elementDrag(e) {
        e = e || window.event
        e.preventDefault()
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX
        pos2 = pos4 - e.clientY
        pos3 = e.clientX 
        pos4 = e.clientY
        // set the element's new position:
        elmnt.style.top = (parseFloat(elmnt.style.top.replace("px", "")) - pos2) + "px"
        elmnt.style.left = (parseFloat(elmnt.style.left.replace("px", "")) - pos1) + "px"
    }

    function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null
        document.onmousemove = null
    }
}